import { useRef, useEffect, useState, useCallback } from "react";

// Mui
import {
  Box,
  Badge,
  Button,
  IconButton,
  MenuItem,
  Typography,
  Menu,
  useMediaQuery,
  styled,
} from "@mui/material";

import useTabVisibility from "use-tab-visibility";

// socket
import { socket } from "src/socket";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// ** Custom Components Imports
import CustomChip from "src/@core/components/mui/chip";

// ** Third Party Components
import PerfectScrollbarComponent from "react-perfect-scrollbar";

// Music
import NotificationSound from "./notif.mp3";

// time
import moment from "moment";
import toast from "react-hot-toast";

// Components
import TabNotification from "./TabNotification";

// Services
import ServiceInfo from "src/service/Info";

let timer;
const showSeconds = 10000;

// ** Styled PerfectScrollbar component
const PerfectScrollbar = styled(PerfectScrollbarComponent)({
  maxHeight: 344,
});

const ScrollWrapper = ({ children, hidden }) => {
  if (hidden) {
    return (
      <Box sx={{ maxHeight: 349, overflowY: "auto", overflowX: "hidden" }}>
        {children}
      </Box>
    );
  } else {
    return (
      <PerfectScrollbar
        options={{ wheelPropagation: false, suppressScrollX: true }}
      >
        {children}
      </PerfectScrollbar>
    );
  }
};

function Notification() {
  const audioPlayer = useRef(null);
  const { visible } = useTabVisibility();

  // ** States
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState([]);

  // useEffect(() => {
  //   if (visible) {
  //     clearTimeout(timer);
  //     timer = setTimeout(() => {
  //       toast.dismiss();
  //     }, showSeconds);
  //   }
  // }, [visible]);

  let setMessage = (m) => {
    toast(m, {
      duration: showSeconds,
    });
    playAudio();
  };

  useEffect(() => {
    ServiceInfo.getNotifyData().then((r) => setData(r.data));
    socket.on("notify_message", (m) => {
      setMessage(m);
    });
    socket.on("notify_data", (d) => {
      setData(d);
    });
    return () => {
      socket.off("notify_message");
      socket.off("notify_data");
    };
  }, []);

  // ** Hook
  const hidden = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  // Handlers
  const handleDropdownOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  function playAudio() {
    audioPlayer.current.play();
  }

  return (
    <Box>
      <TabNotification />
      <audio ref={audioPlayer} src={NotificationSound} />
      <IconButton
        color="inherit"
        aria-haspopup="true"
        onClick={handleDropdownOpen}
        aria-controls="customized-menu"
      >
        <Badge
          color="error"
          variant="dot"
          invisible={!data.length}
          sx={{
            "& .MuiBadge-badge": {
              top: 4,
              right: 4,
              boxShadow: (theme) =>
                `0 0 0 2px ${theme.palette.background.paper}`,
            },
          }}
        >
          <Icon icon="mdi:bell-outline" />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          disableRipple
          disableTouchRipple
          sx={{
            cursor: "default",
            userSelect: "auto",
            backgroundColor: "transparent !important",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography
              textAlign="center"
              sx={{ cursor: "text", fontWeight: 600 }}
            >
              Уведомления
            </Typography>
          </Box>
        </MenuItem>
        <ScrollWrapper hidden={hidden}>
          {data.length ? (
            data.map((notification, index) => (
              <MenuItem key={index} onClick={handleDropdownClose}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>{notification.text}</Typography>

                  <Typography variant="caption" sx={{ color: "text.disabled" }}>
                    {notification.count}
                  </Typography>
                </Box>
              </MenuItem>
            ))
          ) : (
            <Typography textAlign="center" sx={{ px: 2 }}>
              Пусто
            </Typography>
          )}
          {}
        </ScrollWrapper>
      </Menu>
    </Box>
  );
}

export default Notification;
