import * as React from "react";
import * as Mui from "@mui/material";

import PageWrap from "src/components/PageWrap";

// Service
import MerchantService from "src/service/Merchant";

import IconifyIcon from "src/@core/components/icon";
import toast from "react-hot-toast";

function MerchantTariff() {
  let [currencies, setCurrencies] = React.useState([]);
  let [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    MerchantService.request("admin.ser_currency_get-all", {}).then(
      ({ data }) => {
        setCurrencies(data);
        setLoaded(true);
      }
    );
  }, []);

  const changeItemHandler = (newObj) => {
    setCurrencies((prev) =>
      prev.map((c) => (c._id == newObj._id ? newObj : c))
    );
  };

  return (
    <PageWrap title="Тарифы">
      <Mui.Grid container spacing={2}>
        {currencies.map((c) => (
          <Mui.Grid key={c._id} item xs={12} md={6}>
            <CurrencyCard
              changeItemHandler={changeItemHandler}
              style={{ mb: 2 }}
              data={c}
            />
          </Mui.Grid>
        ))}
      </Mui.Grid>
    </PageWrap>
  );
}

function CurrencyCard({ style = {}, data, changeItemHandler }) {
  const [cur, setCur] = React.useState(data);
  const [error, setError] = React.useState({
    receivePercent: "",
    sendPercent: "",
  });

  // Calc
  let isChanged =
    +cur.receivePercent !== +data.receivePercent ||
    +cur.sendPercent !== +data.sendPercent;

  // Handlers
  const changeValueHandler = (name, value) => {
    setCur((prev) => Object.assign({}, prev, { [name]: value }));
    setError({
      receivePercent: "",
      sendPercent: "",
    });
  };

  const saveApiHandler = () => {
    const errorData = checkPercentValue(cur);
    if (errorData?.value) {
      setError((prev) =>
        Object.assign({}, prev, { [errorData.value]: errorData.message })
      );
      return;
    }
    MerchantService.request("admin.ser_currency_change-one", {
      _id: data._id,
      receivePercent: cur.receivePercent,
      sendPercent: cur.sendPercent,
    }).then(({ data }) => {
      changeItemHandler(data);
      toast.success("Процент валюты измененн!");
    });
    //
  };

  return (
    <Mui.Card sx={{ ...style }}>
      <Mui.CardContent sx={{ p: "12px 18px !important" }}>
        <Mui.Grid container spacing={2}>
          <Mui.Grid item xs={12} md={6}>
            <Mui.Box sx={{ display: "flex", alignItems: "center" }}>
              <Mui.Avatar
                sx={{
                  width: "30px",
                  height: "30px",
                  mr: 2,
                }}
                src={cur.logo}
                name={cur.name}
              />
              <Mui.Typography variant="body1">{cur.name}</Mui.Typography>
            </Mui.Box>
          </Mui.Grid>
          <Mui.Grid item xs={isChanged ? 5 : 6} md={isChanged ? 2.5 : 3}>
            <Mui.TextField
              value={cur.receivePercent}
              onChange={(e) =>
                changeValueHandler("receivePercent", e.target.value)
              }
              error={Boolean(error.receivePercent)}
              helperText={error.receivePercent}
              fullWidth
              placeholder="%"
              type="number"
              size="small"
              label="Отдаю"
            />
          </Mui.Grid>
          <Mui.Grid item xs={isChanged ? 5 : 6} md={isChanged ? 2.5 : 3}>
            <Mui.TextField
              value={cur.sendPercent}
              onChange={(e) =>
                changeValueHandler("sendPercent", e.target.value)
              }
              error={Boolean(error.sendPercent)}
              helperText={error.sendPercent}
              placeholder="%"
              type="number"
              fullWidth
              size="small"
              label="Получаю"
            />
          </Mui.Grid>
          {isChanged && (
            <Mui.Grid
              sx={{ display: "flex", justifyContent: "flex-end" }}
              item
              xs={2}
              md={1}
            >
              <Mui.IconButton
                onClick={saveApiHandler}
                sx={{ p: 1, borderRadius: "5px" }}
              >
                <IconifyIcon icon="mingcute:save-line" />
              </Mui.IconButton>
            </Mui.Grid>
          )}
        </Mui.Grid>
      </Mui.CardContent>
    </Mui.Card>
  );
}

function checkPercentValue(newObject) {
  let receivePercent = newObject.receivePercent;
  let sendPercent = newObject.sendPercent;
  if (receivePercent < 0 || receivePercent > 100) {
    return {
      value: "receivePercent",
      message: "Значение должно быть от 0 до 100 %",
    };
  }
  if (sendPercent < 0 || sendPercent > 100) {
    return {
      value: "sendPercent",
      message: "Значение должно быть от 0 до 100 %",
    };
  }
}

export default MerchantTariff;
