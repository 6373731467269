import { Box, Typography, Avatar } from "@mui/material";

//Custom components
import CustomAvatar from "src/@core/components/mui/avatar";

// Config
import ConfigApi from "src/configs/api";

export default function Cur({ name, image }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <CustomAvatar
        skin="light"
        variant="rounded"
        sx={{ width: 32, height: 32, mr: 2 }}
      >
        <Avatar
          src={image}
          alt={name}
          sx={{ height: 26, width: 26 }}
        />
      </CustomAvatar>
      <Typography fontWeight="bold" variant="body1">
        {name}
      </Typography>
    </Box>
  );
}
