// ** React Imports
import { createContext, useEffect, useState } from "react";

// ** Next Import
import { useLocation, useNavigate } from "react-router-dom";

// Services
import AuthService from "src/service/Auth";

// Helpers
import initLoadData from "src/helpers/initLoadData";

// Socket
import { authenticate } from "src/socket";

// Event
import emitter from "src/EventEmitter";

// ** Defaults
const defaultProvider = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
};
const AuthContext = createContext(defaultProvider);

const AuthProvider = ({ children }) => {
  // ** States
  const [user, setUser] = useState(defaultProvider.user);
  const [loading, setLoading] = useState(defaultProvider.loading);

  // Calc
  const isAuth = !!user;
  const isAdmin = user && user.role === "administrator";
  const permission = user?.permission?.rules;


  // ** Hooks
  const location = useLocation();
  const navigation = useNavigate();
  const { pathname } = location;

  useEffect(() => {
    emitter.addListener("logOut", function () {
      handleLogout();
    });
    return () => {
      emitter.removeCurrentListener("logOut");
    };
  }, []);

  useEffect(() => {
    if (isAuth) {
      setLoading(true);
      initLoadData().then(() => {
        setLoading(false);
      });
    }
  }, [isAuth]);

  useEffect(() => {
    if (!loading && !user) {
      let isUnauthRoutes = pathname == "/login" || pathname == "/confirm-login";
      if (!isUnauthRoutes) {
        navigation("/login");
      }
    }
  }, [pathname, loading]);

  useEffect(() => {
    const initAuth = async () => {
      let token = localStorage.getItem("token");
      if (!token) {
        setLoading(false);
        return;
      }

      AuthService.getProfile()
        .then((r) => {
          setUser(r.data);
          authenticate();
        })
        .catch(() => {
          localStorage.removeItem("token");
        })
        .finally(() => {
          setLoading(false);
        });
    };
    initAuth();
  }, []);

  const handleLogin = (data) => {
    localStorage.setItem("token", data.token);
    setUser(data.user);
    authenticate();
    navigation("/orders");
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setUser(null);
    navigation("/login");
  };

  const values = {
    user,
    loading,
    setUser,
    setLoading,
    login: handleLogin,
    logout: handleLogout,
    isAdmin,
    permission
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
