import { useState, useEffect } from "react";
// Mui
import {
  CardContent,
  Box,
  Typography,
  IconButton,
  Collapse,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  Autocomplete,
  TextField,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
// ** Icon Imports
import Icon from "src/@core/components/icon";
// Service
import TargetPairService from "src/service/TargetPair";

// toast
import toast from "react-hot-toast";

function Exceptions({ list, id, deleteItemHandler, addItemHandler }) {
  let [show, setShow] = useState(false);
  let [showModal, setShowModal] = useState(false);

  const deleteExceptionHandler = (excepId) => {
    TargetPairService.changeException(id, {
      action: "delete",
      data: excepId,
    }).then((res) => {
      deleteItemHandler(excepId);
      toast.success("Обменник удален!");
    });
  };

  return (
    <CardContent sx={{ p: 3, pb: '18px !important' }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="body1">Исключения</Typography>
        <IconButton sx={{ p: 1, ml: 3 }} onClick={() => setShow(!show)}>
          <Icon icon={show ? "mdi:chevron-up" : "mdi:chevron-down"} />
        </IconButton>
      </Box>
      <Collapse sx={{ mt: 2 }} in={show} timeout="auto" unmountOnExit>
        <Box>
          <DialogAddExclude
            addItemHandler={addItemHandler}
            id={id}
            open={showModal}
            handleClose={() => setShowModal(false)}
          />
          <Chip
            onClick={() => setShowModal(true)}
            label="Добавить"
            sx={{mb: 2 , mr: 2}}
            icon={<Icon icon="material-symbols:add" fontSize={20} />}
          />
          {list.map((e) => (
            <Chip
              key={e._id}
              label={e.name}
              sx={{mb: 2 , mr: 2}}
              onDelete={() => deleteExceptionHandler(e._id)}
            />
          ))}
        </Box>
      </Collapse>
    </CardContent>
  );
}

function DialogAddExclude({ open, handleClose, addItemHandler, id }) {
  let [list, setList] = useState([]);
  let [selected, setSelected] = useState("");
  let [errorMessage, setErrorMessage] = useState("");
  let [isSubmiting, setIsSubmiting] = useState(false);

  // hooks

  useEffect(() => {
    TargetPairService.getAllExchangers().then((r) => {
      setList(r.data);
    });
  }, []);

  // Handlers

  const onChange = (v) => {
    setErrorMessage("");
    setSelected(v);
  };

  const closeHandler = () => {
    setErrorMessage("");
    setSelected("");
    handleClose();
  };

  const addHandler = (e) => {
    e.preventDefault();
    if (!selected) {
      setErrorMessage("Выберите обменник!");
      return;
    }
    let data = list.find((c) => selected === c.id);
    setIsSubmiting(true);
    TargetPairService.changeException(id, {
      action: "add",
      data,
    })
      .then((res) => {
        addItemHandler(res.data);
        toast.success("Обменник добавлен!");
        closeHandler();
      })
      .finally(() => setIsSubmiting(false));
  };

  return (
    <Dialog open={open} onClose={closeHandler}>
      <DialogTitle>Выберите обменник</DialogTitle>
      <Box component="form" onSubmit={addHandler}>
        <DialogContent>
          <Autocomplete
            value={selected}
            fullWidth
            options={list.map((c) => c.id)}
            onChange={(e, v) => onChange(v)}
            getOptionLabel={(option) => {
              let item = list.find((c) => option === c.id);
              return `${item?.name || ""}`;
            }}
            sx={{ mt: 2 }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(errorMessage)}
                helperText={errorMessage}
                label="Обменник"
              />
            )}
          />
        </DialogContent>
        <DialogActions className="dialog-actions-dense">
          <Button
            startIcon={isSubmiting ? <CircularProgress /> : null}
            disabled={isSubmiting}
            variant="contained"
            onClick={addHandler}
            type="submit"
          >
            Добавить
          </Button>
          <Button variant="outlined" onClick={closeHandler}>
            Отменить
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default Exceptions;
