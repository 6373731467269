import { useState, useEffect } from "react";

import { Box, Grid } from "@mui/material";

import MerchantService from "src/service/Merchant";

// Modules
import DepositCard from "src/modules/Merchant/Deposit/DepositCard";
// import DepositOrderDrawer from "src/modules/Merchant/Deposit/DepositOrderDrawer";
import socket from "src/socket";

function DepositList() {
  const [depositList, setDepositList] = useState([]);

  useEffect(() => {
    MerchantService.request("admin.ser_deposit_get-all").then(({ data }) => {
      setDepositList(data);
    });
    socket.on("merchant_deposit_add", (data) => {
      setDepositList((prev) => [data, ...prev]);
    });
    socket.on("merchant_deposit_upd", (data) => {
      setDepositList((prev) => prev.map((d) => (d._id == data._id ? data : d)));
    });
    return () => {
      socket.off("merchant_deposit_add");
      socket.off("merchant_deposit_upd");
    };
  }, []);

  return (
    <Box>
      {/* <DepositOrderDrawer /> */}
      <Box>
        <Grid container spacing={4}>
          {depositList.map((d) => (
            <Grid key={d._id} item xs={12} md={6} lg={3}>
              <DepositCard data={d} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default DepositList;
