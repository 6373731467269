import { useState, useEffect } from "react";
// MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

// Filter
import Filter from "./Filter";
// Modules
import CreditCard from "src/modules/Cards/CreditCard/CreditCard";
// import CardDrawer from "src/modules/Cards/CardDrawer/CardDrawer";
import AddCardDialog from "src/modules/Cards/AddCardDialog/AddCardDialog";

// Services
import CardService from "src/service/Card";

// LIBS
import InfiniteScroll from "react-infinite-scroll-component";

// Socket
import { socket } from "src/socket";

// Timer
let timerId;

const LIMIT = 32;

function Cards() {
  // Main filter
  let [site, setSite] = useState("");
  let [status, setStatus] = useState("");

  // Advanced filter
  let [bank, setBank] = useState("");
  let [search, setSearch] = useState("");

  // Data states
  let [cards, setCards] = useState([]);

  // Main
  let [loading, setLoading] = useState(false);
  let [showAddCard, setShowAddCard] = useState(false);

  // Calc
  let isAdvancedFilter = bank || search.trim();

  // Hooks
  useEffect(() => {
    socket.on("card_add", (card) => {
      setCards((cards) => [card, ...cards]);
    });
    socket.on("card_upd_item", (upd) => {
      setCards((cards) =>
        cards.map((c) => (c._id == upd._id ? Object.assign({}, c, upd) : c))
      );
    });

    return () => {
      socket.off("card_add");
      socket.off("card_upd_item");
    };
  }, []);

  useEffect(() => {
    if (isAdvancedFilter) {
      if (bank) {
        searchFilterCards(true);
      } else {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
          searchFilterCards(true);
        }, 2000);
      }
    } else {
      fetchCards(true);
    }
  }, [site, status, bank, search]);

  const clearAdvancedFilters = () => {
    setSearch("");
    setBank("");
  };

  // Api
  const fetchCards = (isInit = false) => {
    setLoading(true);
    CardService.getList({
      site,
      status,
      pagination: {
        limit: LIMIT,
        skip: isInit ? 0 : cards.length,
      },
    })
      .then((r) => {
        let data = r.data;
        setCards((list) => {
          return isInit ? data : [...list, ...data];
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function searchFilterCards(isInit) {
    setLoading(true);
    CardService.search({
      search: search,
      bank: bank,
      pagination: {
        limit: LIMIT,
        skip: isInit ? 0 : cards.length,
      },
    })
      .then((r) => {
        let data = r.data;
        setCards((list) => {
          return isInit ? data : [...list, ...data];
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  let cardList = isAdvancedFilter ? cards : filterCards(cards, site, status);

  let requestHandler = isAdvancedFilter ? searchFilterCards : fetchCards;

  return (
    <Box>
      <AddCardDialog
        show={showAddCard}
        handleClose={() => setShowAddCard(false)}
      />
      <Filter
        site={site}
        setSite={setSite}
        status={status}
        setStatus={setStatus}
        bank={bank}
        setBank={setBank}
        openAddCardHandler={() => setShowAddCard(true)}
        search={search}
        setSearch={setSearch}
        isAdvancedFilter={isAdvancedFilter}
        clearAdvancedFilters={clearAdvancedFilters}
      />

      <InfiniteScroll
        dataLength={cards.length}
        next={requestHandler}
        hasMore={true}
        loader={null}
      >
        <Grid sx={{ mt: 2 }} container spacing={4}>
          {cardList.map((c) => {
            return (
              <Grid key={c._id} item xs={12} sm={6} md={3}>
                <CreditCard visibleArchived={isAdvancedFilter} data={c} />
              </Grid>
            );
          })}
        </Grid>
      </InfiniteScroll>

      {loading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pt: 2,
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}

function filterCards(cards, sc, status) {
  let list = [...cards];

  if (sc) {
    list = list.filter((c) => c.sc == sc);
  }

  if (status) {
    switch (status) {
      case "new":
        list = list.filter((c) => {
          let s = c.status;
          return s == 101 && !c.archived;
        });
        break;
      case "resolved":
        list = list.filter((c) => {
          let s = c.status;
          return s == 102 && !c.archived;
        });
        break;
      case "rejected":
        list = list.filter((c) => {
          let s = c.status;
          return s == 103 && !c.archived;
        });
        break;
      case "archived":
        list = list.filter((c) => {
          return c.archived;
        });
        break;
      case "banned":
        list = list.filter((c) => {
          let s = c.status;
          return s == 104;
        });
        break;
      default:
    }
  } else {
    list = list.filter((c) => {
      return !c.archived;
    });
  }

  return list;
}

export default Cards;
