import { Card, CardContent } from "@mui/material";

import AuctionInfo from "./AuctionInfo";
import AuctionOrder from "./AuctionOrder";

function AuctionCard({deleteData, data, style = {}, orderId }) {
  const existOrder = data.order;

  return (
    <Card
      sx={{
        ...style,
      }}
    >
      <CardContent
        sx={{
          p: "12px 16px !important",
        }}
      >
        {existOrder ? <AuctionOrder deleteData={deleteData} auctionId={orderId} data={data.order}/> : <AuctionInfo deleteData={deleteData} orderId={orderId} data={data} />}
      </CardContent>
    </Card>
  );
}

export default AuctionCard;
