import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";

import PageWrap from "src/components/PageWrap";

// ** Icon Imports
import Icon from "src/@core/components/icon";

import { Link } from "react-router-dom";

import moment from "moment";
import "moment/locale/ru";

import MerchantService from "src/service/Merchant";

const StyledLink = styled(Link)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "1rem",
  cursor: "pointer",
  textDecoration: "none",
  wordBreak: "break-word",
  color: theme.palette.text.secondary,
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

function MerchantClientsPage() {
  let [merchants, setMerchants] = useState([]);

  useEffect(() => {
    MerchantService.request("admin.ser_account_get-all-admin-service").then(
      (res) => {
        setMerchants(res.data);
      }
    );
  }, []);

  let rows = merchants.map((c) => Object.assign({}, c, { id: c._id }));

  return (
    <PageWrap title="Мерчант клиенты">
      <DataGrid
        columns={columns}
        autoHeight
        // loading={loading}
        rows={rows}
        sortingMode="server"
        disableSelectionOnClick
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
        // pageSize={pagination.pageSize}
        // onPageSizeChange={(newPageSize) =>
        //   handleChangePagination("pageSize", newPageSize)
        // }
        pagination
        // rowCount={pagination.rowCount}
        paginationMode="server"
        // onPageChange={(newPage) => handleChangePagination("page", newPage)}
        // sortModel={sorting}
        // onSortModelChange={(newSortModel) => setSorting(newSortModel)}
      />
    </PageWrap>
  );
}

const columns = [
  {
    flex: 0.2,
    // minWidth: 150,
    field: "email",
    headerName: "Email",
    // disableColumnMenu: true,
    renderCell: ({ row }) => {
      return (
        <StyledLink
          wordBreak={true}
          component={Typography}
          sx={{ wordBreak: "break-word", mr: 1 }}
          to={`/merchant/clients/${row._id}`}
        >
          {row.email}
        </StyledLink>
      );
    },
  },
  {
    flex: 0.1,
    // minWidth: 50,
    field: "blocked",
    headerName: "Блок.",
    headerAlign: "center",
    align: "center",
    disableColumnMenu: true,
    renderCell: ({ row }) => {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& svg": {
              color: row.blocked ? "error.main" : "success.main",
            },
          }}
        >
          <Icon
            icon={
              row.blocked
                ? "material-symbols:lock-outline"
                : "material-symbols:lock-open-outline"
            }
            fontSize={20}
          />
        </Box>
      );
    },
  },
  {
    flex: 0.1,
    // minWidth: 50,
    field: "activated",
    headerName: "Активирован",
    headerAlign: "center",
    align: "center",
    disableColumnMenu: true,
    renderCell: ({ row }) => {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& svg": {
              color: row.activated ? "success.main" : "error.main",
            },
          }}
        >
          <Icon
            icon={
              row.activated
                ? "line-md:square-to-confirm-square-transition"
                : "line-md:confirm-square-to-square-transition"
            }
            fontSize={20}
          />
        </Box>
      );
    },
  },
  {
    flex: 0.15,
    // minWidth: 50,
    field: "enabled",
    headerName: "Статус",
    headerAlign: "center",
    align: "center",
    disableColumnMenu: true,
    renderCell: ({ row }) => {
      return (
        <Typography
          variant="body2"
          color={row.enabled ? "success.light" : "error.light"}
        >
          {row.enabled ? "Включен" : "Выключен"}
        </Typography>
      );
    },
  },
  {
    flex: 0.25,
    // minWidth: 50,
    field: "telegram",
    headerName: "Telegram",
    headerAlign: "center",
    align: "center",
    disableColumnMenu: true,
    renderCell: ({ row }) => {
      return (
        <Typography variant="body2">
          {row.telegram ? `@${row.telegram}` : "-"}
        </Typography>
      );
    },
  },
  {
    flex: 0.15,
    // minWidth: 50,
    field: "balance",
    headerName: "Баланс",
    headerAlign: "center",
    align: "center",
    disableColumnMenu: true,
    renderCell: ({ row }) => {
      return <Typography variant="body2">{row.balance} USDT</Typography>;
    },
  },
  {
    flex: 0.2,
    //   minWidth: 150,
    field: "createdAt",
    headerName: "Регист.",
    headerAlign: "center",
    align: "center",
    renderCell: ({ row }) => {
      return (
        <Typography noWrap variant="overline">
          {moment(row.createdAt).format("HH:mm, DD.MM.YYYY")}
        </Typography>
      );
    },
  },
];

export { MerchantClientsPage };
