// ** React Imports
import { useState, useEffect } from "react";

// ** MUI Imports
import TabContext from "@mui/lab/TabContext";
import MuiTab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Box from "@mui/material/Box";

// ** Icon Imports
import Icon from "src/@core/components/icon";

//
import GeneralHistory from "src/modules/GeneralHistory";

// Tabs Modules
// Security
import ChangePassword from "./Tabs/Security/ChangePassword";
// Contact
import Mailing from "./Tabs/Contact/Mailing";
// Cards
import Cards from "./Tabs/Cards/Cards";
// Orders
import Orders from "./Tabs/Orders/Orders";
// Accruals
import Accruals from "./Tabs/Accruals/Accruals";
import Withdraw from './Tabs/Withdraw/Withdraw'

// ** Styled Tab component
const Tab = styled(MuiTab)(({ theme }) => ({
  minHeight: 48,
  flexDirection: "row",
  "& svg": {
    marginBottom: "0 !important",
    marginRight: theme.spacing(1),
  },
}));

const ClientTabs = ({ client }) => {
  const [activeTab, setActiveTab] = useState("orders");

  const handleChange = (event, value) => {
    // setIsLoading(true)
    setActiveTab(value);
  };

  return (
    <TabContext value={activeTab}>
      <TabList
        variant="scrollable"
        scrollButtons="auto"
        onChange={handleChange}
        aria-label="forced scroll tabs example"
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Tab
          value="orders"
          label="Заявки"
          icon={<Icon icon="mingcute:exchange-bitcoin-2-line" />}
        />
        <Tab
          value="cards"
          label="Карты"
          icon={<Icon icon="mdi:credit-cards" />}
        />
        <Tab
          value="history"
          label="История"
          icon={<Icon icon="material-symbols:history-edu-rounded" />}
        />
        <Tab
          value="accruals"
          label="Начисления"
          icon={<Icon icon="icon-park-outline:funds" />}
        />
        <Tab
          value="withdraw"
          label="Вывод вознаграждений"
          icon={<Icon icon="uil:money-withdraw" />}
        />
        {/*  */}
        <Tab
          value="security"
          label="Безопасность"
          icon={<Icon icon="material-symbols:lock-outline" />}
        />
        <Tab
          value="contact"
          label="Связь"
          icon={<Icon icon="material-symbols:outgoing-mail-outline" />}
        />
      </TabList>
      <TabPanel value="orders">
        <Orders clientId={client._id} />
      </TabPanel>
      <TabPanel value="cards">
        <Cards clientId={client._id} />
      </TabPanel>
      <TabPanel value="security">
        <ChangePassword id={client._id} />
      </TabPanel>
      <TabPanel value="contact">
        <Mailing clientId={client._id} />
      </TabPanel>
      <TabPanel value="history">
        <GeneralHistory timelineHeight="65vh" mode="client" id={client._id} />
      </TabPanel>
      <TabPanel value="accruals">
        <Accruals clientId={client._id} />
      </TabPanel>
      <TabPanel value="withdraw">
        <Withdraw clientId={client._id} />
      </TabPanel>

    </TabContext>
  );
};

export default ClientTabs;
