import {
  Card,
  CardContent,
  CardActionArea,
  Box,
  Typography,
} from "@mui/material";

// Components
import DepositChip from "src/components/Merchant/Deposit/DepositChip";

// ** Icon Imports
import Icon from "src/@core/components/icon";

import { useNavigate } from "react-router-dom";

// Libs
import moment from "moment";

function DepositCard({ data }) {
  let title = getTitleActon(data.action);
  const navigate = useNavigate();


  return (
    <Card
      onClick={() => {
        navigate(`?merchantDepositId=${data._id}`);
      }}
    >
      <CardActionArea>
        <CardContent sx={{ py: 3, px: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontSize: "18px", mr: 1, fontWeight: "bold" }}
              variant="body1"
            >
              {title}
            </Typography>
            <DepositChip code={data.code} action={data.action} />
          </Box>
          <Box>
            <InfoRaw name={data._id} icon="ph:hash-bold" />
            <InfoRaw
              name={data.sum + " USDT"}
              icon="mingcute:tether-usdt-fill"
            />
            <InfoRaw
              name={data?.account?.email}
              icon="mdi:user-circle-outline"
            />
          </Box>
          <Box sx={{ display: "flex", mt: 2, justifyContent: "flex-end" }}>
            <Typography
              sx={{
                alignSelf: "flex-end",
              }}
              variant="caption"
            >
              {moment(data.createdAt).format("HH:mm DD.MM.YYYY")}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

function InfoRaw({ name, icon, style = {} }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
      <Icon fontSize={18} icon={icon} />
      <Typography sx={{ ml: 1.5 }} variant="body2">
        {name}
      </Typography>
    </Box>
  );
}

// function Info({list}) {

//     return (
//       <Box sx={{ mb: 2.5, color: "secondary" }}>
//         {list.map((i) => {
//           return (
//             <Box sx={{ display: "flex", alignItems: "center", mt: 2.5 }}>
//               <Icon fontSize={18} icon={i.icon} />
//               <Typography
//                 // color={isLink ? "primary" : textColor}
//                 // component={isLink ? Link : undefined}
//                 // to={link}
//                 sx={{ ml: 1.5 }}
//                 variant="body2"
//               >
//                 {i.name}
//               </Typography>
//             </Box>
//           );
//         })}
//       </Box>
//     );
//   }

function getTitleActon(action) {
  switch (action) {
    case "topup":
      return "Пополнение";
    case "withdraw":
      return "Вывод";
    default:
      return "";
  }
}

export default DepositCard;
