import { Fragment, useState } from "react";
// Mui
import {
  Typography,
  Box,
  Collapse,
  Divider,
  CardContent,
  IconButton,
  CardActionArea,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

// Icons
import Icon from "src/@core/components/icon";

// Custom Components
import ViewImage from "src/components/ViewImage";
import PdfViewer from "src/modules/Viewers/PdfViewer";

function IncomingClientReceipt({ value }) {
  // State
  let [show, setShow] = useState(false);

  const isPdf = isPDFByUrl(value);

  return (
    <Fragment>
      <CardContent sx={{ py: 3, px: 0 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body1">Чек о переводе:</Typography>
          <IconButton sx={{ p: 1 }} onClick={() => setShow(!show)}>
            <Icon icon={show ? "mdi:chevron-up" : "mdi:chevron-down"} />
          </IconButton>
        </Box>

        <Collapse in={show} timeout="auto" unmountOnExit>
          <Box>
            {isPdf ? <PdfViewer url={value} /> : <ImageView url={value} />}
          </Box>
        </Collapse>
      </CardContent>
    </Fragment>
  );
}

function ImageView({ url }) {
  let [showImage, setShowImage] = useState(false);
  return (
    <Box>
      <ViewImage
        show={showImage}
        handleClose={() => setShowImage(false)}
        value={url}
      />
      <CardActionArea
        sx={{ margin: "10px auto", width: "auto" }}
        onClick={() => setShowImage(url)}
      >
        <img
          src={url}
          style={{
            width: "100%",
            borderRadius: 4,
          }}
          alt="Чек"
        />
      </CardActionArea>
    </Box>
  );
}

function isPDFByUrl(url) {
  // Извлекаем расширение файла из URL
  const urlParts = url.split(".");
  const extension = urlParts[urlParts.length - 1].toLowerCase();

  // Проверяем, является ли расширение PDF
  return extension === "pdf";
}

export default IncomingClientReceipt;
