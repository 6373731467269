import { useState } from "react";
// Mui
import {
  Box,
  Card,
  CardContent,
  Avatar,
  Typography,
  Grid,
  Alert,
  TextField,
} from "@mui/material";

// Config
import ConfigApi from "src/configs/api";

//Custom components
import CustomAvatar from "src/@core/components/mui/avatar";

// ** Utils
import getPriceCurrentFiat from "src/helpers/getPriceCurrentFiat";

// Store
import { observer } from "mobx-react-lite";

function Currency({ data }) {
  let [search, setSearch] = useState("");
  let [showInfo, setShowInfo] = useState(true);

  let directions = [...data.directions].filter((d) => d.enabled);

  directions = search ? getFilteringDirections(directions, search) : directions;

  console.log(directions, "directions");

  return (
    <>
    {showInfo && (
        <Alert
          onClose={(e) => {
            e.preventDefault();
            setShowInfo(false);
          }}
          sx={{
            mb: 4,
            "& .MuiAlert-message": {
              width: "100%",
            },
          }}
          severity="info"
          componentsProps={{
            width: "100%",
          }}
        >
          Цена "Купили", "Продали", "Курс покупки", "Курс продажи", "Доход" считается по цене валют на момент создания заявки если есть
          данные, иначе по текущему.
          <Typography variant="body2" textAlign="end">
            upd 11.09.2023
          </Typography>
        </Alert>
      )}
      <Card sx={{ mb: 3 }}>
        <CardContent sx={{ display: "flex", py: "12px !important" }}>
          <Typography variant="h6">Общее</Typography>
        </CardContent>
      </Card>

      

      <Card sx={{ mb: 5 }}>
        <CardContent>
          <Box sx={{ mt: 4 }}>
            <CurrencyDetails countCode={data.code} data={data} />
          </Box>
        </CardContent>
      </Card>
      <Card sx={{ mb: 3 }}>
        <CardContent
          sx={{
            display: "flex",
            py: "12px !important",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">По валютам</Typography>
          <TextField
            value={search}
            label="Валюта"
            size="small"
            onChange={(e) => setSearch(e.target.value)}
          />
        </CardContent>
      </Card>

      <Box sx={{ mt: 3 }}>
        <Grid container spacing={4}>
          {directions.map((d) => {
            return (
              <Grid item xs={6}>
                <Card sx={{ minHeight: "100%" }}>
                  <CardContent>
                    <CurrencyInfo size="small" name={d.name} image={d.image} />
                    <Box sx={{ mt: 4 }}>
                      <CurrencyDetails
                        countCode={data.code}
                        size="small"
                        data={d}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
}

const CurrencyDetails = observer(function ({
  data,
  size = "medium",
  countCode,
}) {
  return (
    <Grid container spacing={4}>
      <TextInfoStyleWrap size={size} data={{}} gridData={{ xs: 6 }}>
        Заявок покупаю:
        <br />
        <span className="count">{data.orders.buy}</span>
      </TextInfoStyleWrap>
      <TextInfoStyleWrap size={size} data={{}} gridData={{ xs: 6 }}>
        Заявок продаю:
        <br />
        <span className="count">{data.orders.sell}</span>
      </TextInfoStyleWrap>

      <TextInfoStyleWrap size={size} data={{}} gridData={{ xs: 6 }}>
        Купили:
        <br />
        {data.buy.count && data.buy.amount ? (
          <>
            <span className="count">{data.buy.count}</span>&nbsp;
            <span className="code">{countCode}</span>&nbsp;за&nbsp;
            <span className="count">
              {getPriceCurrentFiat(data.buy.amount, 0)}
            </span>
          </>
        ) : (
          <span>-</span>
        )}
      </TextInfoStyleWrap>
      <TextInfoStyleWrap
        size={size}
        data={data.sell.count}
        gridData={{ xs: 6 }}
      >
        Продали:
        <br />
        {data.sell.count && data.sell.amount ? (
          <>
            <span className="count">{data.sell.count}</span>&nbsp;
            <span className="code">{countCode}</span>&nbsp;за&nbsp;
            <span className="count">
              {getPriceCurrentFiat(data.sell.amount, 0)}
            </span>
          </>
        ) : (
          <span>-</span>
        )}
      </TextInfoStyleWrap>
      <TextInfoStyleWrap size={size} data={data.rate.buy} gridData={{ xs: 6 }}>
        Курс покупки:
        <br />
        {data.rate.buy ? (
          <>
            <span className="count">1</span>&nbsp;
            <span className="code">{countCode}</span>&nbsp;за&nbsp;
            <span className="count">
              {getPriceCurrentFiat(data.rate.buy, 0)}
            </span>
          </>
        ) : (
          <span>-</span>
        )}
      </TextInfoStyleWrap>
      <TextInfoStyleWrap size={size} data={data.rate.sell} gridData={{ xs: 6 }}>
        Курс продажи:
        <br />
        {data.rate.sell ? (
          <>
            <span className="count">1</span>&nbsp;
            <span className="code">{countCode}</span>&nbsp;за&nbsp;
            <span className="count">
              {getPriceCurrentFiat(data.rate.sell, 0)}
            </span>
          </>
        ) : (
          <span>-</span>
        )}
      </TextInfoStyleWrap>
      <TextInfoStyleWrap size={size} data={data.income} gridData={{ xs: 6 }}>
        Доход
        <br />
        <span className="count">{getPriceCurrentFiat(data.income, 0)}</span>
      </TextInfoStyleWrap>
      <TextInfoStyleWrap size={size} data={data.profit} gridData={{ xs: 6 }}>
        Прибыль
        <br />
        <span className="count">{getPriceCurrentFiat(data.profit, 0)}</span>
      </TextInfoStyleWrap>
      <TextInfoStyleWrap
        size={size}
        data={data.accruals.cashBack}
        gridData={{ xs: 6 }}
      >
        Кэшбэк
        <br />
        <span className="count">
          {getPriceCurrentFiat(data.accruals.cashBack, 0)}
        </span>
      </TextInfoStyleWrap>
      <TextInfoStyleWrap
        size={size}
        data={data.accruals.referral}
        gridData={{ xs: 6 }}
      >
        Реф. программа
        <br />
        <span className="count">
          {getPriceCurrentFiat(data.accruals.referral, 0)}
        </span>
      </TextInfoStyleWrap>
    </Grid>
  );
});

function TextInfoStyleWrap({ children, size = "medium", gridData = {} }) {
  // if (!data) return;

  let isSmall = size == "small";

  return (
    <Grid item {...gridData}>
      <Box
        sx={{
          fontSize: isSmall ? "11px" : "16px",
          color: (t) => t.palette.text.secondary,
          alignItems: "center",
          "word-break": "break-all",
          "& .code,.count": {
            fontSize: isSmall ? "13px" : "17px",
            color: (t) => t.palette.text.primary,
          },
        }}
      >
        {children}
      </Box>
    </Grid>
  );
}

function CurrencyInfo({ image, name, size = "medium" }) {
  let customAvatarSize = 46;
  let avatarSize = 36;
  let typographyVariant = "h6";

  if (size == "small") {
    customAvatarSize = 36;
    avatarSize = 26;
    typographyVariant = "body1";
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <CustomAvatar
        skin="light"
        variant="rounded"
        sx={{ width: customAvatarSize, height: customAvatarSize, mr: 3 }}
      >
        <Avatar
          src={ConfigApi.PUBLIC_IMAGES + image + ".png"}
          alt={name}
          sx={{ height: avatarSize, width: avatarSize }}
        />
      </CustomAvatar>
      <Typography variant={typographyVariant}>{name}</Typography>
    </Box>
  );
}

function getFilteringDirections(list, text = "") {
  let st = text.trim().toLowerCase();

  let result = [...list];

  return result.filter((d) => {
    return (
      d.name.toLowerCase().trim().includes(st) ||
      d.code.toLowerCase().trim().includes(st)
    );
  });
}

export default observer(Currency);
