import { useEffect, useState } from "react";

import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";

import moment from "moment";
import "moment/locale/ru";

import Icon from "src/@core/components/icon";

import MerchantService from "src/service/Merchant";

function DepositTab({ merchantId }) {
  let [history, setHistory] = useState([]);

  useEffect(() => {
    MerchantService.request("admin.ser_history_get-by-account", {
      id: merchantId,
    }).then((res) => {
      setHistory(res.data);
    });
  }, []);

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Событие</TableCell>
              <TableCell>Действие</TableCell>
              <TableCell align="center">USDT</TableCell>
              <TableCell align="center">RUB</TableCell>
              <TableCell align="center">Статус</TableCell>
              <TableCell align="center">Баланс</TableCell>
              <TableCell align="center">Дата</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history.map((h) => (
              <TableRow
                key={h._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {getEventName(h.dir)}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                  {getEventIcon(h.action)}
                </TableCell>
                <TableCell align="center">{h.sumUSD} USDT</TableCell>
                <TableCell align="center">
                  {h.sumRUB && `${h.sumRUB} RUB`}
                </TableCell>
                <TableCell
                  sx={{
                    "& svg": {
                      color: getIconColor(h.status),
                    },
                  }}
                  align="center"
                >
                  {getIcon(h.status)}
                </TableCell>
                <TableCell align="center">{h.balance} USDT</TableCell>
                <TableCell align="center">
                  {moment(h.createdAt).format("HH:mm DD.MM.YYYY")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function getIconColor(status) {
  if (status == "ok") {
    return "success.main";
  } else if (status == "cancel") {
    return "error.main";
  }
}

function getIcon(status) {
  if (status == "ok") {
    return <Icon fontSize="24px" icon="mdi:success-circle-outline" />;
  } else if (status == "cancel") {
    return <Icon fontSize="24px" icon="game-icons:cancel" />;
  }
}

function getEventIcon(status) {
  if (status == "add") {
    return <Icon fontSize="24px" icon="formkit:arrowdown" color="green" />;
  } else if (status == "sub") {
    return <Icon fontSize="24px" icon="formkit:arrowup" color="red" />;
  }
}

function getEventName(name) {
  switch (name) {
    case "deposit":
      return "Пополнение";
    case "withdraw":
      return "Вывод";
    case "receive":
      return "Получение";
    case "send":
      return "Перевод";
    case "profit":
      return "Прибыль";
    case "service-profit":
      return "Прибыль сервиса";
    default:
      return "Неизвестно";
  }
}

export default DepositTab;
