import { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";

import MerchantService from "src/service/Merchant";

// Components
import RequisiteCard from "src/modules/Merchant/Requisite/RequisiteCard";
// import RequisiteDrawer from "src/modules/Merchant/Requisite/RequisiteDrawer";
import socket from "src/socket";
import PageWrap from "src/components/PageWrap";

function MerchantRequisites() {
  const [requisites, setRequisites] = useState([]);

  useEffect(() => {
    MerchantService.request("admin.ser_requisites_get-all").then(({ data }) => {
      setRequisites(data);
    });
    // socket.on("merchant_deposit_add", (data) => {
    //   setDepositList((prev) => [data, ...prev]);
    // });
    socket.on("merchant_requisite_upd", (data) => {
      setRequisites((prev) => prev.map((d) => (d._id == data._id ? data : d)));
    });
    return () => {
      // socket.off("merchant_deposit_add");
      socket.off("merchant_requisite_upd");
    };
  }, []);

  return (
    <PageWrap title="Реквизиты партнеров">
      {/* <RequisiteDrawer /> */}
      <Grid container spacing={4}>
        {requisites.map((r) => (
          <Grid item key={r._id} xs={12} md={6} lg={3}>
            <RequisiteCard style={{ mb: 2 }} data={r} />{" "}
          </Grid>
        ))}
      </Grid>
    </PageWrap>
  );
}

export default MerchantRequisites;
