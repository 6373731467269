import { useState, useEffect } from "react";

//Router
import { useSearchParams, Link } from "react-router-dom";

import socket from "src/socket";

// Mui
import {
  Box,
  Drawer,
  CircularProgress,
  IconButton,
  Typography,
  CardContent,
  Divider,
  Link as MuiLink,
  Card,
  Alert,
  Grid,
  Button,
  TextField,
} from "@mui/material";

import MerchantService from "src/service/Merchant";

import Icon from "src/@core/components/icon";

import DepositChip from "src/components/Merchant/Deposit/DepositChip";
import Timer from "src/modules/Orders/Timer";
// Libs
import moment from "moment";
import "moment/locale/ru";

// Views
import TopupView from "./modules/TopupView";
import WithdrawView from "./modules/WithdrawView";

function DepositOrderDrawer() {
  // Hooks Router
  const [searchParams, setSearchParams] = useSearchParams();
  const depositId = searchParams.get("merchantDepositId");

  // States
  const [loading, setLoading] = useState(false);
  const [deposit, setDeposit] = useState(null);

  // Hooks
  useEffect(() => {
    if (!depositId) return;
    setLoading(true);
    MerchantService.request("admin.ser_deposit_get-by-id", {
      id: depositId,
    }).then(({ data }) => {
      setDeposit(data);
      setLoading(false);
    });

    socket.on("merchant_deposit_upd", (data) => {
      setDeposit((prev) => (prev._id == data._id ? data : prev));
    });
  }, [depositId]);

  useEffect(() => {
    return () => {
      socket.off("merchant_deposit_upd");
    };
  }, []);

  // Handler
  const handleClose = () => {
    searchParams.delete("merchantDepositId");
    setSearchParams(searchParams);
  };

  let hasData = !loading && deposit;

  return (
    <Drawer
      open={!!depositId}
      anchor="right"
      variant="temporary"
      onClose={handleClose}
      ModalProps={{ keepMounted: false }}
      sx={{ "& .MuiDrawer-paper": { width: { xs: "100%", sm: 600 } } }}
    >
      {hasData ? (
        <Content data={deposit} handleClose={handleClose} />
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Drawer>
  );
}

function Content({ handleClose, data }) {
  let viewSection;

  if (data.action == "topup") {
    viewSection = <TopupView data={data} />;
  } else if (data.action == "withdraw") {
    viewSection = <WithdrawView data={data} />;
  }

  return (
    <Box>
      <Box
        sx={{
          py: 3,
          px: 4,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <IconButton
          size="small"
          onClick={handleClose}
          sx={{ color: "text.primary" }}
        >
          <Icon icon="mdi:close" fontSize={20} />
        </IconButton>
      </Box>
      <CardContent sx={{ pt: 0 }}>
        <Info data={data} />
      </CardContent>
      <Divider />
      <CardContent>
        {data.code == "cancel" && data.comment ? (
          <Typography color="error" sx={{ mb: 3 }}>
            Комментарий: {data.comment}
          </Typography>
        ) : null}
        {viewSection}
      </CardContent>
    </Box>
  );
}
function Info({ data }) {
  let title = getTitleActon(data.action);

  function getTitleActon(action) {
    switch (action) {
      case "topup":
        return "Пополнение";
      case "withdraw":
        return "Вывод";
      default:
        return "";
    }
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ fontWeight: "bold" }} variant="h5">
            {title}
          </Typography>
        </Box>

        <Box>
          <DepositChip code={data.code} action={data.action} />
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <InfoRaw style={{ mb: 1 }} iconName="ph:hash-bold" text={data._id} />
          {data.code == "payment" && data.action == "topup" && (
            <Timer finished={data.expired} />
          )}
        </Box>
        <InfoRaw
          iconName="mdi:user-circle-outline"
          text={data.account.email}
          style={{ mb: 1 }}
          link={`/merchant/clients/${data.account._id}`}
        />
        <InfoRaw
          style={{ mb: 1 }}
          iconName="ion:time-outline"
          text={moment(data.createdAt).format("DD MMMM YYYY, в HH:mm")}
        />
      </Box>
    </Box>
  );
}

function InfoRaw(props) {
  let {
    style,
    textColor,
    link,
    external = false,
    iconName,
    text,
    TypographyProps = {},
    copy = false,
  } = props;

  let textComponent;

  if (external) {
    textComponent = (
      <MuiLink
        component={"a"}
        target="_blank"
        color="primary"
        underline="none"
        rel="noreferrer"
        variant="body1"
        href={link}
        {...TypographyProps}
      >
        {text}
      </MuiLink>
    );
  } else {
    textComponent = (
      <Typography
        color={link ? "primary" : textColor}
        component={link ? Link : undefined}
        to={link}
        {...TypographyProps}
      >
        {text}
      </Typography>
    );
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center", mb: 2, ...style }}>
      <Icon fontSize={20} icon={iconName} />
      <Box sx={{ ml: 1.5 }}>{textComponent}</Box>
    </Box>
  );
}

export default DepositOrderDrawer;
