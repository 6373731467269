// ** Custom Components
import CustomChip from "src/@core/components/mui/chip";

function DepositChip(props) {
  let { code, action, size = "medium", style = {} } = props;

  let codeData = getCodeData(code, action);


  return (
    <CustomChip
      skin="light"
      size={size}
      label={codeData.name}
      color={codeData.color}
      sx={{
        width: "min-content",
        fontSize: "1rem",
        fontWeight: 600,
        borderRadius: "5px",
        ...style,
      }}
    />
  );
}

function getCodeData(code, action) {
  switch (code) {
    case "payment":
      return {
        color: "warning",
        name: "Новая",
      };
    case "confirm-payment":
      return {
        color: "info",
        name: action == "topup" ? "Оплачена" : "Подтверждена",
      };
    case "done":
      return {
        color: "success",
        name: "Выполненна",
      };
    case "cancel":
      return {
        color: "error",
        name: "Отклоненна",
      };
    default:
      return {};
  }
}

export default DepositChip;
