//Mui
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

// Config
import ConfigApi from "src/configs/api";

//Custom components
import CustomAvatar from "src/@core/components/mui/avatar";

function CardCurrency(props) {
  let { currency, style = {} } = props;

  if (!currency) return null;

  return (
    <Card sx={{ bgcolor: (t) => t.palette.background.default, ...style }}>
      <CardContent
        sx={{ display: "flex", alignItems: "center", p: "8px !important" }}
      >
        <CustomAvatar
          skin="light"
          variant="rounded"
          sx={{ width: 35, height: 35, mr: 3 }}
        >
          <Avatar
            src={ConfigApi.PUBLIC_IMAGES + currency?.image + ".png"}
            alt={currency?.name}
            sx={{ height: 28, width: 28 }}
          />
        </CustomAvatar>
        <Typography variant="subtitle1">{currency?.name}</Typography>
      </CardContent>
    </Card>
  );
}

export default CardCurrency;
