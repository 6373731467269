// Mui
import { Box, Typography } from "@mui/material";

// ** Icon Imports
import Icon from "src/@core/components/icon";

function EmptyListStub({ text = "Нет данных", style = {} }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ...style,
      }}
    >
      <Box sx={{ mt: 3 }}>
        <Icon
          fontSize="38px"
          icon="streamline:mail-inbox-email-outbox-drawer-empty-open-inbox"
        />
      </Box>
      <Typography variant="body1" textAlign="center">
        {text}
      </Typography>
    </Box>
  );
}

export default EmptyListStub;
