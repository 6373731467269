import { useState, useEffect } from "react";
// Mui
import {
  Box,
  CardContent,
  Tab as MuiTab,
  Pagination,
  Grid,
  Card,
  CardActionArea,
  Typography,
  CircularProgress,
} from "@mui/material";
import { TabList, TabPanel, TabContext } from "@mui/lab";
import { styled } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Components
import EmptyListStub from "src/components/EmptyListStub";

import HistoryService from "src/service/History";
import moment from "moment";

// ** Styled Tab component
const Tab = styled(MuiTab)(({ theme }) => ({
  minHeight: 48,
  flexDirection: "row",
  "& svg": {
    marginBottom: "0 !important",
    marginRight: theme.spacing(1),
  },
}));

const pageRows = 10;

export default function Accruals({ clientId }) {
  const [activeTab, setActiveTab] = useState("referral");
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(true);

  let navigate = useNavigate();

  useEffect(() => {
    setPage(1);
    setPageCount(1);
    setList([]);
    setLoading(true);
    HistoryService.accrualsGet({
      name: activeTab,
      clientId: clientId,
      pageRows,
      page: page - 1,
    })
      .then((r) => {
        let data = r.data;
        setList(data.list);
        setPageCount(Math.ceil(data.rows / pageRows));
      })
      .finally(() => setLoading(false));
  }, [activeTab]);

  useEffect(() => {
    setLoading(true);
    HistoryService.accrualsGet({
      name: activeTab,
      clientId: clientId,
      pageRows,
      page: page - 1,
    })
      .then((r) => {
        let data = r.data;
        setList(data.list);
        setPageCount(Math.ceil(data.rows / pageRows));
      })
      .finally(() => setLoading(false));
  }, [page]);

  const handleChange = (event, value) => {
    setActiveTab(value);
  };

  let viewComponent;

  if (loading) {
    viewComponent = <Loader />;
  } else if (!list.length) {
    viewComponent = <EmptyListStub style={{my: 4}} />;
  } else {
    viewComponent = (
      <Grid sx={{ mt: 0 }} container spacing={4}>
        {list.map((c) => {
          return (
            <Grid key={c._id} item xs={12} md={6}>
              <AccrualCard c={c} navigate={navigate} />
            </Grid>
          );
        })}
      </Grid>
    );
  }

  return (
    <TabContext value={activeTab}>
      <TabList
        variant="scrollable"
        scrollButtons="auto"
        onChange={handleChange}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Tab
          value="referral"
          label="Реферральная программа"
          icon={<Icon icon="ic:baseline-people" />}
        />
        <Tab
          value="cashback"
          label="Кэшбек"
          icon={<Icon icon="mdi:account-cash-outline" />}
        />
      </TabList>
      {viewComponent}

      <Box
        sx={{
          mt: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Pagination
          count={pageCount}
          page={page}
          onChange={(e, v) => setPage(v)}
          shape="rounded"
          color="primary"
        />
      </Box>
    </TabContext>
  );
}

function Loader() {
  return (
    <Box
      sx={{
        display: "flex",
        pt: 4,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
}

function AccrualCard({ c, navigate }) {
  return (
    <Card>
      <CardActionArea>
        <CardContent
          sx={{ py: 3, px: 3 }}
          onClick={() => {
            navigate(`?orderId=${c.order}`);
          }}
        >
          <Typography sx={{ fontSize: "18px" }} variant="body1">
            {c.order}
          </Typography>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography fontWeight="bold" variant="body1">
              {c.sum} RUB
            </Typography>
            <Typography variant="body2">
              {moment(c.createdAt).format("HH:mm DD.MM.YYYY")}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
