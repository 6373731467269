import { useState } from "react";
// Mui
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  CircularProgress,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

//
import SITES from "src/configs/websites";

// Services
import TargetPairService from "src/service/TargetPair";
import toast from "react-hot-toast";

function DialogAddPairSite({ show, closeHandler, id, addItemHandler }) {
  let [isSubmiting, setIsSubmiting] = useState(false);
  let [selected, setSelected] = useState(SITES[0].id);

  // Handlers
  const onSubmit = (e) => {
    e.preventDefault();
    setIsSubmiting(true);
    TargetPairService.changeSequence(id, {
      action: "add",
      data: selected,
    })
      .then((r) => {
        addItemHandler(r.data);
        toast.success("Пара для сайта добавлена!");

        closeHandler();
      })
      .finally(() => {
        setIsSubmiting(false);
      });
  };

  return (
    <Dialog maxWidth="xs" open={show} onClose={closeHandler}>
      <DialogTitle textAlign="center">Добавить таргет для сайта</DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          <FormControl sx={{ mt: 2 }} fullWidth>
            <InputLabel id="demo-simple-select-label">Сайт</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selected}
              label="Сайт"
              onChange={(e) => setSelected(e.target.value)}
            >
              {SITES.map((s) => {
                return <MenuItem value={s.id}>{s.name}</MenuItem>;
              })}
            </Select>
          </FormControl>

          <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
            <Button
              startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
              disabled={isSubmiting}
              size="large"
              type="submit"
              variant="contained"
              sx={{ mr: 2 }}
              onClick={onSubmit}
            >
              Добавить
            </Button>
            <Button
              sx={{ mr: 2 }}
              type="reset"
              size="large"
              variant="outlined"
              color="secondary"
              onClick={closeHandler}
            >
              Отменить
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default DialogAddPairSite;
