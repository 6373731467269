import axios from "axios";

class TargetPair {
  getAllExchangers() {
    return axios.get("/target-pair/all-exchangers");
  }
  getPossibleExchanges() {
    return axios.get("/target-pair/possible-exchanges");
  }
  addPairTarget(data) {
    return axios.post("/target-pair", data);
  }
  changePairTarget(id, data) {
    return axios.put(`/target-pair/${id}`, data);
  }
  deletePairTarget(id) {
    return axios.delete(`/target-pair/${id}`);
  }
  getPairTargetList() {
    return axios.get("/target-pair");
  }
  getPairTargetFull(params) {
    return axios.get("/target-pair/full", { params });
  }
  changeException(id, data) {
    return axios.put(`/target-pair/${id}/exception`, data);
  }
  changeSequence(id, data) {
    return axios.put(`/target-pair/${id}/sequence`, data);
  }
}

export default new TargetPair();
