import { useState, useEffect } from "react";

import {
  Card,
  CardContent,
  Box,
  Grid,
  Typography,
  Link as MuiLink,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";

import Timer from "src/modules/Orders/Timer";

import MerchantService from "src/service/Merchant";

import socket from "src/socket";

import { toast } from "react-hot-toast";

import OrderPartnerStatusChip from "./StatusChip";

function MerchantOrderCard({ data, orderId }) {
  let [order, setOrder] = useState(data);

  useEffect(() => {
    socket.on("merchant_order-receive_upd", (upd) => {
      setOrder((order) => Object.assign({}, order, { ...upd }));
    });
    return () => {
      socket.off("merchant_order-receive_upd");
    };
  }, []);

  return (
    <Card sx={{ bgcolor: (t) => t.palette.background.paper, mb: 2 }}>
      <CardContent
        sx={{
          p: "12px 16px !important",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <MuiLink
            component={"a"}
            target="_blank"
            color="primary"
            underline="none"
            rel="noreferrer"
            variant="body1"
            href={`/merchant/clients/${data.account._id}`}
          >
            {data.account.email}
          </MuiLink>
          <OrderPartnerStatusChip isReceive status={order.status} />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
          <Box>
            {order.comment && (
              <Typography variant="body2">{order.comment}</Typography>
            )}
          </Box>
          <Box>
            {order.status == "wait" && <Timer finished={order.expired} />}
          </Box>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
          <Typography variant="body1">{order.sum} RUB</Typography>
          <Typography variant="body2">
            Прибыль партнера: {order.profitSum} RUB ({order.profitPercent}%)
          </Typography>
        </Box>
        <Actions status={order.status} orderId={orderId} />
      </CardContent>
    </Card>
  );
}

function Actions({ status, orderId }) {
  let [submit, setSubmit] = useState(false);
  let [isCancelView, setCancelView] = useState(false);
  let [comment, setComment] = useState("");

  let view = null;

  const changeStatusHandler = (newStatus, comment = "") => {
    setSubmit(newStatus);
    MerchantService.request("admin.ser_order_receive-change", {
      newStatus,
      id: orderId,
      comment,
    })
      .then(() => {
        toast.success("Статус изменен");
        setCancelView(false);
      })
      .finally(() => {
        setSubmit();
      });
  };

  const recoverHandler = () => {
    if (!window.confirm("Вы уверены?")) return;
    changeStatusHandler("wait");
  };
  const doneHandler = () => {
    if (!window.confirm("Вы уверены?")) return;
    changeStatusHandler("done", "Администратор подтвердил получение средств.");
  };

  const cancelHandler = () => {
    if (!window.confirm("Вы уверены?")) return;
    changeStatusHandler(
      "cancel",
      comment || "Администратор отменил получение средств."
    );
  };

  if (status == "wait") {
    view = (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Button
            disabled={submit}
            startIcon={submit == "done" ? <CircularProgress /> : null}
            fullWidth
            onClick={doneHandler}
            variant="contained"
          >
            Получил
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            onClick={() => setCancelView(true)}
            fullWidth
            color="error"
            variant="outlined"
          >
            Отклонить
          </Button>
        </Grid>
      </Grid>
    );
  } else if (status == "cancel") {
    view = (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Button
            onClick={recoverHandler}
            disabled={submit}
            startIcon={submit == "wait" ? <CircularProgress /> : null}
            variant="contained"
          >
            Восстановить
          </Button>
        </Grid>
      </Grid>
    );
  } else if (status == "done") {
    view = (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Button
            onClick={() => setCancelView(true)}
            fullWidth
            color="error"
            variant="outlined"
          >
            Отклонить
          </Button>
        </Grid>
      </Grid>
    );
  }

  if (isCancelView) {
    view = (
      <Grid sx={{ mt: 2 }} container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Причина отмены"
            variant="outlined"
            size="small"
            multiline
            rows={2}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            onClick={cancelHandler}
            disabled={submit}
            startIcon={submit == "cancel" ? <CircularProgress /> : null}
            fullWidth
            color="error"
            variant="contained"
          >
            Отклонить
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            onClick={() => setCancelView(false)}
            fullWidth
            color="error"
            variant="outlined"
          >
            Отменить
          </Button>
        </Grid>
      </Grid>
    );
  }

  return <Box sx={{ mt: 2 }}>{view}</Box>;
}

export default MerchantOrderCard;
