import { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  Button,
  CircularProgress,
  Typography,
  Divider,
} from "@mui/material";

import WalletService from "src/service/Wallet";
import toast from "react-hot-toast";

function AddRequisitesDialog({
  show,
  handleClose,
  merchantCode,
  kit,
  addItemsHandler,
}) {
  const [isSubmit, setIsSubmit] = useState(false);
  const [requisites, setRequisites] = useState([]);
  const [selected, setSelected] = useState([]);

  const closeHandler = () => {
    handleClose();
    setSelected([]);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    WalletService.addMerchantRequisite({
      list: selected,
      kit: kit._id,
    })
      .then(({ data }) => {
        addItemsHandler(data);
        toast.success("Реквизиты добавлены!");
        closeHandler()
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const itemCheckSelected = (id) => {
    return selected.includes(id);
  };
  const toggleChecked = (id) => {
    if (itemCheckSelected(id)) {
      setSelected(selected.filter((item) => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  useEffect(() => {
    if (!show) return;
    WalletService.getByMerchantCodeRequisite(merchantCode).then(({ data }) => {
      setRequisites(data);
    });
  }, [show, merchantCode]);

  return (
    <Box>
      <Dialog fullWidth maxWidth="xs" open={show} onClose={closeHandler}>
        <DialogTitle sx={{ pb: 0 }}>Добавить реквизиты партнеров</DialogTitle>
        <DialogContent sx={{ mt: 4 }}>
          <Box sx={{ maxHeight: "70vh", overflowY: "scroll" }}>
            {requisites.map(({ _id, email, name, fields }) => {
              return (
                <Box sx={{ mb: 4 }} key={_id}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography variant="body1">{email}</Typography>
                      {name && <Typography variant="body1">{name}</Typography>}
                      <Box>
                        {fields.map((f) => {
                          return (
                            <Box>
                              <Typography variant="body2">{f.name}:</Typography>
                              <Typography variant="body2">{f.value}</Typography>
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                    <Checkbox
                      checked={itemCheckSelected(_id)}
                      onChange={() => toggleChecked(_id)}
                    />
                  </Box>

                  <Divider />
                </Box>
              );
            })}
          </Box>
        </DialogContent>

        <DialogActions sx={{ pb: { xs: 4, sm: 6 }, justifyContent: "center" }}>
          <Button
            startIcon={isSubmit ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmit}
            variant="contained"
            sx={{ mr: 2 }}
            type="submit"
            onClick={onSubmit}
          >
            Добавить
          </Button>
          <Button variant="outlined" color="secondary" onClick={closeHandler}>
            Отмена
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AddRequisitesDialog;
