// Mui
import { Box } from "@mui/material";

// Components
import SimpleSelectCurrency from "src/components/Currency/SimpleSelectCurrency";

function SelectPair({
  style = {},
  pairs = [],
  takeId,
  giveId,
  setTakeId,
  setGiveId,
}) {
  const takes = [
    ...new Map(
      pairs.map((p) => p.take).map((item) => [item._id, item])
    ).values(),
  ];

  const gives = takeId
    ? pairs.filter((p) => p.take._id == takeId).map((p) => p.give)
    : [];

  return (
    <Box sx={{ ...style }}>
      <SimpleSelectCurrency
        label="Отдаю"
        containerStyle={{ mt: 2 }}
        currencies={takes}
        value={takeId}
        onChange={(v) => setTakeId(v)}
      />
      <SimpleSelectCurrency
        label="Получаю"
        containerStyle={{ mt: 2 }}
        currencies={gives}
        value={giveId}
        onChange={(v) => setGiveId(v)}
      />
    </Box>
  );
}

export default SelectPair;
