import React from "react";

// Mui
import { Box, Typography } from "@mui/material";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, message: "" };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, message: `${error.message} ${error.stack}` };
  }

  componentDidCatch(error, info) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App

    // this.setState({ message: error });
    // console.log("Error:", error, 'component:', info.componentStack);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Box>
          <Typography
            sx={{ fontWeight: "bold", textAlign: "center", mt: 4 }}
            color="error"
          >
            Упс! Произошла ошибка...
          </Typography>
          {this?.state?.message && (
            <Typography
              sx={{ mt: 4, px: 4 }}
            >
              {this?.state?.message}
            </Typography>
          )}
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
