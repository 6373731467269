import {
  Card,
  CardContent,
  CardActionArea,
  Box,
  Typography,
  Avatar,
  Divider,
} from "@mui/material";

import CustomAvatar from "src/@core/components/mui/avatar";
// Config
import moment from "moment";
import "moment/locale/ru";
// Router
import { useNavigate } from "react-router-dom";

import StatusChip from "./StatusChip";

import Icon from "src/@core/components/icon";

function OrderCard({ data }) {
  const navigate = useNavigate();

  let viewContent = data.isReceive ? (
    <ReceiveContent data={data} />
  ) : (
    <SendContent data={data} />
  );

  return (
    <Card
      onClick={() => {
        navigate(`?merchantOrderId=${data._id}`);
      }}
    >
      <CardActionArea>{viewContent}</CardActionArea>
    </Card>
  );
}

function SendContent({ data }) {
  return (
    <Box>
      <CardContent sx={{ py: 3, px: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& svg": {
                color: (t) => t.palette.primary.light,
              },
            }}
          >
            <Typography sx={{ fontSize: "18px", mr: 1 }} variant="body1">
              Перевод
            </Typography>
          </Box>
          <StatusChip
            status={data.status}
            isReceive={data.isReceive}
            isSend={data.isSend}
          />
        </Box>
        <FieldItem icon="ph:hash" value={data._id} />
        <FieldItem icon="lets-icons:order" value={data.orderId} />
        <FieldItem icon="mdi:user-circle-outline" value={data.account.email} />
      </CardContent>
      <Divider />
      <CardContent sx={{ py: 3, px: 3 }}>
        <SumAndCurrency
          name={data.currency.name}
          sum={data.sum}
          image={data.currency.logo}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 2,
          }}
        >
          <Typography
            sx={{
              alignSelf: "flex-end",
            }}
            variant="caption"
          >
            {moment(data.createdAt).format("HH:mm DD.MM.YYYY")}
          </Typography>
        </Box>
      </CardContent>
    </Box>
  );

  //  sum  currency
}

function ReceiveContent({ data }) {
  return (
    <Box>
      <CardContent sx={{ py: 3, px: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& svg": {
                color: (t) => t.palette.primary.light,
              },
            }}
          >
            <Typography sx={{ fontSize: "18px", mr: 1 }} variant="body1">
              Получение
            </Typography>
          </Box>
          <StatusChip
            status={data.status}
            isReceive={data.isReceive}
            isSend={data.isSend}
          />
        </Box>
        <FieldItem icon="ph:hash" value={data._id} />
        <FieldItem icon="lets-icons:order" value={data.orderId} />
        <FieldItem icon="mdi:user-circle-outline" value={data.account.email} />
      </CardContent>
      <Divider />
      <CardContent sx={{ py: 3, px: 3 }}>
        <SumAndCurrency
          name={data.currency.name}
          sum={data.sum}
          image={data.currency.logo}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 2,
          }}
        >
          <Typography
            sx={{
              alignSelf: "flex-end",
            }}
            variant="caption"
          >
            {moment(data.createdAt).format("HH:mm DD.MM.YYYY")}
          </Typography>
        </Box>
      </CardContent>
      {/* <FieldItem icon="ph:hash" value={data.}/> */}
    </Box>
  );
}

function SumAndCurrency(props) {
  let { name, sum, image } = props;
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <CustomAvatar
        skin="light"
        variant="rounded"
        sx={{ width: 38, height: 38, mr: 3 }}
      >
        <Avatar src={image} alt={name} sx={{ height: 30, width: 30 }} />
      </CustomAvatar>
      <Box>
        <Typography
          fontWeight="bold"
          variant="body1"
        >{`${sum} RUB`}</Typography>
        <Typography noWrap variant="body2">
          {name}
        </Typography>
      </Box>
    </Box>
  );
}

function FieldItem({ icon, value }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", mt: 2.5 }}>
      <Icon fontSize={18} icon={icon} />
      <Typography
        // color={isLink ? "primary" : textColor}
        // component={isLink ? Link : undefined}
        // to={link}
        sx={{ ml: 1.5 }}
        variant="body2"
      >
        {value}
      </Typography>
    </Box>
  );
}

export default OrderCard;
