import { useState, useEffect } from "react";

//Router
import { useSearchParams, Link } from "react-router-dom";

import socket from "src/socket";

// Mui
import {
  Box,
  Drawer,
  CircularProgress,
  IconButton,
  Typography,
  CardContent,
  Divider,
  Link as MuiLink,
  Card,
  Alert,
  Grid,
  Button,
  TextField,
} from "@mui/material";

import MerchantService from "src/service/Merchant";

import Icon from "src/@core/components/icon";

import SumTitle from "src/modules/Orders/OrderDrawer/Modules/SumTitle";
import DepositChip from "src/components/Merchant/Deposit/DepositChip";
import WalletInfo from "src/modules/Orders/OrderDrawer/Modules/WalletInfo";
// import Timer from "src/modules/Orders/Timer";
import FieldsRaw from "./modules/FieldsRaw";
import StatusChip from "../StatusChip";
import Cur from "../Cur";
// Libs
import moment from "moment";
import "moment/locale/ru";
import toast from "react-hot-toast";
// import CardSend from "./SendCard";

function RequisiteDrawer() {
  // Hooks Router
  const [searchParams, setSearchParams] = useSearchParams();
  const requisiteId = searchParams.get("merchantRequisiteId");

  // States
  const [loading, setLoading] = useState(false);
  const [requisite, setRequisite] = useState(null);

  // Hooks
  useEffect(() => {
    if (!requisiteId) return;
    setLoading(true);
    MerchantService.request("admin.ser_requisite_get-by-id", {
      id: requisiteId,
    }).then(({ data }) => {
      setRequisite(data);
      setLoading(false);
    });

    socket.on("merchant_requisite_upd", (data) => {
      setRequisite((prev) => (prev._id == data._id ? data : prev));
    });
  }, [requisiteId]);

  useEffect(() => {
    return () => {
      socket.off("merchant_requisite_upd");
    };
  }, []);

  // Handler
  const handleClose = () => {
    searchParams.delete("merchantRequisiteId");
    setSearchParams(searchParams);
  };

  let hasData = !loading && requisite;

  return (
    <Drawer
      open={!!requisiteId}
      anchor="right"
      variant="temporary"
      onClose={handleClose}
      ModalProps={{ keepMounted: false }}
      sx={{ "& .MuiDrawer-paper": { width: { xs: "100%", sm: 600 } } }}
    >
      {hasData ? (
        <Content data={requisite} handleClose={handleClose} />
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Drawer>
  );
}

function Content({ handleClose, data }) {
  let viewSection;

  let isNotVerify = data.status == "not-verified";

  return (
    <Box>
      <Box
        sx={{
          py: 3,
          px: 4,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <IconButton
          size="small"
          onClick={handleClose}
          sx={{ color: "text.primary" }}
        >
          <Icon icon="mdi:close" fontSize={20} />
        </IconButton>
      </Box>
      <CardContent sx={{ pt: 0 }}>
        <TopInfo data={data} />
      </CardContent>
      <Divider />
      <CardContent>
        <Cur name={data?.currency?.name} image={data?.currency?.logo} />

        {data.status == "fail-verified" && !!data.comment && (
          <Typography color="error" sx={{ mt: 2 }}>
            Комментарий: {data.comment}
          </Typography>
        )}
        <Card sx={{ bgcolor: (t) => t.palette.background.default, mt: 3 }}>
          <CardContent sx={{ p: 3 }}>
            <Box>
              {data.fields.map((f) => (
                <FieldsRaw key={f._id} name={f.name} value={f.value} />
              ))}
            </Box>
            {isNotVerify && (
              <Alert sx={{ p: "4px 10px", mt: 2 }} severity="info">
                Пользователь еще не подтвердил отправление на проверку.
              </Alert>
            )}
          </CardContent>
          {!isNotVerify && <Actions status={data.status} _id={data._id} />}
        </Card>
      </CardContent>
    </Box>
  );
}
function TopInfo({ data }) {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ fontWeight: "bold" }} variant="h5">
            Проверка реквизита
          </Typography>
        </Box>

        <Box>
          <StatusChip status={data.status} size="large" />
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>
        <InfoRaw
          iconName="mdi:user-circle-outline"
          text={data.account.email}
          style={{ mb: 1 }}
          link={`/merchant/clients/${data.account._id}`}
        />
        <InfoRaw
          style={{ mb: 1 }}
          iconName="ion:time-outline"
          text={moment(data.createdAt).format("DD MMMM YYYY, в HH:mm")}
        />
      </Box>
    </Box>
  );
}

function Actions({ status, _id }) {
  let [submit, setSubmit] = useState("");
  let [isViewCancel, setIsViewCancel] = useState(false);
  let [comment, setComment] = useState("");
  //         "on-verified",
  //         "verified",
  //         "fail-verified",
  //         "blocked",

  let view;

  const blockHandler = () => {
    if (!window.confirm("Вы уверены?")) return;
    setSubmit("blocked");
    MerchantService.request("admin.ser_requisite_change", {
      action: "blocked",
      _id,
    })
      .then(() => {
        toast.success("Реквизит заблокирован");
      })
      .finally(() => {
        setSubmit("");
      });
  };

  const verifiedHandler = () => {
    if (!window.confirm("Вы уверены?")) return;
    setSubmit("verified");
    MerchantService.request("admin.ser_requisite_change", {
      action: "verified",
      _id,
    })
      .then(() => {
        toast.success("Реквизит проверен");
      })
      .finally(() => {
        setSubmit("");
      });
  };

  const cancelHandler = () => {
    if (!window.confirm("Вы уверены?")) return;
    setSubmit("fail-verified");
    MerchantService.request("admin.ser_requisite_change", {
      action: "fail-verified",
      _id,
      comment,
    })
      .then(() => {
        toast.success("Реквизит отклонен");
        setIsViewCancel(false);
      })
      .finally(() => {
        setSubmit("");
      });
  };

  const cancelView = (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Комментарий"
          placeholder="Не обязательно"
          size="small"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <Button
          disabled={submit == "fail-verified"}
          startIcon={submit == "fail-verified" && <CircularProgress />}
          fullWidth
          onClick={cancelHandler}
          variant="contained"
        >
          Отклонить
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          onClick={() => setIsViewCancel(false)}
          fullWidth
          color="error"
          variant="outlined"
        >
          Отменить
        </Button>
      </Grid>
    </>
  );

  if (status == "on-verified") {
    view = (
      <>
        <Grid item xs={6}>
          <Button
            disabled={submit == "verified"}
            startIcon={submit == "verified" && <CircularProgress />}
            fullWidth
            onClick={verifiedHandler}
            variant="contained"
          >
            Проверено
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={() => setIsViewCancel(true)}
            fullWidth
            color="error"
            variant="outlined"
          >
            Отклонить
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            disabled={submit == "blocked"}
            startIcon={submit == "blocked" && <CircularProgress />}
            fullWidth
            onClick={blockHandler}
            variant="outlined"
            color="secondary"
          >
            Заблокировать
          </Button>
        </Grid>
      </>
    );
  } else if (status == "verified") {
    view = (
      <>
        <Grid item xs={6}>
          <Button
            onClick={() => setIsViewCancel(true)}
            fullWidth
            color="error"
            variant="outlined"
          >
            Отклонить
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            disabled={submit == "blocked"}
            startIcon={submit == "blocked" && <CircularProgress />}
            fullWidth
            onClick={blockHandler}
            variant="outlined"
            color="secondary"
          >
            Заблокировать
          </Button>
        </Grid>
      </>
    );
  } else if (status == "fail-verified") {
    view = (
      <>
        <Grid item xs={6}>
          <Button
            disabled={submit == "verified"}
            startIcon={submit == "verified" && <CircularProgress />}
            fullWidth
            onClick={verifiedHandler}
            variant="contained"
          >
            Проверено
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            disabled={submit == "blocked"}
            startIcon={submit == "blocked" && <CircularProgress />}
            fullWidth
            onClick={blockHandler}
            variant="outlined"
            color="secondary"
          >
            Заблокировать
          </Button>
        </Grid>
      </>
    );
  } else if (status == "blocked") {
    view = (
      <>
        <Grid item xs={6}>
          <Button
            disabled={submit == "verified"}
            startIcon={submit == "verified" && <CircularProgress />}
            fullWidth
            onClick={verifiedHandler}
            variant="contained"
          >
            Проверено
          </Button>
        </Grid>
      </>
    );
  }

  view = isViewCancel ? cancelView : view;

  return (
    <CardContent sx={{ py: 1 }}>
      <Grid container spacing={4}>
        {view}
      </Grid>
    </CardContent>
  );
}

function InfoRaw(props) {
  let {
    style,
    textColor,
    link,
    external = false,
    iconName,
    text,
    TypographyProps = {},
    copy = false,
  } = props;

  let textComponent;

  if (external) {
    textComponent = (
      <MuiLink
        component={"a"}
        target="_blank"
        color="primary"
        underline="none"
        rel="noreferrer"
        variant="body1"
        href={link}
        {...TypographyProps}
      >
        {text}
      </MuiLink>
    );
  } else {
    textComponent = (
      <Typography
        color={link ? "primary" : textColor}
        component={link ? Link : undefined}
        to={link}
        {...TypographyProps}
      >
        {text}
      </Typography>
    );
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center", mb: 2, ...style }}>
      <Icon fontSize={20} icon={iconName} />
      <Box sx={{ ml: 1.5 }}>{textComponent}</Box>
    </Box>
  );
}

export default RequisiteDrawer;
