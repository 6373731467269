// Mui
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

function CountTextField(props) {
  let { label = "", name = "", count = 0, value, setValue, disabledValue = false } = props;
  return (
    <TextField
      size="small"
      disabled={disabledValue}
      name={name}
      label={label}
      variant="outlined"
      sx={{
        "& .MuiOutlinedInput-root": {
          paddingLeft: 0,
        },
        "& input[type=number]": {
          "-moz-appearance": "textfield",
        },
        "& input[type=number]::-webkit-outer-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        "& input[type=number]::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
      }}
      // required
      type="number"
      fullWidth
      value={value}
      onChange={(e) => setValue(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment
            sx={{
              width: "100%",
              backgroundColor: (theme) => theme.palette.divider,
              height: "100% !important",
              p: "20px 14px",
              borderTopLeftRadius: (theme) => theme.shape.borderRadius + "px",
              borderBottomLeftRadius: (theme) =>
                theme.shape.borderRadius + "px",
              "& p": {
                textAlign: "right",
                width: "100%",
                fontSize: "14px",
              },
            }}
            position="start"
          >
            {count}
          </InputAdornment>
        ),
      }}
    />
  );
}

export default CountTextField;
