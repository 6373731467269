import { Typography, Box } from "@mui/material";

// Components
import PageWrap from "src/components/PageWrap";

const list = [
  {
    text: "Расширен формат ссылки на блокчейн транзакцию.",
    date: "1.11",
  },
  {
    text: "Попарвлен подсчет прибыли, после изменения суммы кэшбека, или реф. начисления заявки.",
    date: "25.10",
  },
  {
    text: "Отображения статуса онлайн у операторов и у клиентов",
    date: "24.10",
  },
  {
    text: "Добавлена информация о начислениях (реферральная программа, кэшбек), а также вывод вознаграждений.",
    date: "20.10",
  },
  {
    text: "Добавлена расширенная сортировка на странице списка клиентов. По таким полям как: email, сайт, лояльность, активирован, кол-во заявок, оборот тд.",
    date: "19.10",
  },
  {
    text: "При блокировке реквизита - он отключается",
    date: "18.10",
  },
  {
    text: "Переделан формат таргета, с добавлением исключенных обменников",
    date: "17.10",
  },
  {
    text: "Функция поиска заявок по тексту, а также мультифильтр с возможностью поиска по: имени, номеру, полям, валютам и тд.",
    date: "04.10",
  },
  {
    text: "Функция поиска карт по тексту, и банку",
    date: "04.10",
  },
];

function Updates() {
  return (
    <PageWrap title="Последние обновления">
      {list.map((t) => {
        return (
          <Box sx={{mb: 4}}>
            <Typography variant="body1">{t.text}</Typography>
            <Typography variant="subtitle2">{t.date}</Typography>
          </Box>
        );
      })}
    </PageWrap>
  );
}

export default Updates;
