import axios from "axios";

const globalNotificationService = {
  async getAll() {
    return axios.get("/global-notification");
  },
  async delete(id) {
    return axios.delete("/global-notification", { data: { id: id } });
  },
};

export { globalNotificationService };
