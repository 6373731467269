import React from "react";

// socket
import { socket } from "src/socket";

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      favicon: null,
      notificationFavicon: null,
      notificationCount: 0,
      isVisible: true,
    };
  }

  componentDidMount() {
    this.createFavicon();
  }

  componentWillMount() {
    let than = this;
    document.addEventListener("visibilitychange", function (event) {
      let isVisible = document.visibilityState === "visible";
      if (isVisible) {
        than.clearNotification();
      }
    });
    socket.on("notify_message", (m) => {
      if (document.visibilityState !== "visible") {
        than.addNotification();
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener("visibilitychange");
    socket.off("notify_message");
  }

  createFavicon = () => {
    // Save original favicon
    const favicon = document.getElementById("favicon");
    this.setState({ favicon: favicon.href });

    // Create new favicon using the existing favicon but add red dot
    const img = document.createElement("img");
    img.src = favicon.href;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const context = canvas.getContext("2d");
      context.drawImage(img, 0, 0, img.width, img.height);
      context.beginPath();
      context.arc(
        img.width - img.width / 5,
        img.height / 5,
        img.width / 5,
        0,
        2 * Math.PI
      );
      context.fillStyle = "#f00000";
      context.fill();
      this.setState({ notificationFavicon: canvas.toDataURL("image/png") });
    };
  };

  addNotification = () => {
    document.getElementById("favicon").href = this.state.notificationFavicon;
  };

  clearNotification = () => {
    document.getElementById("favicon").href = this.state.favicon;
  };

  render() {
    return <div></div>;
  }
}

export default Notifications;

/* The Instructions component is for demo purposes only */
