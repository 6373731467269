import { useState, useEffect } from "react";
// Mui
import { Box, Grid, Card, CardContent, Button } from "@mui/material";

// Components
import PageWrap from "src/components/PageWrap";

// Modules
import SelectFilter from "./SelectFilter";
import ModalCreatePair from "./ModalCreatePair";
import SelectPair from "./SelectPair";
import TargetPairList from "./TargetPairList";

// Services
import TargetPairService from "src/service/TargetPair";

function Target2() {
  // States
  const [statusFilter, setStatusFilter] = useState("all");
  const [showCreatePair, setShowCreatePair] = useState(false);

  // Pairs
  const [pairs, setPairs] = useState([]);
  const [takeId, setTakeId] = useState("");
  const [giveId, setGiveId] = useState("");

  // Hooks

  useEffect(() => {
    TargetPairService.getPairTargetList().then((r) => {
      let list = r.data;
      setPairs(list);
      setTakeId(list[0].take._id);
      setGiveId(list[0].give._id);
    });
  }, []);

  useEffect(() => {
    if (!takeId) return;
    let first = pairs.find((p) => p.take._id == takeId);
    setGiveId(first.give._id);
  }, [takeId]);

  // Handlers
  const addItemPair = (item) => {
    setPairs((list) => [item, ...list]);
    setTakeId(item.take._id);
    setGiveId(item.give._id);
    setStatusFilter('pair')

  };

  return (
    <PageWrap title="Таргет">
      <ModalCreatePair
        addItemPair={addItemPair}
        show={showCreatePair}
        handleClose={() => {
          setShowCreatePair(false);
        }}
      />
      <Grid container spacing={4}>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <SelectFilter
                statusFilter={statusFilter}
                setStatusFilter={setStatusFilter}
              />
              {statusFilter == "pair" && (
                <SelectPair
                  style={{ mt: 2 }}
                  pairs={pairs}
                  takeId={takeId}
                  setTakeId={setTakeId}
                  giveId={giveId}
                  setGiveId={setGiveId}
                />
              )}
              <Button
                variant="contained"
                sx={{ mt: 4 }}
                fullWidth
                onClick={() => setShowCreatePair(true)}
              >
                Добавить
              </Button>
            </CardContent>
          </Card>

        </Grid>
        <Grid item xs={12} md={9}>
          <TargetPairList
            statusFilter={statusFilter}
            takeId={takeId}
            giveId={giveId}
          />
        </Grid>
      </Grid>
    </PageWrap>
  );
}

export default Target2;
