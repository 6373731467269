import { useState } from "react";
import MerchantService from "src/service/Merchant";
import {
    Box,
    Typography,
    IconButton,
    Grid,
    Button,
    TextField,
    Collapse,
    CircularProgress,
    CardActionArea,
    Link as MuiLink,
  } from "@mui/material";
  

import { toast } from "react-hot-toast";


function SendActions({ order, style = {}}) {
    let [submit, setSubmit] = useState(false);
    let [isCancelView, setCancelView] = useState(false);
    let [comment, setComment] = useState("");
  
    let orderStatus = order.status;
    let view = null;
  
    const changeStatusHandler = (newStatus, comment = "") => {
      setSubmit(newStatus);
      MerchantService.request("admin.ser_order_send-change", {
        newStatus,
        id: order._id,
        comment,
      })
        .then(() => {
          toast.success("Статус изменен");
          setCancelView(false);
        })
        .finally(() => {
          setSubmit();
        });
    };
  
    const recoverHandler = () => {
      if (!window.confirm("Вы уверены?")) return;
      changeStatusHandler(
        "wait",
        "Администратор восстановил заявку на перевод средств."
      );
    };
    const doneHandler = () => {
      if (!window.confirm("Вы уверены?")) return;
      changeStatusHandler("done", "Администратор подтвердил перевод средств.");
    };
  
    const cancelHandler = () => {
      if (!window.confirm("Вы уверены?")) return;
      changeStatusHandler(
        "cancel",
        comment || "Администратор отменил перевод средств."
      );
    };
  
    if (orderStatus == "wait" || orderStatus == "selected") {
      view = (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Button
              disabled={submit}
              startIcon={submit == "done" ? <CircularProgress /> : null}
              fullWidth
              onClick={doneHandler}
              variant="contained"
            >
              Перевел
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              onClick={() => setCancelView(true)}
              fullWidth
              color="error"
              variant="outlined"
            >
              Отклонить
            </Button>
          </Grid>
        </Grid>
      );
    } else if (orderStatus == "done") {
      view = (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Button
              onClick={() => setCancelView(true)}
              fullWidth
              color="error"
              variant="outlined"
            >
              Отклонить
            </Button>
          </Grid>
        </Grid>
      );
    } else if (orderStatus == "cancel") {
      view = (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Button
              onClick={recoverHandler}
              disabled={submit}
              fullWidth
              startIcon={submit == "wait" ? <CircularProgress /> : null}
              variant="contained"
            >
              Восстановить
            </Button>
          </Grid>
        </Grid>
      );
    }
  
    if (isCancelView) {
      view = (
        <Grid sx={{ mt: 2 }} container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Причина отмены"
              variant="outlined"
              size="small"
              multiline
              rows={2}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              onClick={cancelHandler}
              disabled={submit}
              startIcon={submit == "cancel" ? <CircularProgress /> : null}
              fullWidth
              color="error"
              variant="contained"
            >
              Отклонить
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              onClick={() => setCancelView(false)}
              fullWidth
              color="error"
              variant="outlined"
            >
              Отменить
            </Button>
          </Grid>
        </Grid>
      );
    }
  
    return <Box sx={{ mt: 2,...style }}>{view}</Box>;
  }

  export default SendActions;