import { useState } from "react";
// Mui
import { Box, Grid, Alert, Typography } from "@mui/material";

// Components
import StatMiniCard from "src/components/Stat/MiniCard";

// Store
import { observer } from "mobx-react-lite";

// ** Utils
import getPriceCurrentFiat from "src/helpers/getPriceCurrentFiat";

function General({ data }) {
  let [showInfo, setShowInfo] = useState(true);

  return (
    <Box>
      {showInfo && (
        <Alert
          onClose={(e) => {
            e.preventDefault();
            setShowInfo(false);
          }}
          sx={{ mb: 4 }}
          severity="info"
        >
          1. Цена на Входящий и Исходящий оборот считается по цене валют на
          момент создания заявки если есть данные, иначе по текущему.
          <br />
          2. Внутренние переводы всегда общие, и не привязаны к сайту. <br />
          3. Внутренний перевод, приход и прибыль подсчитываются исходя из
          наличия данных.
          <Typography variant="body2" textAlign="end">
            upd 11.09.2023
          </Typography>
        </Alert>
      )}

      <Grid container spacing={4}>
        <Grid item xs={6} md={4}>
          <StatMiniCard
            color="primary"
            icon="mdi:user"
            title="Клиентов"
            stats={data.count.clients}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <StatMiniCard
            color="warning"
            icon="material-symbols:credit-card-outline"
            title="Карт"
            stats={data.count.cards}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <StatMiniCard
            color="primary"
            icon="ic:twotone-currency-exchange"
            title="Заявок"
            stats={data.count.orders}
          />
        </Grid>
        <Grid item xs={6}>
          <StatMiniCard
            color="success"
            icon="mdi:arrow-down-bold-box-outline"
            title="Входящий оборот"
            stats={getPriceCurrentFiat(data.turnover.incoming, 0)}
          />
        </Grid>
        <Grid item xs={6}>
          <StatMiniCard
            color="error"
            icon="mdi:arrow-top-bold-box-outline"
            title="Исходящий оборот"
            stats={getPriceCurrentFiat(data.turnover.outgoing, 0)}
          />
        </Grid>

        <Grid item xs={6}>
          <StatMiniCard
            color="warning"
            icon="ic:round-get-app"
            title="Приход"
            stats={getPriceCurrentFiat(data.turnover.earnings, 0)}
          />
        </Grid>
        <Grid item xs={6}>
          <StatMiniCard
            color="primary"
            icon="mingcute:transfer-fill"
            title="Внутренние переводы"
            stats={getPriceCurrentFiat(data.turnover.costs, 0)}
          />
        </Grid>
        <Grid item xs={6}>
          <StatMiniCard
            color="info"
            icon="mdi:cash-chargeback"
            title="Кэшбэк"
            stats={getPriceCurrentFiat(data.turnover.cashBack, 0)}
          />
        </Grid>
        <Grid item xs={6}>
          <StatMiniCard
            color="warning"
            icon="mdi:invite"
            title="Реф. программа"
            stats={getPriceCurrentFiat(data.turnover.referral, 0)}
          />
        </Grid>
        <Grid item xs={6}>
          <StatMiniCard
            color="success"
            icon="ph:money"
            title="Прибыль"
            stats={getPriceCurrentFiat(data.turnover.profit, 0)}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default observer(General);
