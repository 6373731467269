// ** MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// ** Hooks Imports
import useBgColor from "src/@core/hooks/useBgColor";

// Config
import ConfigApi from "src/configs/api";

function BalanceInfo(props) {
  let { balance = 0, currency, isGeneral, disabledBalance, isPartner } = props;
  const bgColors = useBgColor();

  if (!currency) return null;

  console.log("balance info");

  return (
    <Box
      sx={{
        mb: 3.5,
        borderRadius: 1,
        color: "text.primary",
        p: (theme) => theme.spacing(2.75, 3.5),
        backgroundColor: bgColors.primaryLight.backgroundColor,
        height: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      {isGeneral && <CurrencyInfo currency={currency} />}

      <Box sx={{ }}>
        <Box sx={{ display: "flex" }}>
          <Typography variant="body1">{isPartner && '~~'}{balance}</Typography>
          <Typography
            component="sub"
            variant="caption"
            sx={{ alignSelf: "flex-end", mb: 1, ml: 1 }}
          >
            {currency.code}
          </Typography>
        </Box>
        {!!disabledBalance && <Box sx={{ display: "flex" }}>
          <Typography variant="body2">{isPartner && '~~'}({disabledBalance})</Typography>
          <Typography
            component="sub"
            variant="caption"
            sx={{ alignSelf: "flex-end", mb: 1, ml: 1 }}
          >
            {currency.code}
          </Typography>
        </Box>}
      </Box>
    </Box>
  );
}

function CurrencyInfo({ currency }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <img
        width="24px"
        height="24px"
        src={ConfigApi.PUBLIC_IMAGES + currency.image + ".png"}
      />
      <Typography variant="body1" sx={{ ml: 1.5 }}>
        {currency.name}
      </Typography>
    </Box>
  );
}

export default BalanceInfo;
