// ** Custom Components
import CustomChip from "src/@core/components/mui/chip";

function StatusChip(props) {
  let { status, size = "medium", style = {}, isReceive,isSend } = props;

  let statusData = getStatusData(status, isReceive, isSend);

  return (
    <CustomChip
      skin="light"
      size={size}
      label={statusData.name}
      color={statusData.color}
      sx={{
        width: "min-content",
        fontSize: "1rem",
        fontWeight: 600,
        borderRadius: "5px",
        ...style,
      }}
    />
  );
}

// "wait", "done", "cancel"

function getStatusData(status, isReceive, isSend) {
  if (isReceive) {
    switch (status) {
      case "wait":
        return {
          color: "warning",
          name: "В ожидании",
        };
      case "done":
        return {
          color: "success",
          name: "Выполненна",
        };
      case "cancel":
        return {
          color: "error",
          name: "Отмененна",
        };
      default:
        return "";
    }
  } else if (isSend) {
    switch (status) {
      case "wait":
      case "selected":
        return {
          color: "warning",
          name: "В ожидании",
        };
      case "done":
        return {
          color: "success",
          name: "Выполненна",
        };
      case "cancel":
        return {
          color: "error",
          name: "Отмененна",
        };
      default:
        return "";
    }
  }
}

export default StatusChip;
