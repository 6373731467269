import { useCountdown } from "src/hooks/countDownSeconds";

//
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// time() {

// },

function Timer(props) {
  const { finished, TypographyProps } = props;
  const [seconds] = useCountdown(finished);

  //   console.log({ seconds });
  let textTime = getTextTime(seconds);

  if (!textTime) return null;

  return <Typography {...TypographyProps}>{textTime}</Typography>;
}

function getTextTime(seconds) {
  if (seconds <= 0) return null;

  let min = Math.floor(seconds / 60);
  let sec = Math.floor(seconds % 60);

  return `${min} мин. ${sec} сек.`;
}



export default Timer;
