import { Box, Typography } from "@mui/material";
import CopyClipboardWrap from "src/components/UI/CopyClipboardWrap";

export default function FieldsRaw({ name, value, style = {} }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        maxWidth: "100%",
        ...style,
      }}
    >
      <Typography sx={{ mr: 2 }} variant="body2">
        {name}:
      </Typography>
      <Box sx={{ display: "flex", flexGrow: 1, alignItems: "center" }}>
        <Typography variant="body1" sx={{ wordBreak: "break-word" }}>
          {value}
        </Typography>
        <CopyClipboardWrap value={value} />
      </Box>
    </Box>
  );
}
