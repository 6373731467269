import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Grid,
  Button,
  TextField,
  Collapse,
  CircularProgress,
  CardActionArea,
  Link as MuiLink,
} from "@mui/material";

import OrderPartnerStatusChip from "./StatusChip";

import socket from "src/socket";

import { toast } from "react-hot-toast";

import MerchantService from "src/service/Merchant";
import OrderService from "src/service/Order";

import Timer from "src/modules/Orders/Timer";
import { Icon } from "@iconify/react";

import CONFIG from "src/configs/api";
import ViewImage from "src/components/ViewImage";

import AuctionReceipt from "./AuctionReceipt";

function AuctionOrder({ data, auctionId, deleteData }) {
  let [order, setOrder] = useState(data);

  useEffect(() => {
    socket.on("merchant_order-send_upd", (upd) => {
      setOrder((order) => {
        return order._id == upd._id ? upd : order;
      });
    });
    return () => {
      socket.off("merchant_order-send_upd");
    };
  }, []);

  const deleteHandler = () => {
    if (window.confirm("Вы уверены?") == false) return;

    MerchantService.request("admin.ser_order_auction-change", {
      orderId: auctionId,
      action: "delete",
    }).then(() => {
      toast.success("Аукцион удален");
      deleteData();
    });
  };
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <MuiLink
          component={"a"}
          target="_blank"
          color="primary"
          underline="none"
          rel="noreferrer"
          variant="body1"
          href={`/merchant/clients/${order?.account?._id}`}
        >
          {order?.account?.email}
        </MuiLink>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <OrderPartnerStatusChip action="send" status={order.status} />
          {order.status == "cancel" && (
            <IconButton
              title="Удалить"
              onClick={deleteHandler}
              color="error"
              sx={{ ml: 2 }}
            >
              <Icon icon="ic:round-delete" />
            </IconButton>
          )}
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
        <Typography variant="body1">{order.sum} RUB</Typography>
        <Typography variant="body2">
          Прибыль партнера: {order.profitSum} RUB ({order.profitPercent}%)
        </Typography>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
        <Box>
          {order.comment && (
            <Typography variant="body2">{order.comment}</Typography>
          )}
        </Box>
        <Box>
          {order.status == "wait" && <Timer finished={order.expired} />}
        </Box>
      </Box>

      {order.status == "done" && order.requisite && (
        <OrderInfoRequisite data={order} />
      )}
      {order.status == "done" && order.receipt && (
        <AuctionReceipt value={CONFIG.MERCHANT_IMAGES + order.receipt} />
      )}
      <OrderInfoActions order={order} orderId={order.orderId} />
    </Box>
  );
}

function OrderInfoRequisite({ data }) {
  let [show, setShow] = useState(false);

  let fields = data?.requisite?.fields;

  if (!fields) return;

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="body1">Реквизиты партнера:</Typography>
        <IconButton onClick={() => setShow(!show)}>
          <Icon icon="material-symbols:expand-more-rounded" />
        </IconButton>
      </Box>
      <Collapse in={show} timeout="auto" unmountOnExit>
        {fields.map((f) => {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                maxWidth: "100%",
              }}
            >
              <Typography sx={{ mr: 2 }} variant="body2">
                {f.name}:
              </Typography>
              <Typography variant="body1" sx={{ wordBreak: "break-word" }}>
                {f.value}
              </Typography>
            </Box>
          );
        })}
      </Collapse>
    </Box>
  );
}

// function OrderReceipt({ receipt }) {
//   let [show, setShow] = useState(false);
//   let [showFullscreen, setShowFullscreen] = useState(false);

//   const url = CONFIG.MERCHANT_IMAGES + receipt;

//   return (
//     <Box>
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "space-between",
//           alignItems: "center",
//         }}
//       >
//         <Typography variant="body1">Фото чека:</Typography>
//         <IconButton onClick={() => setShow(!show)}>
//           <Icon icon="material-symbols:expand-more-rounded" />
//         </IconButton>
//       </Box>
//       <Collapse in={show} timeout="auto" unmountOnExit>
//         <Box>
//           <ViewImage
//             show={showFullscreen}
//             handleClose={() => setShowFullscreen(false)}
//             value={url}
//           />
//           <CardActionArea
//             sx={{ mt: 2 }}
//             onClick={() => setShowFullscreen(true)}
//           >
//             <img
//               alt="Фото чека"
//               src={url}
//               style={{
//                 width: "100%",
//                 borderRadius: 4,
//               }}
//             />
//           </CardActionArea>
//         </Box>
//       </Collapse>
//     </Box>
//   );
// }

function OrderInfoActions({ order }) {
  let [submit, setSubmit] = useState(false);
  let [isCancelView, setCancelView] = useState(false);
  let [comment, setComment] = useState("");

  let orderStatus = order.status;
  let view = null;

  const changeStatusHandler = (newStatus, comment = "") => {
    setSubmit(newStatus);
    MerchantService.request("admin.ser_order_send-change", {
      newStatus,
      id: order._id,
      comment,
    })
      .then(() => {
        toast.success("Статус изменен");
        setCancelView(false);
      })
      .finally(() => {
        setSubmit();
      });
  };

  const recoverHandler = () => {
    if (!window.confirm("Вы уверены?")) return;
    changeStatusHandler(
      "wait",
      "Администратор восстановил заявку на перевод средств."
    );
  };
  const doneHandler = () => {
    if (!window.confirm("Вы уверены?")) return;
    changeStatusHandler("done", "Администратор подтвердил перевод средств.");
  };

  const cancelHandler = () => {
    if (!window.confirm("Вы уверены?")) return;
    changeStatusHandler(
      "cancel",
      comment || "Администратор отменил перевод средств."
    );
  };

  if (orderStatus == "wait" || orderStatus == "selected") {
    view = (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Button
            disabled={submit}
            startIcon={submit == "done" ? <CircularProgress /> : null}
            fullWidth
            onClick={doneHandler}
            variant="contained"
          >
            Перевел
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            onClick={() => setCancelView(true)}
            fullWidth
            color="error"
            variant="outlined"
          >
            Отклонить
          </Button>
        </Grid>
      </Grid>
    );
  } else if (orderStatus == "done") {
    view = (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Button
            onClick={() => setCancelView(true)}
            fullWidth
            color="error"
            variant="outlined"
          >
            Отклонить
          </Button>
        </Grid>
      </Grid>
    );
  } else if (orderStatus == "cancel") {
    view = (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Button
            onClick={recoverHandler}
            disabled={submit}
            fullWidth
            startIcon={submit == "wait" ? <CircularProgress /> : null}
            variant="contained"
          >
            Восстановить
          </Button>
        </Grid>
      </Grid>
    );
  }

  if (isCancelView) {
    view = (
      <Grid sx={{ mt: 2 }} container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Причина отмены"
            variant="outlined"
            size="small"
            multiline
            rows={2}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            onClick={cancelHandler}
            disabled={submit}
            startIcon={submit == "cancel" ? <CircularProgress /> : null}
            fullWidth
            color="error"
            variant="contained"
          >
            Отклонить
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            onClick={() => setCancelView(false)}
            fullWidth
            color="error"
            variant="outlined"
          >
            Отменить
          </Button>
        </Grid>
      </Grid>
    );
  }

  return <Box sx={{ mt: 2 }}>{view}</Box>;
}

export default AuctionOrder;
