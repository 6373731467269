import { useState, useEffect } from "react";

import {
  Box,
  Grid,
  Button,
  CircularProgress,
  Typography,
  Card,
  CardContent,
  IconButton,
  TextField,
} from "@mui/material";

// Api
import WalletService from "src/service/Wallet";
import MerchantService from "src/service/Merchant";

// Modules
import WalletSelect from "src/modules/Wallets/WalletSelect";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Notif
import { toast } from "react-hot-toast";

function SendSelectRequisite({ closeHandler, sum, depositId }) {
  // State
  let [kits, setKits] = useState([]);
  let [loaded, setLoaded] = useState(false);
  const [selectetWallets, setSelectedWallets] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);

  // Hooks
  useEffect(() => {
    WalletService.getOutcomeKitsList({ merchantCode: "usdtrc20" }).then(
      ({ data }) => {
        setKits(data);
        setLoaded(true);
      }
    );
  }, []);

  // APi
  // Api handlers
  const confirmSendPaymentHandler = () => {
    setSubmitting(true);
    MerchantService.request("admin.ser_deposit_change", {
      _id: depositId,
      list: selectetWallets,
      action: "done",
    })
      .then((res) => {
        toast.success("Заявка изменна");
        closeHandler();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  let hasKits = kits.length > 0;

  if (!loaded) return <LoaderComponent />;

  return (
    <Box>
      {hasKits ? (
        <SelectKits
          selectetWallets={selectetWallets}
          setSelectedWallets={setSelectedWallets}
          isSubmitting={isSubmitting}
          setSubmitting={setSubmitting}
          kits={kits}
          sum={sum}
        />
      ) : (
        notKitsTitle
      )}

      <Buttons
        isSubmitting={isSubmitting}
        confirmSendPaymentHandler={confirmSendPaymentHandler}
        closeHandler={closeHandler}
        hasWallets={selectetWallets.length}
      />
    </Box>
  );
}

function SelectKits(props) {
  let { kits, selectetWallets, setSelectedWallets, sum } = props;

  // Handlers
  const handlerAddWallet = (added) => {
    let exist = selectetWallets.find((i) => i._id == added._id);
    if (exist) return;
    let newWallet = {
      _id: added._id,
      name: added.name,
      sum: 0,
      cms: 0,
    };
    setSelectedWallets((wallets) => [newWallet, ...wallets]);
  };

  const handlerDeleteWallet = (id) => {
    setSelectedWallets((wallets) => wallets.filter((w) => w._id !== id));
  };
  const handlerChangeWallet = (upd) => {
    setSelectedWallets((wallets) =>
      wallets.map((w) => (upd._id == w._id ? upd : w))
    );
  };

  return (
    <Box>
      <Box sx={{ p: 3, pb: 1 }}>
        <Typography sx={{ mb: 3 }} variant="body1">
          Выберите комплекты
        </Typography>
      </Box>

      <WalletSelect
        isOutcome
        isCryptoOutcome
        kits={kits}
        code="USDT"
        setHandler={handlerAddWallet}
      />

      <Box sx={{ p: 3, pt: 1 }}>
        <Typography sx={{ mb: 3 }} variant="body1">
          Выбранные комплекты
        </Typography>

        {selectetWallets.map((w) => {
          return (
            <SelectedItem
              deleteHandler={handlerDeleteWallet}
              changeHandler={handlerChangeWallet}
              key={w._id}
              sum={sum}
              data={w}
            />
          );
        })}
      </Box>
    </Box>
  );
}

const notKitsTitle = (
  <Typography variant="body1" textAlign="center">
    Нет комплектов для отдачи
  </Typography>
);

function LoaderComponent() {
  return (
    <Box
      sx={{
        mt: 5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
}

function SelectedItem(props) {
  let { data, deleteHandler, changeHandler, sum } = props;
  let name = data.name;
  return (
    <Card sx={{ mb: 2.5 }}>
      <CardContent
        sx={{
          p: 2,
          pb: "12px !important",
        }}
      >
        <Typography
          sx={{ mb: 2 }}
          variant="body2"
        >{`Комплект: ${name}`}</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            onClick={() =>
              changeHandler(
                Object.assign({}, data, {
                  _id: data._id,
                  sum: sum,
                })
              )
            }
            color="primary"
            sx={{ borderRadius: 1, ml: 2 }}
          >
            <Icon icon="mdi:tray-full" />
          </IconButton>
          <Box sx={{ flexGrow: 1, ml: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  value={data.sum}
                  onChange={(e) =>
                    changeHandler(
                      Object.assign({}, data, {
                        _id: data._id,
                        sum: e.target.value,
                      })
                    )
                  }
                  size="small"
                  label="Сумма"
                  type="number"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={data.cms}
                  onChange={(e) =>
                    changeHandler(
                      Object.assign({}, data, {
                        _id: data._id,
                        cms: e.target.value,
                      })
                    )
                  }
                  size="small"
                  label="Комиссия"
                  type="number"
                />
              </Grid>
            </Grid>
          </Box>
          <IconButton
            onClick={() => deleteHandler(data._id)}
            color="error"
            sx={{ borderRadius: 1, ml: 2 }}
          >
            <Icon icon="ic:round-delete" />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
}

function Buttons({
  closeHandler,
  hasWallets,
  confirmSendPaymentHandler,
  isSubmitting,
}) {
  return (
    <Grid sx={{ mt: 3 }} justifyContent="space-between" container spacing={4}>
      <Grid item xs={6}>
        {!!hasWallets && (
          <Button
            fullWidth
            sx={{ fontSize: "0.8rem" }}
            variant="contained"
            color="primary"
            onClick={confirmSendPaymentHandler}
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress /> : null}
          >
            Подтвердить отправление
          </Button>
        )}
      </Grid>
      <Grid item xs={6}>
        <Button
          fullWidth
          sx={{ fontSize: "0.8rem" }}
          variant="outlined"
          color="error"
          onClick={closeHandler}
        >
          Отменить
        </Button>
      </Grid>
    </Grid>
  );
}

export default SendSelectRequisite;
