function getFullBlockchainTransactionUrl(siteUrl, address) {
  let hasAll = siteUrl && address;

  if (!hasAll) return;

  let replaceText = "$address$";

  let hasReplacement = siteUrl.includes(replaceText);

  if (hasReplacement) {
    let url = siteUrl;
    url = url.replace(replaceText, address);
    return url;
  } else {
    return siteUrl + address;
  }
}

export { getFullBlockchainTransactionUrl };
