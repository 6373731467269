import axios from "axios";

class OrderAcquiring {
  getAll() {
    return axios.get("/partners/acquiring");
  }
  viewedChange(id) {
    return axios.put("/partners/acquiring", {
      id,
    });
  }
}

export default new OrderAcquiring();
