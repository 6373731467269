import { useState, useEffect } from "react";
// Mui
import { CardContent } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import ListItemButton from "@mui/material/ListItemButton";
import Link from "@mui/material/Link";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import Checkbox from "@mui/material/Checkbox";

// Store
import { observer } from "mobx-react-lite";

// Socket
import { socket } from "src/socket";

// Custom componenents
import CustomChip from "src/@core/components/mui/chip";
import CustomAvatar from "src/@core/components/mui/avatar";

// Toast
import { toast } from "react-hot-toast";

// Congi
import ConfigApi from "src/configs/api";

// ** Utils
import getPriceCurrentFiat from "src/helpers/getPriceCurrentFiat";
import siteCurrencySearchFiltering from "src/helpers/siteCurrencySearchFiltering";

// Time
import moment from "moment";

// Configs
import WEBSITES from "src/configs/websites";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Services
import SiteCurrencyService from "src/service/SiteCurrency";

// Modules
import DialogAddSiteCurrency from "./DialogAddSiteCurrency";

function SiteCurrencies() {
  // State
  const [currentTab, setCurrentTab] = useState(WEBSITES[0].id);

  return (
    <Card>
      <CardHeader title={"Валюты сайтов"} />
      <CardContent>
        <TabContext value={currentTab}>
          <TabList
            onChange={(_, v) => setCurrentTab(v)}
            variant="scrollable"
            sx={{
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            {WEBSITES.map((s) => (
              <Tab value={s.id} label={s.name} />
            ))}
          </TabList>
          {WEBSITES.map((s) => {
            return (
              <TabPanel value={s.id}>
                <SiteTabPanel
                  site={s.id}
                  //   currencies={e.currencies}
                  //   code={e.code}
                  //   setAddCurrencyShow={openAddCurrencyShow}
                />
              </TabPanel>
            );
          })}
        </TabContext>
      </CardContent>
    </Card>
  );
}

function SiteTabPanel(props) {
  let { site } = props;
  // State
  let [search, setSearch] = useState("");
  let [currencies, setCurrencies] = useState([]);
  let [showAddCurrency, setShowAddCurrency] = useState(false);

  // Hooks
  useEffect(() => {
    SiteCurrencyService.getSiteCurrencyList({ sc: site }).then((r) =>
      setCurrencies(r.data)
    );
    socket.on("site_currency_add", (c) =>
      setCurrencies((list) => [c, ...list])
    );
    return () => {
      socket.off("site_currency_add");
    };
  }, [site]);

  // Calc
  let filteredCurrencies = siteCurrencySearchFiltering(currencies, search);

  return (
    <Box>
      <DialogAddSiteCurrency
        show={showAddCurrency}
        closeHandler={() => setShowAddCurrency(false)}
        site={site}
      />
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <TextField
            size="small"
            fullWidth
            value={search}
            placeholder="Поиск"
            onChange={(e) => setSearch(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            startIcon={<Icon icon="mdi:plus" />}
            onClick={() => setShowAddCurrency(true)}
            variant="contained"
          >
            Добавить
          </Button>
        </Grid>
      </Grid>
      <List
        dense
        sx={{
          maxHeight: "50vh",
          overflowY: "auto",
        }}
      >
        {filteredCurrencies.map((c) => (
          <SiteCurrencyCard key={c._id} data={c} />
        ))}
      </List>
    </Box>
  );
}

const SiteCurrencyCard = observer(function (props) {
  let { data } = props;
  let [currency, setCurrency] = useState(data);

  // Hooks
  useEffect(() => {
    socket.on(`site_currency_upd_${currency._id}`, (d) => {
      setCurrency(d);
    });
    return () => {
      socket.off(`site_currency_upd_${currency._id}`);
    };
  }, []);

  // calc
  let main = currency.cur;

  // API
  const changeCurrencyEnblHandler = (enbl) => {
    let fetchObj = {
      _id: currency._id,
      enbl,
    };
    SiteCurrencyService.changeSiteCurrency(fetchObj).then((r) =>
      toast.success(`Валюта ${enbl ? "включена" : "отключена"}!`)
    );
  };

  return (
    <ListItem>
      <ListItemAvatar>
        <CustomAvatar
          skin="light"
          variant="rounded"
          sx={{ width: 46, height: 46 }}
        >
          <Avatar
            src={ConfigApi.PUBLIC_IMAGES + main.image+ '.png'}
            alt={main.name}
            sx={{ height: 36, width: 36 }}
          />
        </CustomAvatar>
      </ListItemAvatar>
      <ListItemText primary={main.name} secondary={main.code} />
      <ListItemSecondaryAction sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <CustomChip
            skin="light"
            size="small"
            color="primary"
            label={getPriceCurrentFiat(currency.val)}
            sx={{ height: 20, fontSize: "0.75rem", fontWeight: 500, mb: 1 }}
          />
          <Typography variant="caption">
            {moment(currency.updatedAt).format("DD.MM.YYYY, HH:mm:ss")}
          </Typography>
        </Box>
        <Checkbox
          sx={{ ml: 2 }}
          edge="end"
          tabIndex={-1}
          disableRipple
          onChange={(v) => changeCurrencyEnblHandler(v.target.checked)}
          checked={currency.enbl}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
});

export default SiteCurrencies;
