import { useEffect, useState } from "react";
// Modules
import PageWrap from "src/components/PageWrap";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import Box from "@mui/material/Box";

import { Typography } from "@mui/material";

import moment from "moment";

// ** Icon
import Icon from "src/@core/components/icon";

// Services
import HistoryService from "src/service/History";

// Modules
import Action from "./Modules/Action";
import ChangeBalance from "./Modules/ChangeBalance";
import CurrencySum from "./Modules/CurrencySum";

const columns = [
  { id: "order", label: "Комментарий", minWidth: 50 },
  {
    id: "action",
    label: "Действие",
  },
  {
    id: "currencyName",
    label: "Валюта и сумма",
  },
  {
    id: "kit",
    label: "Комплект",
  },
  {
    id: "requisite",
    label: "Реквизит",
  },
  {
    id: "author",
    label: "Автор",
  },
  {
    id: "createdAt",
    label: "Дата",
  },
];

function Operations() {
  const [page, setPage] = useState(0);
  const [rowsCount, setRowsCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);

  // Hooks
  useEffect(() => {
    HistoryService.operationsGet({
      page,
      rowsPerPage: rowsPerPage,
    }).then((r) => {
      let data = r.data;
      setRowsCount(data.count);
      setRows(data.rows);
    });
  }, [rowsPerPage, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Card>
      <CardHeader title="Операции со счетами" />
      <TableContainer dense component={Paper} sx={{ maxHeight: "70vh" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              let hasCurrencyInfo =
                row.currencyName && row.сurrencyCode && row.sum;

                console.log(hasCurrencyInfo, 'hasCurrencyInfo')

              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                  <TableCell>
                    <Typography variant="body1">{row.comment}</Typography>
                  </TableCell>
                  <TableCell>
                    <Action history="operations" action={row.action} />
                  </TableCell>
                  <TableCell>
                    {hasCurrencyInfo ? (
                      <CurrencySum
                        name={row.currencyName}
                        code={row.сurrencyCode}
                        sum={row.sum}
                      />
                    ) : (
                      <Typography
                        sx={{ fontWeight: 600, fontSize: "14px" }}
                        variant="body1"
                      >
                        -
                      </Typography>
                    )}
                  </TableCell>

                  <TableCell>
                    <ChangeBalance data={row.kit} />
                  </TableCell>
                  <TableCell>
                    {row.requisite && <ChangeBalance data={row.requisite} />}
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{row.author}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="secondary">
                      {moment(row.createdAt).format("DD.MM.YYYY, в HH:mm:ss")}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rowsCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}

export default Operations;
