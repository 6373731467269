import { useEffect, useState } from "react";
// Mui
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  CircularProgress,
} from "@mui/material";

// Services
import TargetPairService from "src/service/TargetPair";

// Components
import SimpleSelectCurrency from "src/components/Currency/SimpleSelectCurrency";
import toast from "react-hot-toast";

function ModalCreatePair({ show, handleClose, addItemPair }) {
  let [possibleExchanges, setPossibleExchanges] = useState([]);
  let [takeId, setTakeId] = useState("");
  let [giveId, setGiveId] = useState("");
  let [isSubmiting, setIsSubmiting] = useState(false);

  // Hooks
  useEffect(() => {
    TargetPairService.getPossibleExchanges().then((res) => {
      let list = res.data;
      setPossibleExchanges(list);
      setTakeId(list[0]._id);
      setGiveId(list[0].give[0]._id);
    });
  }, []);

  useEffect(() => {
    if (!takeId) return;
    let gives = possibleExchanges.find((e) => e._id == takeId)?.give;
    setGiveId(gives[0]._id);
  }, [takeId]);

  //
  if (!possibleExchanges.length) return null;

  let takes = possibleExchanges.map((t) =>
    Object.assign({}, t, { give: undefined })
  );
  let gives = possibleExchanges.find((e) => e._id == takeId)?.give || [];

  // Handlers
  const closeHandler = () => {
    handleClose();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsSubmiting(true);
    TargetPairService.addPairTarget({
      take: takeId,
      give: giveId,
    })
      .then((r) => {
        toast.success("Таргетируемая пара создана");
        closeHandler();
        addItemPair(r.data);
      })
      .finally(() => {
        setIsSubmiting(false);
      });
  };

  return (
    <Dialog maxWidth="xs" open={show} onClose={closeHandler}>
      <DialogTitle textAlign="center">Добавить таргет</DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          <SimpleSelectCurrency
            label="Отдаю"
            containerStyle={{ mt: 2 }}
            currencies={takes}
            value={takeId}
            onChange={(v) => setTakeId(v)}
          />
          <SimpleSelectCurrency
            label="Получаю"
            containerStyle={{ mt: 4 }}
            currencies={gives}
            value={giveId}
            onChange={(v) => setGiveId(v)}
          />

          <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
            {takeId && giveId ? (
              <Button
                startIcon={
                  isSubmiting ? <CircularProgress size="1rem" /> : null
                }
                disabled={isSubmiting}
                size="large"
                type="submit"
                variant="contained"
                sx={{ mr: 2 }}
                onClick={onSubmit}
              >
                Добавить
              </Button>
            ) : null}
            <Button
              sx={{ mr: 2 }}
              type="reset"
              size="large"
              variant="outlined"
              color="secondary"
              onClick={handleClose}
            >
              Отменить
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default ModalCreatePair;
