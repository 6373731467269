import { useState, useEffect } from "react";
import {
  Box,
  CardContent,
  Button,
  Typography,
  CircularProgress,
  Grid,
} from "@mui/material";

import OrderService from "src/service/Order";
import MerchantService from "src/service/Merchant";

import { socket } from "src/socket";
import { toast } from "react-hot-toast";

// Modules
import AuctionCard from "./modules/AuctionCard";

function SendingAuction({ order }) {
  let [data, setData] = useState(null);
  let [loading, setLoading] = useState(true);
  let [isSubmitCreate, setIsSubmitCreate] = useState(false);
  let [isSubmitFinish, setIsSubmitFinish] = useState(false);

  let isDone = !!(data?.order?.status == "done");

  // Api handlers
  const confirmSendPaymentHandler = () => {
    setIsSubmitFinish(true);
    OrderService.confirmSend(order._id, {
      isMerchant: true,
    })
      .then(() => {
        toast.success("Отправка средств клиенту подтверждена!");
      })
      .finally(() => {
        setIsSubmitFinish(false);
      });
  };

  useEffect(() => {
    MerchantService.request("admin.ser_order_auction-get-by-order-id", {
      id: order._id,
    }).then(({ data }) => {
      if (data) {
        setData(data);
      }
      setLoading(false);
    });

    socket.on(`merchant_auction_upd`, (upd) => {
      setData((prev) => (prev?._id === upd._id ? upd : prev));
    });

    return () => {
      socket.off(`merchant_auction_upd`);
    };
  }, []);

  // Handlers
  const deleteData = () => {
    setData(null);
  };

  const onSubmitHandler = () => {
    setIsSubmitCreate(true);
    MerchantService.request("admin.ser_order_auction-create", {
      orderId: order._id,
    })
      .then(({ data }) => {
        setData(data);
        toast.success("Аукцион создан");
      })
      .catch((e) => {
        // toast.error("Ошибка создания аукциона");
      })
      .finally(() => {
        setIsSubmitCreate(false);
      });
  };

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );

  const createAuctionBtn = (
    <Button
      startIcon={isSubmitCreate ? <CircularProgress /> : null}
      fullWidth
      size="small"
      onClick={onSubmitHandler}
      sx={{ mt: 2 }}
      variant="contained"
    >
      Начать аукцион
    </Button>
  );

  return (
    <Box>
      <CardContent sx={{ py: 1, px: 3 }}>
        <Typography variant="body1">Аукцион перевода:</Typography>
        {!data ? (
          createAuctionBtn
        ) : (
          <AuctionCard
            deleteData={deleteData}
            orderId={order._id}
            data={data}
            style={{ mt: 2 }}
          />
        )}
        <Grid sx={{ mt: 2 }} container spacing={4}>
          {isDone && (
            <Grid item xs={6}>
              <Button
                fullWidth
                startIcon={isSubmitFinish ? <CircularProgress /> : null}
                sx={{ fontSize: "0.8rem" }}
                variant="contained"
                color="primary"
                onClick={confirmSendPaymentHandler}
              >
                Завершить заявку
              </Button>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Box>
  );
}

export default SendingAuction;
