import { useState, useEffect } from "react";
// Mui
import {
  Button,
  Dialog,
  DialogActions,
  Typography,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  TableContainer,
  Table,
  TableHead,
  Paper,
  TableRow,
  TableCell,
  CircularProgress,
  TablePagination,
  TableBody,
} from "@mui/material";

// Router
import { useNavigate } from "react-router-dom";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Time
import moment from "moment";

// Services
import HistoryService from "src/service/History";

function SingleAccountDialog(props) {
  let { data, open, closeHandler } = props;

  // States
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [page, setPage] = useState(0);
  const [rowsCount, setRowsCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);

  // Handlers
  const handleClose = () => {
    closeHandler();
    setName("");
    setPage(0);
    setRowsCount(1);
    setRowsPerPage(10);
    setRows([]);
    setLoading(true);
  };

  // Calc
  let isKit = data?.mode == "kit";
  //   let name;

  useEffect(() => {
    if (!open) return;
    HistoryService.singleGet({
      query: data,
      page,
      rowsPerPage,
    })
      .then((r) => {
        let data = r.data;
        setRowsCount(data.count);
        setRows(data.rows);
        setName(data.name);
      })
      .finally(() => setLoading(false));
  }, [open, rowsPerPage, page]);

  // Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box>
      <Dialog
        fullScreen
        onClose={handleClose}
        aria-labelledby="full-screen-dialog-title"
        open={open}
      >
        <DialogTitle id="full-screen-dialog-title">
          <Typography variant="h6" component="span">
            {`История ${isKit ? "комплекта" : "реквизита"}: ${name || "-"}`}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ top: 8, right: 10, position: "absolute", color: "grey.500" }}
          >
            <Icon icon="mdi:close" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: 5,
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <HistoryTable
              rows={rows}
              rowsCount={rowsCount}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}

function HistoryTable({
  rows,
  rowsCount,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
}) {
  return (
    <>
      <TableContainer dense component={Paper} sx={{ maxHeight: "80vh" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Действие </TableCell>
              <TableCell>Сумма </TableCell>
              <TableCell>Было </TableCell>
              <TableCell>Стало </TableCell>
              <TableCell>Инфо </TableCell>
              <TableCell>Автор </TableCell>
              <TableCell>Дата </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((r) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={r.createdAt}>
                  <TableCell>{getActionName(r.action)}</TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        color: (t) =>
                          t.palette[r.sum >= 0 ? "success" : "error"].light,
                      }}
                      variant="body1"
                    >
                      {`${r.sum >= 0 ? "+" : ""}` + formatPrice(r.sum)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        fontWeight: 600,
                      }}
                      variant="subtitle1"
                      color="main"
                    >
                      {`${formatPrice(r.was)}`}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        fontWeight: 600,
                      }}
                      variant="subtitle1"
                      color="main"
                    >
                      {`${formatPrice(r.became)}`}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{r.info}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{r.author}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="secondary">
                      {moment(r.createdAt).format("DD.MM.YYYY, в HH:mm:ss")}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rowsCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

export default SingleAccountDialog;

function formatPrice(price) {
  return new Intl.NumberFormat("ru-RU").format(price);
}

function getActionName(name) {
  switch (name) {
    case "operation":
      return "Операция";
    case "order":
      return "Заявка";
    case "transfer":
      return "Внут. перевод";
    default:
      return "-";
  }
}
