import { useState } from "react";

import { Box, Typography, IconButton, Divider } from "@mui/material";

import { Icon } from "@iconify/react";

export default function Fields({ fields = [], name = "" }) {
  if (!fields) return;

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb:2
        }}
      >
        <Typography variant="body1">{name}:</Typography>
      </Box>
      {fields.map((f) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              maxWidth: "100%",
            }}
          >
            <Typography sx={{ mr: 2 }} variant="body2">
              {f.name}:
            </Typography>
            <Typography variant="body1" sx={{ wordBreak: "break-word" }}>
              {f.value}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
}
