import { Box, Card, CardContent, Typography, Divider } from "@mui/material";

import Timer from "src/modules/Orders/Timer";

import Fields from "./Fields";

import AuctionReceipt from "src/modules/Merchant/SendingAuction/modules/AuctionReceipt";

import CONFIG from "src/configs/api";
import Actions from "src/modules/Merchant/OrderSend/Actions";

export default function SendContent({ data }) {
  return (
    <Card sx={{ bgcolor: (t) => t.palette.background.default }}>
      <CardContent
        sx={{
          p: "12px 16px !important",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
          <Typography variant="body1">{data.sum} RUB</Typography>
          <Typography variant="body2">
            Прибыль партнера: {data.profitSum} RUB ({data.profitPercent}%)
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
          <Box>
            {data.comment &&
              (data.status == "done" || data.status == "cancel") && (
                <Typography variant="body2">{data.comment}</Typography>
              )}
          </Box>
          <Box>
            {data.status == "wait" && <Timer finished={data.expired} />}
          </Box>
        </Box>
        {data.status == "done" && data.receipt && (
          <AuctionReceipt value={CONFIG.MERCHANT_IMAGES + data.receipt} />
        )}
        <Divider />
        <Fields fields={data.fields} name="Реквизиты получателя" />
        {!!data?.requisite && (
          <>
            <Divider />
            <Fields
              fields={data?.requisite?.fields}
              name="Реквизиты мерчанта"
            />
          </>
        )}
      
        <Actions style={{mt: 2}} order={data} orderId={data.orderId} />
      </CardContent>
    </Card>
  );
}
