// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import LinearProgress from "@mui/material/LinearProgress";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// CONSTANTS
import WEBSITES from "src/configs/websites";

// ** Custom Components Imports
import CustomAvatar from "src/@core/components/mui/avatar";

// let sites = CONSTANTS.

// const data = [
//   {
//     progress: 62,
//     title: "Ex-bank",
//     color: randomColor(),
//     amount: "30",
//   },
//   {
//     progress: 25,
//     color: randomColor(),
//     title: "Crypik",
//     amount: "10",
//   },
//   {
//     progress: 13,
//     title: "Menyatt ",
//     color: randomColor(),
//     amount: "8",
//   },
// ];

function StatOrderCount({ countData = {} }) {
  let globalCount = 0;

  let formattedList = [];

  WEBSITES.forEach((s) => {
    globalCount += countData[s.id];
  });

  WEBSITES.forEach((s) => {
    formattedList.push({
      title: s.name,
      amount: countData[s.id],
      color: s.color,
      progress: Math.floor((100 * countData[s.id]) / globalCount) || 0,
    });
  });

  return (
    <Card>
      <CardHeader title="Кол-во заявок за день" />
      <CardContent sx={{ pt: (theme) => `${theme.spacing(2.5)} !important` }}>
        <Box sx={{ mb: 5.75, display: "flex", alignItems: "center" }}>
          <CustomAvatar
            skin="light"
            variant="rounded"
            sx={{ mr: 4, width: 50, height: 50 }}
          >
            <Icon icon="ic:twotone-currency-exchange" fontSize="2rem" />
          </CustomAvatar>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h4">{globalCount}</Typography>
            <Typography variant="caption">Всего заявок</Typography>
          </Box>
        </Box>

        {formattedList.map((item, index) => {
          return (
            <Box
              key={item.title}
              sx={{ mb: index !== formattedList.length - 1 ? 6.5 : undefined }}
            >
              <Box
                sx={{
                  mb: 1.5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ mr: 2, fontWeight: 600 }}>
                  {item.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 600, color: "text.primary" }}
                >
                  {item.amount}
                </Typography>
              </Box>
              <LinearProgress
                sx={{
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: item.color,
                  },
                }}
                value={item.progress}
                variant="determinate"
              />
            </Box>
          );
        })}
      </CardContent>
    </Card>
  );
}

export default StatOrderCount;
