// ** React Imports
import { useState, useEffect, useCallback } from "react";

// ** MUI Imports
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";

// Components
import Filter from "./Filter";
import TableHeader from "./TableHeader";
import Table from "./Table";
import AddClient from "./AddClient";

// ** Icon
import Icon from "src/@core/components/icon";

// Services
import ClientService from "src/service/Client";

// Timer
let timerId;

const defaultFilter = {
  site: "",
  status: "",
  loyalty: "",
  verify: "",
};

const Users = () => {
  // Ui
  let [loading, setLoading] = useState(false);

  // Drawer
  const [addClientOpen, setAddClientOpen] = useState(false);
  // Filter
  let [filter, setFilter] = useState(defaultFilter);
  let [search, setSearch] = useState("");
  let [sorting, setSorting] = useState([]);
  let [showFilter, setShowFilter] = useState(false);
  // Pagination
  const [pagination, setPagination] = useState({
    pageSize: 25,
    page: 0,
    rowCount: 25,
  });
  // Data
  let [clients, setClients] = useState([]);

  // API
  function fetchClients() {
    if (loading) return;
    setLoading(true);
    let query = Object.assign({}, filter, { search });
    let sort = undefined;
    if (sorting[0]) {
      sort = {
        field: sorting[0].field,
        sort: sorting[0].sort,
      };
    }
    ClientService.getList({ query, pagination, sort })
      .then((r) => {
        let result = r.data;
        handleChangePagination("rowCount", result.rowCount);
        setClients(result.clients);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      fetchClients();
    }, 2000);
  }, [search]);

  useEffect(() => {
    if (!showFilter) {
      setFilter(defaultFilter);
      fetchClients();
    }
  }, [showFilter]);

  let { page, pageSize } = pagination;
  useEffect(() => {
    fetchClients();
  }, [page, pageSize]);

  useEffect(() => {
    fetchClients();
  }, [sorting]);

  // Hadler
  // Filter
  const handleChangeFilter = useCallback((name, value) => {
    setFilter(Object.assign({}, filter, { [name]: value }));
  });
  const handleClearFilter = useCallback((name, value) => {
    setFilter(defaultFilter);
  });
  // Pagination
  const handleChangePagination = useCallback((name, value) => {
    setPagination(Object.assign({}, pagination, { [name]: value }));
  });

  console.log(sorting, "sorting");

  // API
  const findHandler = useCallback(fetchClients);

  // Drawer
  const toggleAddClientDrawer = () => setAddClientOpen(!addClientOpen);

  return (
    <Grid item xs={12}>
      <Card>
        <CardHeader
          action={
            <IconButton onClick={() => setShowFilter(!showFilter)}>
              <Icon
                icon={
                  showFilter
                    ? "iconamoon:arrow-up-2-duotone"
                    : "iconamoon:arrow-down-2-duotone"
                }
              />
            </IconButton>
          }
          title="Поиск по фильтру"
          sx={{ pb: 4, "& .MuiCardHeader-title": { letterSpacing: ".15px" } }}
        />
        <Collapse in={showFilter}>
          <Filter
            data={filter}
            handleChange={handleChangeFilter}
            handleClear={handleClearFilter}
            handleFind={findHandler}
          />
        </Collapse>
        <Divider />
        <TableHeader
          addClientToggle={toggleAddClientDrawer}
          search={search}
          changeSearch={(v) => setSearch(v)}
        />
        <Table
          sorting={sorting}
          setSorting={setSorting}
          clients={clients}
          pagination={pagination}
          loading={loading}
          handleChangePagination={handleChangePagination}
        />
      </Card>
      <AddClient open={addClientOpen} toggle={toggleAddClientDrawer} />
    </Grid>
  );
};

export default Users;
