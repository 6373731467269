import { useState } from "react";
// Mui
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
//Custom components
import CustomAvatar from "src/@core/components/mui/avatar";
import CurrencySelect from "src/components/CurrencySelect";
import { useTheme, useMediaQuery } from "@mui/material";

// Config
import ConfigApi from "src/configs/api";
// Helpers
import siteCurrencyFiltering from "src/helpers/siteCurrencyFiltering";
import siteCurrencySearchFiltering from "src/helpers/siteCurrencySearchFiltering";
import siteCurrencyEnblFiltering from "src/helpers/siteCurrencyEnblFiltering";

function CourseSelectBase({ currencies = [], selectedId, setSelectedId }) {
  let [search, setSearch] = useState("");

  // Hooks
  const theme = useTheme();

  // calc
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  // let filteredBaseCurrencies = siteCurrencyEnblFiltering(currencies);
  let filteredBaseCurrencies = siteCurrencyFiltering(currencies);

  (function () {
    if (filteredBaseCurrencies.length) {
      if (selectedId) {
        let has = filteredBaseCurrencies.find((c) => c._id === selectedId);
        if (!has) {
          setSelectedId(filteredBaseCurrencies[0]._id);
        }
      } else {
        setSelectedId(filteredBaseCurrencies[0]._id);
      }
    }
  })();

  filteredBaseCurrencies = siteCurrencySearchFiltering(
    filteredBaseCurrencies,
    search
  );

  // let

  return (
    <>
      <TextField
        size="small"
        fullWidth
        value={search}
        placeholder="Поиск базовой валюты"
        onChange={(e) => setSearch(e.target.value)}
      />
      {isDesktop ? (
        <List
          dense
          sx={{
            maxHeight: "50vh",
            overflowY: "auto",
          }}
        >
          {filteredBaseCurrencies.map((c, i) => (
            <CurrencyCard
              key={c._id}
              name={c.cur.name}
              code={c.cur.code}
              image={c.cur.image}
              _id={c._id}
              setSelectedId={setSelectedId}
              isSelected={c._id == selectedId}
            />
          ))}
        </List>
      ) : (
        <CurrencySelect
          containerStyle={{ mt: 3 }}
          label="Валюта"
          placeholder="Выбирете валюту"
          value={selectedId}
          setValue={setSelectedId}
          currencies={filteredBaseCurrencies.map((c) => {
            return {
              _id: c._id,
              name: c.cur.name,
              image: c.cur.image,
            };
          })}
        />
      )}
    </>
  );
}

const CurrencyCard = (props) => {
  let { name, code, image, _id, setSelectedId, isSelected } = props;
  return (
    <ListItemButton selected={isSelected} onClick={() => setSelectedId(_id)}>
      <CustomAvatar
        skin="light"
        variant="rounded"
        sx={{ width: 46, height: 46, mr: 3 }}
      >
        <Avatar
          src={ConfigApi.PUBLIC_IMAGES + image + ".png"}
          alt={name}
          sx={{ height: 36, width: 36 }}
        />
      </CustomAvatar>
      <ListItemText primary={name} secondary={code} />
    </ListItemButton>
  );
};

export default CourseSelectBase;
