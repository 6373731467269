import { useState, useEffect } from "react";
// Mui
import {
  Card,
  CardContent,
  Divider,
  Box,
  IconButton,
  TextField,
  Typography,
  InputAdornment,
  Grid,
} from "@mui/material";

// Modules
import TitleCurrencyCard from "./TitleCurrencyCard";
import PairSiteCard from "./PairSiteCard";
import Exceptions from "./Exceptions";
import DialogAddPairSite from "./DialogAddPairSite";

// Services
import TargetPairService from "src/service/TargetPair";

// ** Icon
import Icon from "src/@core/components/icon";
import toast from "react-hot-toast";

// Socket
import { socket } from "src/socket";

// Draggable
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function TargetPairCard({ style = {}, data, deleteTargetPair }) {
  let [targetData, setTargetData] = useState(data);
  let [showAddPair, setShowAddPair] = useState(false);

  // Hooks
  useEffect(() => {
    socket.on(`target_pair_${data._id}`, (upd) => {
      setTargetData((t) => Object.assign({}, t, { ...upd }));
    });
    socket.on(`target_pair_sort_${data._id}`, resortHanlers);

    return () => {
      socket.off(`target_pair_${data._id}`);
      socket.off(`target_pair_sort_${data._id}`);
    };
  }, []);
  //

  const resortHanlers = (sort) => {
    setTargetData((t) => {
      let sequence = [...t.sequence];
      let result = [];
      sort.forEach((id) => {
        result.push(sequence.find((s) => s._id + "" == id));
      });
      return Object.assign({}, t, {
        sequence: result,
      });
    });
  };

  // Calc
  let sequence = targetData.sequence;
  let tkCode = targetData?.take?.code;
  let gvCode = targetData?.give?.code;

  // Handlers
  const addExceptionHandler = (item) => {
    setTargetData((t) => {
      let oldExceptions = t.exceptions;
      let newExceptions = [...oldExceptions, item];

      return Object.assign({}, t, {
        exceptions: newExceptions,
      });
    });
  };
  const deleteExceptionHandler = (id) => {
    setTargetData((t) => {
      let oldExceptions = t.exceptions;
      let newExceptions = oldExceptions.filter((e) => e._id !== id);
      return Object.assign({}, t, {
        exceptions: newExceptions,
      });
    });
  };

  const addSequenceItemHandler = (item) => {
    setTargetData((t) => {
      let oldSequence = t.sequence;
      let newSequence = [...oldSequence, item];
      return Object.assign({}, t, {
        sequence: newSequence,
      });
    });
  };

  const deleteSequenceItemHandler = (id) => {
    setTargetData((t) => {
      let oldSequence = t.sequence;
      let newSequence = oldSequence.filter((s) => s._id !== id);
      return Object.assign({}, t, {
        sequence: newSequence,
      });
    });
  };

  const saveSortSequence = (sort) => {
    TargetPairService.changeSequence(targetData._id, {
      action: "sort",
      data: sort,
    }).then((r) => {
      toast.success("Порядок пар измененн!");
    });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const list = reorder(
      [...sequence],
      result.source.index,
      result.destination.index
    );

    let ids = list.map((s) => s._id);
    saveSortSequence(ids);
  };

  const listComponent = (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="vertical">
        {(provided, snapshot) => (
          <Box
            sx={{ mt: 6 }}
            container
            spacing={4}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {sequence.map((s, i) => (
              <Draggable key={s._id} draggableId={s._id} index={i}>
                {(provided, snapshot) => (
                  <Box
                    key={s._id}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <PairSiteCard
                      style={{ mb: 2 }}
                      num={i + 1}
                      deleteItemSequence={deleteSequenceItemHandler}
                      tpId={targetData._id}
                      data={s}
                      key={s._id}
                      tkCode={tkCode}
                      gvCode={gvCode}
                    />
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );

  return (
    <Card sx={{ ...style }}>
      <DialogAddPairSite
        id={data._id}
        show={showAddPair}
        closeHandler={() => {
          setShowAddPair(false);
        }}
        addItemHandler={addSequenceItemHandler}
      />
      <HeaderCard
        deleteTargetPair={deleteTargetPair}
        setShowAddPair={setShowAddPair}
        data={targetData}
      />
      <Divider sx={{ my: "0px !important" }} />
      <Box
        sx={{
          opacity: targetData.enabled ? 1 : 0.5,
          transition: "all 0.3s",
          pl: 4,
          pr: 2,
          py: 2,
        }}
      >
        {sequence.length ? (
          listComponent
        ) : (
          <Typography textAlign="center" sx={{ py: 2 }}>
            Список таргетов пуст
          </Typography>
        )}
      </Box>

      <Divider sx={{ my: "0px !important" }} />
      <Exceptions
        id={targetData._id}
        list={targetData.exceptions}
        addItemHandler={addExceptionHandler}
        deleteItemHandler={deleteExceptionHandler}
      />
    </Card>
  );
}
function HeaderCard({ data, setShowAddPair, deleteTargetPair }) {
  // States
  let [showAddBtn, setShowAddBtn] = useState(false);
  let [step, setStep] = useState(data.step);

  // Calc
  let enabled = data.enabled;
  let originalStep = data.step;
  let isChangedStep = +step !== +originalStep;

  const addBtn = (
    <IconButton onClick={setShowAddPair} sx={{ ml: 2 }} color="primary">
      <Icon icon="material-symbols:add" />
    </IconButton>
  );

  const toggleEnabled = (enabled) => {
    TargetPairService.changePairTarget(data._id, { enabled }).then(() => {
      toast.success(`Обменная пара ${enabled ? "включена" : "выключена"}!`);
    });
  };

  const saveStep = () => {
    TargetPairService.changePairTarget(data._id, { step }).then(() => {
      toast.success(`Шаг обменной пары измененн!`);
    });
  };

  const deleteHandler = () => {
    let id = data._id;
    if (!window.confirm("Удалить обменную пару!")) return;
    TargetPairService.deletePairTarget(id).then(() => {
      deleteTargetPair(id);
      toast.success("Обменная пара удалена!");
    });
  };

  const saveStepBtn = (
    <InputAdornment position="end">
      <IconButton
        sx={{ borderRadius: 0, px: 0.8 }}
        aria-label="toggle password visibility"
        onClick={saveStep}
        color="primary"
        edge="end"
      >
        <Icon icon="mingcute:save-line" />
      </IconButton>
    </InputAdornment>
  );

  const deleteBtn = (
    <IconButton
      color="error"
      variant="contained"
      onClick={deleteHandler}
      sx={{ borderRadius: 1, ml: 3 }}
    >
      <Icon icon="mdi:trash-outline" />
    </IconButton>
  );

  return (
    <CardContent
      sx={{
        justifyContent: "space-between",
        p: 3,
      }}
      onMouseEnter={() => setShowAddBtn(true)}
      onMouseLeave={() => setShowAddBtn(false)}
    >
      <Grid container justifyContent="space-between" spacing={4}>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TitleCurrencyCard name={data.take.name} image={data.take.image} />
            <Box sx={{ mx: 3 }}>
              <Icon icon="uil:exchange" />
            </Box>
            <TitleCurrencyCard name={data.give.name} image={data.give.image} />
            {showAddBtn && addBtn}
            {showAddBtn && deleteBtn}
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              size="small"
              sx={{ mr: 4, pr: 0 }}
              fullWidth
              value={step}
              type="number"
              label="Шаг"
              InputProps={{
                endAdornment: isChangedStep ? saveStepBtn : null,
              }}
              onChange={(e) => setStep(e.target.value)}
            />

            <IconButton
              color={data.enabled ? "primary" : "secondary"}
              variant="contained"
              onClick={() => toggleEnabled(!enabled)}
              sx={{
                borderRadius: 1,
                backgroundColor: (theme) => theme.palette.background.default,
                color: enabled ? "primary.dark" : "secondary.light",
              }}
              fullWidth
            >
              <Icon
                icon={
                  data.enabled
                    ? "pepicons-pop:power-circle-filled"
                    : "pepicons-pop:power-circle-off"
                }
              />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </CardContent>
  );
}

export default TargetPairCard;
