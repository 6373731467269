import { useState } from "react";
import { Box, Pagination } from "@mui/material";
import { Document, Page } from "react-pdf";

export default function PdfViewer({ url = "", width = 400 }) {
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
        <Page
          renderAnnotationLayer={false}
          renderTextLayer={false}
          width={width}
          pageNumber={pageNumber}
        />
      </Document>

      {numPages > 1 && (
        <Pagination
          sx={{ mt: 4 }}
          count={numPages}
          page={pageNumber}
          onChange={(e, v) => setPageNumber(v)}
          shape="rounded"
          color="primary"
        />
      )}
    </Box>
  );
}
