import { useState } from "react";

// Router
import { Link } from "react-router-dom";

// MUI imports
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";

import {
  DataGrid,
  GridColumnsMenuItem,
  GridColumnMenu,
  GridColumnMenuContainer,
} from "@mui/x-data-grid";
// import {
//   DataGrid,
//   GridColumnMenuFilterItem,
//   GridColumnMenuSortItem,
//   GridColumnMenuColumnsItem,
// } from '@mui/x-data-grid';

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
// ** Custom Components Imports
import CustomChip from "src/@core/components/mui/chip";
import LoyaltyChip from "src/modules/LoyaltyChip";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Helpers
import getPriceCurrentFiat from "src/helpers/getPriceCurrentFiat";

// Configs
import WEBSITES from "src/configs/websites";
import CONSTANTS from "src/constants/index";

import { observer } from "mobx-react-lite";

// Utils
import moment from "moment";
import "moment/locale/ru";

const StyledLink = styled(Link)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "1rem",
  cursor: "pointer",
  textDecoration: "none",
  wordBreak: "break-word",
  color: theme.palette.text.secondary,
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

// ** Vars
const userVerifiedIcons = {
  verified: { icon: "ri:mail-check-line", color: "success.light" },
  unverified: { icon: "ri:mail-close-line", color: "error.light" },
};

const columns = [
  {
    flex: 0.1,
    minWidth: 150,
    field: "email",
    headerName: "Email",
    disableColumnMenu: true,
    renderCell: ({ row }) => {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& svg": {
              color: "success.light",
            },
          }}
        >
          <StyledLink
            wordBreak={true}
            component={Typography}
            sx={{ wordBreak: "break-word", mr: 1 }}
            to={`/clients/${row._id}`}
          >
            {row.email}
          </StyledLink>
          {row?.info?.online ? (
            <Icon fontSize="18px" icon="tabler:point-filled" />
          ) : null}
        </Box>
      );
    },
  },
  {
    flex: 0.04,
    minWidth: 100,
    field: "sc",
    headerName: "Сайт",
    headerAlign: "center",
    align: "center",
    disableColumnMenu: true,
    renderCell: ({ row }) => {
      return (
        <Typography variant="subtitle1" noWrap>
          {WEBSITES.find((s) => s.id === row.sc).name}
        </Typography>
      );
    },
  },
  {
    disableColumnMenu: true,
    flex: 0.05,
    minWidth: 110,
    field: "loyalty",
    headerName: "Лояльность",
    headerAlign: "center",
    align: "center",
    renderCell: ({ row }) => {
      return <LoyaltyChip loyalty={row.loyalty} />;
    },
  },
  {
    flex: 0.03,
    minWidth: 50,
    field: "verified",
    headerName: "Актив.",
    headerAlign: "center",
    align: "center",
    disableColumnMenu: true,
    renderCell: ({ row }) => {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& svg": {
              color:
                userVerifiedIcons[row.verified ? "verified" : "unverified"]
                  .color,
            },
          }}
        >
          <Icon
            icon={
              userVerifiedIcons[row.verified ? "verified" : "unverified"].icon
            }
            fontSize={20}
          />
        </Box>
      );
    },
  },
  {
    flex: 0.05,
    minWidth: 70,
    field: "orderCount",
    headerName: "Заявок",
    headerAlign: "center",
    align: "center",
    disableColumnMenu: true,
    renderCell: ({ row }) => {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="body1" color="success.light">
            {row.exchangeCounter.completed}{" "}
          </Typography>
          <Typography>/</Typography>
          <Typography variant="body1" color="error.light">
            {" "}
            {row.exchangeCounter.rejected}
          </Typography>
        </Box>
      );
    },
  },
  {
    flex: 0.05,
    minWidth: 140,
    field: "totalAmount",
    disableColumnMenu: true,
    headerName: "Оборот",
    headerAlign: "center",
    align: "center",
    renderCell: ({ row }) => {
      const Comp = observer(function ({ sum }) {
        return (
          <Typography variant="body2">{getPriceCurrentFiat(sum, 0)}</Typography>
        );
      });

      return <Comp sum={row.exchangeCounter.total} />;
    },
  },
  {
    flex: 0.05,
    minWidth: 100,
    field: "refBalance",
    disableColumnMenu: true,
    headerName: "Реф.",
    headerAlign: "center",
    align: "center",
    renderCell: ({ row }) => {
      const Comp = observer(function ({ sum, has }) {
        return (
          <Typography variant="body2">
            {has ? getPriceCurrentFiat(sum, 0) : "-"}
          </Typography>
        );
      });

      return <Comp has={row?.referral} sum={row?.referral?.balance} />;
    },
  },
  {
    flex: 0.05,
    minWidth: 100,
    field: "accBalance",
    headerName: "Кэш.",
    disableColumnMenu: true,
    headerAlign: "center",
    align: "center",
    renderCell: ({ row }) => {
      const Comp = observer(function ({ sum, has }) {
        return (
          <Typography variant="body2">
            {has ? getPriceCurrentFiat(sum, 0) : "-"}
          </Typography>
        );
      });

      return <Comp has={row?.accrual} sum={row?.accrual?.availableBalance} />;
    },
  },
  {
    flex: 0.06,
    minWidth: 150,
    field: "createdAt",
    headerName: "Регист.",
    disableColumnMenu: true,
    // disa
    headerAlign: "center",
    align: "center",
    renderCell: ({ row }) => {
      return (
        <Typography noWrap variant="overline">
          {moment(row.createdAt).format("HH:mm, DD.MM.YYYY")}
        </Typography>
      );
    },
  },
];

const Table = ({
  pagination,
  loading,
  handleChangePagination,
  clients,
  sorting,
  setSorting,
}) => {
  let list = clients.map((c) => Object.assign({}, c, { id: c._id }));

  return (
    <DataGrid
      columns={columns}
      autoHeight
      loading={loading}
      rows={list}
      sortingMode="server"
      disableSelectionOnClick
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
      pageSize={pagination.pageSize}
      onPageSizeChange={(newPageSize) =>
        handleChangePagination("pageSize", newPageSize)
      }
      pagination
      rowCount={pagination.rowCount}
      paginationMode="server"
      onPageChange={(newPage) => handleChangePagination("page", newPage)}
      sortModel={sorting}
      onSortModelChange={(newSortModel) => setSorting(newSortModel)}
    />
  );
};



export default observer(Table);
