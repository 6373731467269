import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Divider,
  Link,
  Grid,
  Typography,
} from "@mui/material";

import { Link as RouterLink } from "react-router-dom";

import { Icon } from "@iconify/react";

import useBgColor from "src/@core/hooks/useBgColor";
import CopyClipboardWrap from "src/components/UI/CopyClipboardWrap";

// Socket
import { socket } from "src/socket";
import WalletService from "src/service/Wallet";
// Modules
import AddRequisitesDialog from "./AddRequisitesDialog";
import CountTextField from "../../modules/Ui/CountTextField";
import CustomTextField from "../../modules/Ui/CustomTextField";
// Custom Ui
import ActiveSwitch from "../../modules/Ui/ActiveSwitch";
import { toast } from "react-hot-toast";
function RequisiteList({ merchantCode, kit }) {
  const [showAdd, setShowAdd] = useState(false);
  const [requisites, setRequisites] = useState([]);

  //Hooks
  useEffect(() => {
    socket.on("update_item_req", (r) => {
      setRequisites((list) =>
        list.map((req) => (req._id == r._id ? Object.assign({}, req, r) : req))
      );
    });
    WalletService.getRequisiteList(kit._id).then(({ data }) => {
      setRequisites(data);
    });

    return () => {
      socket.off(`update_item_req`);
    };
  }, []);

  const deleteHandler = (id) => {
    if (!window.confirm("Удалить комлект?")) return;

    WalletService.deleteRequisite(id).then(() => {
      setRequisites((list) => list.filter((req) => req._id != id));
      toast.success(`Комплект удален!`);
    });
  };

  console.log(kit, "kit");

  return (
    <Box sx={{ mt: 4 }}>
      <AddRequisitesDialog
        kit={kit}
        merchantCode={merchantCode}
        show={showAdd}
        addItemsHandler={(items) => {
          setRequisites((requisites) => [...items, ...requisites]);
        }}
        handleClose={() => {
          setShowAdd(false);
        }}
      />
      <Box>
        <Button
          onClick={() => setShowAdd(true)}
          startIcon={<Icon icon="material-symbols:add" />}
          variant="contained"
        >
          Добавить реквизит
        </Button>
      </Box>
      <Box sx={{ mt: 4 }}>
        {requisites.map((r) => (
          <RequisiteCard
            key={r._id}
            deleteHandler={deleteHandler}
            currency={kit?.wallet?.currency}
            style={{ mb: 3 }}
            data={r}
          />
        ))}
      </Box>
    </Box>
  );
}

function RequisiteCard({ data, style = {}, currency, deleteHandler }) {
  const [requisite, setRequisite] = useState(data);
  const [name, setName] = useState(data.name);

  useEffect(() => {
    socket.on(`update_req_${requisite._id}`, (upd) => {
      setRequisite((r) => Object.assign({}, r, upd));
    });
    return () => {
      socket.off(`update_req_${requisite._id}`);
    };
  }, []);

  const saveNameHandler = (v) => {
    WalletService.changeRequisite({
      _id: requisite._id,
      name,
    }).then(() => {
      toast.success(`Реквизит изменен!`);
    });
  };

  const changeEnabledHadnler = (enabled) => {
    WalletService.changeRequisite({
      _id: requisite._id,
      enabled,
    }).then(() => {
      toast.success(`Реквизит ${enabled ? "включен" : "выключен"}!`);
    });
  };

  // Calc
  let isEmptyBalance = requisite.balance <= 0;
  let partner = data.partner;

  return (
    <Card sx={{ ...style }}>
      <CardContent>
        <Grid container spacing={4}>
          <Grid
            sx={{ display: "flex", alignItems: "center" }}
            item
            xs={12}
            md={8}
          >
            <ActiveSwitch
              number={requisite.number}
              enabled={requisite.enabled}
              hideNumber
              onChange={changeEnabledHadnler}
              style={{ mr: 2 }}
            />

            <CustomTextField
              label="Название"
              placeholder="Введите название"
              isChanged={name.trim() !== requisite.name.trim()}
              saveHandler={saveNameHandler}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid
            sx={{ display: "flex", alignItems: "center" }}
            item
            xs={12}
            md={4}
          >
            <RequisiteBalance
              sum={requisite.balance}
              code={currency?.code || "RUB"}
              precision={currency?.precision}
            />

            <Box sx={{ height: "100%" }}>
              <Button
                variant="outlined"
                sx={{ p: 1.5, minHeight: "100%", minWidth: 38, ml: 3 }}
                color={"error"}
                onClick={() => deleteHandler(data._id)}
              >
                <Icon fontSize="18px" icon="ic:round-delete-forever" />
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ display: "flex" }}></Box>
        <Grid sx={{ mt: 1 }} container spacing={2}>
          <Grid item xs={4}>
            <FieldItem
              name="Статус"
              hideCopy
              color={partner.enabled ? "success.light" : "error.light"}
              value={partner.enabled ? "Активный" : "Неактивный"}
            />
          </Grid>
          <Grid item xs={4}>
            <FieldItem
              link={`/merchant/clients/${partner.id}`}
              name="Email"
              value={partner.email}
            />
          </Grid>
          <Grid item xs={4}>
            <FieldItem name="Название партнера" value={partner.name} />
          </Grid>
          {data.fields.map((f) => {
            return (
              <Grid item xs={4}>
                <FieldItem name={f.name} value={f.value} />
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
      <Divider sx={{ my: "0px !important" }} />
      <CardContent sx={{ px: 3, py: 1, mt: 2 }}>
        <Grid container columns={15} spacing={4}>
          <Grid item xs={7.5} md={2}>
            <CountTextField
              label="Кол-во в день"
              name=""
              disabledValue
              count={data.dayAmount}
              value={data.dayMaxAmount}
            />
          </Grid>
          <Grid item xs={7.5} md={4}>
            <CountTextField
              label="Сумма в день"
              name=""
              disabledValue
              count={data.daySum}
              value={data.dayMaxSum}
            />
          </Grid>
          <Grid item xs={7.5} md={2}>
            <CountTextField
              label="Кол-во в месяц"
              name=""
              disabledValue
              count={data.monthAmount}
              value={data.monthMaxAmount}
            />
          </Grid>

          <Grid item xs={7.5} md={4}>
            <CountTextField
              label="Сумма в месяц"
              name=""
              disabledValue
              count={data.monthSum}
              value={data.monthMaxSum}
            />
          </Grid>
          <Grid item xs={7.5} md={3}>
            <TextField
              size="small"
              name=""
              disabled
              label="Макс. баланс"
              disabledValue
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-root": {
                  paddingLeft: 0,
                },
              }}
              fullWidth
              value={data.maxBalance}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function FieldItem({ name, value, hideCopy, color, link }) {
  let valueComponent;

  if (link) {
    valueComponent = (
      <Link component={RouterLink} color="primary" to={link} variant="body1">
        {value}
      </Link>
    );
  } else {
    valueComponent = (
      <Typography color={color} variant="body1">
        {value}
      </Typography>
    );
  }

  return (
    <Box>
      <Typography variant="body2">{name}:</Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {valueComponent}
        {!hideCopy && <CopyClipboardWrap value={value} />}
      </Box>
    </Box>
  );
}
function RequisiteBalance({ sum, code }) {
  const bgColors = useBgColor();

  return (
    <Box
      sx={{
        paddingX: 1,
        paddingY: 2.2,
        width: "100%",
        height: "100%",
        borderRadius: 1,
        color: "text.primary",
        backgroundColor: bgColors.primaryLight.backgroundColor,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="body2">~~{sum}</Typography>
      <Typography component="sub" variant="body2" sx={{ ml: 1 }}>
        {code}
      </Typography>
    </Box>
  );
}

export default RequisiteList;
