import SumTitle from "src/modules/Orders/OrderDrawer/Modules/SumTitle";
import Timer from "src/modules/Orders/Timer";
import FieldsRaw from "./FieldsRaw";

// Mui
import {
  Box,
  Typography,
  CardContent,
  Divider,
  Link as MuiLink,
  Card,
} from "@mui/material";

function ReceiveContent({ data }) {
  return (
    <Card sx={{ bgcolor: (t) => t.palette.background.default }}>
      <CardContent sx={{ p: 3 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <SumTitle text={"Сумма"} sum={data.sum} code="RUB" />
        </Box>
      </CardContent>
      <Divider sx={{ my: "0px !important" }} />
      <CardContent sx={{ p: 3 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
        <Typography variant="body2">
            Прибыль партнера: {data.profitSum} RUB ({data.profitPercent}%)
          </Typography>
          <Box>
            {data.status == "wait" && <Timer finished={data.expired} />}
          </Box>
         
        </Box>
        <Box>
          {data.comment &&
            (data.status == "done" || data.status == "cancel") && (
              <Typography sx={{mb: 2}} variant="body2">
                Комментарий: {data.comment}
              </Typography>
            )}
        </Box>
        <Typography variant="body1">Откуда получает:</Typography>
        {data.senderFields.map((f) => (
          <FieldsRaw key={f._d} name={f.name} value={f.value} />
        ))}
        <Typography sx={{ mt: 2 }} variant="body1">
          Куда получает:
        </Typography>
        {data.recipientFields.map((f) => (
          <FieldsRaw key={f._d} name={f.name} value={f.value} />
        ))}
      </CardContent>
    </Card>
  );
}

export default ReceiveContent;
