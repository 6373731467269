import { useState, useEffect } from "react";
// Mui
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";

// Configs
import WEBSITES from "src/configs/websites";
// Constants
import CONSTANTS from "src/constants/index";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Service
import CurrencyService from "src/service/Currencies";

// Components
import CurrencySelect from "src/components/CurrencySelect";

// Vars
const WEBSITES_LIST = [
  ...WEBSITES.map((w) => ({
    name: w.name,
    value: w.id,
  })),
];

const STATUS_LIST_FILTER = [
  {
    name: "Все",
    value: "",
  },
  {
    name: "Новые",
    value: "new",
  },
  {
    name: "Одобренные",
    value: "resolved",
  },
  {
    name: "Отклоненные",
    value: "rejected",
  },
  {
    name: "Забаненные",
    value: "banned",
  },
];

//   case 'all':
//     sort = { archived: false };
//     break;
//   case 'new':
//     sort = { archived: false, status: 101 };
//     break;
//   case 'resolved':
//     sort = { archived: false, status: 102 };
//     break;
//   case 'rejected':
//     sort = { archived: false, status: 103 };
//     break;
//   case 'archived':
//     sort = { archived: true };
//     break;
//   case 'banned':

function CardsFilter(props) {
  let {
    site,
    setSite,
    status,
    setStatus,
    bank,
    setBank,
    openAddCardHandler,
    search,
    setSearch,
    isAdvancedFilter,
    clearAdvancedFilters,
  } = props;
  // State
  let [currencies, setCurrencies] = useState([]);

  // Hooks
  useEffect(() => {
    CurrencyService.getList({ populate: ["settings"] }).then((r) => {
      setCurrencies(r.data.filter((c) => c.settings.isBank));
    });
  }, []);

  return (
    <Box>
      {!isAdvancedFilter && (
        <>
          <TabContext value={site}>
            <TabList variant="scrollable" onChange={(e, v) => setSite(v)}>
              <Tab value="" label="Все" />
              {WEBSITES_LIST.map((w) => (
                <Tab value={w.value} label={w.name} />
              ))}
            </TabList>
          </TabContext>
          <TabContext value={status}>
            <TabList variant="scrollable" onChange={(e, v) => setStatus(v)}>
              {STATUS_LIST_FILTER.map((w) => (
                <Tab value={w.value} label={w.name} />
              ))}
              <Tab sx={{ ml: 8 }} value="archived" label="Архив" />
            </TabList>
          </TabContext>
        </>
      )}

      <Grid sx={{ mt: 1 }} container justifyContent="space-between" spacing={4}>
        <Grid item xs={6} md={3}>
          <Button
            startIcon={<Icon icon="material-symbols:add-card-outline" />}
            variant="contained"
            onClick={openAddCardHandler}
          >
            Добавить
          </Button>
        </Grid>
        <Grid item xs={0} md={3}></Grid>
        <Grid item xs={6} md={3}>
          <CurrencySelect
            label="Банк"
            placeholder="Выберите банк"
            currencies={currencies}
            value={bank}
            setValue={setBank}
            size="small"
            addEmpty
            emptyName="Все"
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              label="Поиск"
              size="small"
              fullWidth
              value={search}
              placeholder="Номер карты, имя или email"
              onChange={(e) => setSearch(e.target.value)}
            />
            {isAdvancedFilter && (
              <IconButton
                color="error"
                variant="contained"
                title="Очистить филтры"
                onClick={clearAdvancedFilters}
                sx={{
                  borderRadius: 1,
                  ml: 2,
                }}
              >
                <Icon size="24px" icon="ic:round-clear" />
              </IconButton>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CardsFilter;
