import { Box, Typography, IconButton } from "@mui/material";

import Icon from "src/@core/components/icon";

import { useCountdown } from "src/hooks/countDownSeconds";

import MerchantService from "src/service/Merchant";

import { toast } from "react-hot-toast";

function AuctionInfo({ data, orderId, deleteData }) {
  let curStatus = data.status;

  let actionBtn;

  // Handlers
  const deleteItemApi = () => {
    if (!window.confirm("Удалить аукцион?")) return;
    MerchantService.request("admin.ser_order_auction-change", {
      orderId,
      action: "delete",
    }).then(() => {
      toast.success("Аукцион удален");
      deleteData();
    });
  };

  const restoreItemApi = () => {
    if (!window.confirm("Восстановить аукцион?")) return;
    MerchantService.request("admin.ser_order_auction-change", {
      orderId,
      action: "restore",
    }).then(() => {
      toast.success("Аукцион восстановлен");
    });
  };

  if (curStatus == "expired") {
    actionBtn = (
      <Box>
        <IconButton onClick={restoreItemApi} title="Восстановить">
          <Icon icon="ic:baseline-restore" />
        </IconButton>
        <IconButton
          sx={{ ml: 1 }}
          title="Удалить"
          onClick={deleteItemApi}
          color="error"
        >
          <Icon icon="ic:round-delete" />
        </IconButton>
      </Box>
    );
  } else if (curStatus == "wait") {
    actionBtn = (
      <Box>
        <IconButton
          title="Удалить"
          onClick={deleteItemApi}
          color="error"
          sx={{ ml: 2 }}
        >
          <Icon icon="ic:round-delete" />
        </IconButton>
      </Box>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <StatusAuctionIcon status={data.status} />
          <StatusAuctionText
            order={data.order}
            status={data.status}
            expired={data.expired}
            style={{ ml: 2 }}
          />
        </Box>
        <Box>{actionBtn}</Box>
      </Box>
      <Typography variant="body2">{data.sum} RUB</Typography>
    </Box>
  );
}

function StatusAuctionText({ style = {}, status, expired }) {
  const [seconds] = useCountdown(expired);

  if (status == "wait") {
    return (
      <Typography variant="body2" sx={{ ...style }}>
        {seconds} cек.
      </Typography>
    );
  } else if (status == "expired") {
    return (
      <Typography variant="body2" sx={{ ...style }}>
        Просрочен
      </Typography>
    );
  }
}

function StatusAuctionIcon({ status }) {
  if (status == "wait") {
    return <Icon icon="svg-spinners:180-ring" />;
  } else if (status == "expired") {
    return <Icon icon="mdi:clock-alert-outline" />;
  }
}

export default AuctionInfo;
