import { useState } from "react";
import {
  Card,
  CardContent,
  Alert,
  Box,
  Divider,
  CircularProgress,
  Grid,
  Button,
  TextField,
  Typography,
} from "@mui/material";

import SumTitle from "src/modules/Orders/OrderDrawer/Modules/SumTitle";
import FieldsRaw from "./FieldsRaw";

import toast from "react-hot-toast";
// Apu
import MerchantService from "src/service/Merchant";
// Native Modules
import SendSelectRequisite from "./SendSelectRequisite";
import SendRequisiteList from "./SendRequisiteList";

function WithdrawView({ data }) {
  let code = data.code;

  let requisiteList = data?.data?.list;
  let isDone = code == "done";

  return (
    <Card sx={{ bgcolor: (t) => t.palette.background.default }}>
      <CardContent sx={{ p: 3 }}>
        {code == "payment" && (
          <Alert sx={{ p: "4px 10px", mb: 2 }} severity="info">
            Ожидание подтверждения мерчанта.
          </Alert>
        )}
        {code == "confirm-payment" && (
          <Alert sx={{ p: "4px 10px", mb: 2 }} severity="info">
            Мерчант подтвердил вывод средств.
          </Alert>
        )}

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <SumTitle text={"Сумма"} sum={data.sum} code="USDT" />
        </Box>
      </CardContent>
      <Divider sx={{ my: "0px !important" }} />
      <CardContent sx={{ p: 3 }}>
        <Typography variant="body1">Реквизиты клиента:</Typography>
        <FieldsRaw name="Адрес USDT" value={data.address} style={{ mt: 2 }} />
      </CardContent>
      <Divider />
      {requisiteList && isDone && <SendRequisiteList list={requisiteList} />}
      <Actions sum={data.sum} _id={data._id} code={data.code} />
    </Card>
  );
}

function Actions({ code, _id, sum }) {
  let [submit, setSubmit] = useState("");
  let [comment, setComment] = useState("");
  let [actionView, setActionView] = useState("");
  let view;

  // admin.ser_deposit_change

  const cancelHandler = () => {
    changeApiHandler("cancel");
  };

  const confirmHandler = () => {
    changeApiHandler("confirm");
  };

  const changeApiHandler = (action) => {
    setSubmit(action);
    MerchantService.request("admin.ser_deposit_change", {
      _id,
      action: action,
      comment: comment || "Администратор отклонил заявку",
    })
      .then(() => {
        toast.success("Заявка изменна");
        setActionView('')
      })
      .finally(() => {
        setSubmit("");
      });
  };

  const cancelView = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Комментарий"
          placeholder="Не обязательно"
          size="small"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <Button
          disabled={submit == "cancel"}
          startIcon={submit == "cancel" && <CircularProgress />}
          fullWidth
          onClick={cancelHandler}
          variant="contained"
        >
          Отклонить
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          onClick={() => setActionView("")}
          fullWidth
          color="error"
          variant="outlined"
        >
          Отменить
        </Button>
      </Grid>
    </Grid>
  );

  if (code == "confirm-payment") {
    view = (
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Button
            fullWidth
            onClick={() => {
              setActionView("select-requisite");
            }}
            variant="contained"
          >
            Выбрать реквизит
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={() => setActionView("cancel")}
            fullWidth
            color="error"
            variant="outlined"
          >
            Отклонить
          </Button>
        </Grid>
      </Grid>
    );
  } else if (code == "done") {
    view = (
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Button
            fullWidth
            disabled={submit == "cancel"}
            startIcon={submit == "cancel" && <CircularProgress />}
            onClick={cancelHandler}
            variant="outlined"
            color="error"
          >
            Возврат
          </Button>
        </Grid>
      </Grid>
    );
    // refund
  } else if (code == "cancel") {
    view = (
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Button
            fullWidth
            disabled={submit == "confirm"}
            startIcon={submit == "confirm" && <CircularProgress />}
            onClick={confirmHandler}
            variant="contained"
          >
            Восстановить
          </Button>
        </Grid>
      </Grid>
    );
  }
  if (actionView == "select-requisite") {
    view = (
      <SendSelectRequisite
        depositId={_id}
        sum={sum}
        closeHandler={() => setActionView("")}
      />
    );
  } else if (actionView == "cancel") {
    view = cancelView;
  }

  return <CardContent sx={{ py: 1 }}>{view}</CardContent>;
}

export default WithdrawView;
