// Hooks
import { useAuth } from "src/hooks/useAuth";

// Methods
import checkPermission from "./checkPermission";

function PermissionProvider({ children, name }) {
  // Hooks
  const { isAdmin, permission } = useAuth();

  if (isAdmin || !permission) return <>{children}</>;

  console.log(permission, 'permission')

  let isAllow = checkPermission(permission, name);

  if (isAllow) return <>{children}</>;

  return null;
}

export default PermissionProvider;
