import { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,
  CardActionArea,
  Box,
  Collapse,
  Divider,
} from "@mui/material";

// Compoennts
import StatusChip from "./StatusChip";
import Cur from "./Cur";


// Libs
import moment from "moment";

import { useNavigate } from "react-router-dom";
// merchantRequisiteId

function RequisiteCard({ data, style = {} }) {
  const navigate = useNavigate();

  return (
    <Card
      onClick={() => {
        navigate(`?merchantRequisiteId=${data._id}`);
      }}
      sx={{ ...style }}
    >
      <CardActionArea>
        <CardContent sx={{ p: "16px 12px !important" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Cur name={data.currency.name} image={data.currency.logo} />
            <StatusChip size="small" status={data.status} />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body1">{data.account.email}</Typography>
            <Typography textAlign="right" variant="body2">
              {moment(data.createdAt).format("HH:mm DD.MM.YYYY")}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default RequisiteCard;
