import { useState, useEffect } from "react";

import PageWrap from "src/components/PageWrap";

import MerchantService from "src/service/Merchant";
import socket from "src/socket";
import { Grid } from "@mui/material";

import OrderCard from "src/modules/Merchant/Order/OrderCard";

function MerchantOrdersPage() {
  let [orders, setOrders] = useState([]);

  useEffect(() => {
    MerchantService.request("admin.ser_order_get-all").then(({ data }) => {
      setOrders(data);
    });

    socket.on("merchant_order-send_upd", (upd) => {
      setOrders((prev) => prev.map((o) => (o._id == upd._id ? upd : o)));
    });
    socket.on("merchant_order-receive_upd", (upd) => {
      setOrders((prev) => prev.map((o) => (o._id == upd._id ? upd : o)));
    });

    socket.on("merchant_order-send_add", (doc) => {
      setOrders((prev) => [doc, ...prev]);
    });
    socket.on("merchant_order-receive_add", (doc) => {
      setOrders((prev) => [doc, ...prev]);
    });

    return () => {
      socket.off("merchant_order-send_upd");
      socket.off("merchant_order-receive_upd");
      socket.off("merchant_order-send_add");
      socket.off("merchant_order-receive_add");
    };
  }, []);

  return (
    <PageWrap title="Мерчант заявки">
      <Grid sx={{ mt: 4 }} container spacing={4}>
        {orders.map((o) => {
          return (
            <Grid key={o._id} item xs={12} sm={6} md={3}>
              <OrderCard key={o._id} data={o} />
            </Grid>
          );
        })}
      </Grid>
    </PageWrap>
  );
}

export default MerchantOrdersPage;
