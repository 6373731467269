import { useState, useEffect } from "react";
// Mui
import {
  Box,
  Card,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

// Components
import PageWrap from "src/components/PageWrap";
import CopyClipboardWrap from "src/components/UI/CopyClipboardWrap";

// Time
import moment from "moment";

// Services
import OrderAcquiringService from "src/service/OrderAcquiring";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Constatns
import SITES from "src/configs/websites";
import toast from "react-hot-toast";

function OrderAcquiring() {
  let [list, setList] = useState([]);
  let [loading, setLoading] = useState(true);
  let [showData, setShowData] = useState(null);
  let [showDailog, setShowDialog] = useState(false);

  useEffect(() => {
    OrderAcquiringService.getAll().then((r) => {
      setList(r.data);
      setLoading(false);
    });
  }, []);

  // Handlers
  const openDialogHandler = (data) => {
    setShowData(data);
    setShowDialog(true);
  };
  const closeDialogHandler = () => {
    setShowDialog(false);
  };

  const updateItemHandler = (upd) => {
    setList((list) => list.map((item) => (item._id == upd._id ? upd : item)));
  };

  let listWithId = list.map((c) => Object.assign({}, c, { id: c._id }));

  return (
    <PageWrap title="Заявки на эквайринг">
      <Modal
        updateHandler={updateItemHandler}
        open={showDailog}
        data={showData}
        handleClose={closeDialogHandler}
      />
      <Card>
        <DataGrid
          isRowSelectable={false}
          columns={columns}
          autoHeight
          loading={loading}
          rows={listWithId}
          sortable={false}
          sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
          onCellClick={(r) => {
            let item = list.find((i) => i._id == r.id);
            openDialogHandler(item);
          }}
        />
      </Card>
    </PageWrap>
  );
}

function Modal(props) {
  let { open, handleClose, data, updateHandler } = props;

  // Calc
  let name = data?.name;
  let email = data?.email;
  let url = data?.url;
  let turnover = data?.turnover;

  let siteName = SITES.find((s) => s.id == data?.sc)?.name;

  let isViewed = data?.isViewed;

  const viewedHandler = () => {
    OrderAcquiringService.viewedChange(data._id).then((r) => {
      updateHandler(r.data);
      handleClose();
      toast.success("Заявка просмотрена!");
    });
  };

  return (
    <Dialog maxWidth="xs" onClose={handleClose} open={open}>
      <DialogTitle id="simple-dialog-title">Заявка: {name} </DialogTitle>
      <DialogContent>
        <FieldItem name="Сайт" value={siteName} />
        <FieldItem name="Название" value={name} />
        <FieldItem name="Email" value={email} />
        <FieldItem name="URL" value={url} />
        <FieldItem name="Оборот" value={`${turnover} RUB`} />
      </DialogContent>
      {!isViewed && (
        <DialogActions>
          <Button onClick={viewedHandler} variant="contained" fullWidth>
            Просмотрено
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

function FieldItem({ name, value }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
      <Typography variant="body2">{name}:</Typography>&nbsp;&nbsp;
      <CopyClipboardWrap value={value}>
        <Typography variant="body1" fontWeight="500">
          {value}
        </Typography>
      </CopyClipboardWrap>
    </Box>
  );
}

const columns = [
  {
    flex: 0.05,
    minWidth: 100,
    field: "sc",
    headerName: "Cайт",
    renderCell: ({ row }) => {
      let siteName = SITES.find((s) => s.id == row.sc)?.name;
      return (
        <Typography sx={{ fontSize: 14 }} variant="subtitle1" noWrap>
          {siteName}
        </Typography>
      );
    },
  },
  {
    flex: 0.05,
    minWidth: 100,
    field: "name",
    headerName: "Название",
    renderCell: ({ row }) => {
      return (
        <Typography sx={{ fontSize: 14 }} variant="subtitle1" noWrap>
          {row.name}
        </Typography>
      );
    },
  },
  {
    flex: 0.1,
    minWidth: 120,
    field: "email",
    headerName: "Email",
    renderCell: ({ row }) => {
      return (
        <Typography sx={{ fontSize: 14 }} variant="subtitle1" noWrap>
          {row.email}
        </Typography>
      );
    },
  },
  {
    flex: 0.08,
    minWidth: 80,
    field: "url",
    headerName: "URL",
    renderCell: ({ row }) => {
      return (
        <Typography sx={{ fontSize: 14 }} variant="body1" noWrap>
          {row.url}
        </Typography>
      );
    },
  },
  {
    flex: 0.05,
    minWidth: 50,
    field: "turnover",
    headerName: "Оборот",
    renderCell: ({ row }) => {
      return (
        <Typography variant="body2" noWrap>
          {row.turnover} RUB
        </Typography>
      );
    },
  },
  {
    field: "payed",
    headerName: "Статус",
    renderCell: ({ row }) => {
      return (
        <Typography
          color={row.isViewed ? "primary" : "error"}
          textAlign="center"
          variant="body2"
          sx={{
            "& svg": {
              color: "inherit",
            },
          }}
          noWrap
        >
          <Icon
            icon={
              row.isViewed ? "carbon:view-filled" : "carbon:view-off-filled"
            }
          />
        </Typography>
      );
    },
  },
  {
    flex: 0.06,
    minWidth: 100,
    field: "createdAt",
    headerName: "Создано",
    renderCell: ({ row }) => {
      return (
        <Typography variant="body2" noWrap>
          {moment(row.createdAt).format("DD.MM.YYYY, в HH:mm")}
        </Typography>
      );
    },
  },
];

export default OrderAcquiring;
