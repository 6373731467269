// Mui
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

// Config
import ConfigApi from "src/configs/api";

export default function SelectCurrency(props) {
  let {
    size = "medium",
    containerStyle,
    label,
    value,
    onChange,
    placeholder = "",
    currencies = [],
  } = props;

  return (
    <FormControl size={size} sx={containerStyle} fullWidth>
      <InputLabel id="currency-select">{label}</InputLabel>
      <Select
        size={size}
        fullWidth
        value={value}
        id="currency-select"
        label={label}
        labelId="currency-select"
        renderValue={(value) => {
          let currency = currencies.find((c) => c._id == value);
          if (!currency) return;

          return <CurrencyItem currency={currency} />;
        }}
        onChange={(e) => onChange(e.target.value)}
        inputProps={{ placeholder: placeholder }}
      >
        {currencies.map((currency) => {
          return (
            <MenuItem key={currency._id} value={currency._id}>
              <CurrencyItem currency={currency} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

function CurrencyItem(props) {
  let { currency } = props;


  let { image, name } = currency;

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        py: 0,
      }}
    >
      <Box sx={{ display: "flex" }}>
        {image && (
          <Avatar
            variant="square"
            src={ConfigApi.PUBLIC_IMAGES + image + ".png"}
            alt={name}
            sx={{ height: 23, width: 23, mr: 3 }}
          />
        )}
        {name}
      </Box>
    </Box>
  );
}
