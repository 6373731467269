const WEBSITES = [
  {
    name: "Ex-bank",
    code: "exbank",
    short: "ex-bank",
    id: "e",
    api: "https://api.ex-bank.cc",
    client: "https://ex-bank.cc",
    color: "#2196f3",
  },
  {
    name: "Crypik",
    code: "crypik",
    short: "crypik",
    id: "c",
    api: "https://api.crypik.com",
    client: "https://crypik.com",
    color: "#ff8c00",
  },
  {
    name: "Menyatt",
    code: "menyatt",
    short: "menyatt",
    id: "m",
    api: "https://api.menyatt.com",
    color: "#3bd2a2",
  },
];

export default WEBSITES;
