// ** Custom Components
import CustomChip from "src/@core/components/mui/chip";

function StatusChip({ status, size = "medium", style = {} }) {
  const statusData = getStatusData(status);

  return (
    <CustomChip
      skin="light"
      size={size}
      label={statusData.label}
      color={statusData.color}
      sx={{
        width: "min-content",
        fontSize: "1rem",
        fontWeight: 600,
        borderRadius: "5px",
        ...style,
      }}
    />
  );
}

const getStatusData = (status) => {
  switch (status) {
    case "not-verified":
      return {
        color: "warning",
        label: "Ожидание",
      };
    case "on-verified":
      return {
        color: "info",
        label: "Не проверено",
      };
    case "verified":
      return {
        color: "success",
        label: "Проверено",
      };
    case "fail-verified":
      return {
        color: "error",
        label: "Отклонено",
      };
    case "blocked":
      return {
        color: "secondary",
        label: "Заблокировано",
      };
    default:
      return {};
  }
};

export default StatusChip;
