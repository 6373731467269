import { useState, useEffect } from "react";
import { Box, Button, Card, CardContent, Grid } from "@mui/material";
// Custom
import PageWrap from "src/components/PageWrap";
import CurrencySelect from "src/components/CurrencySelect";
// ** Icon
import Icon from "src/@core/components/icon";

import CurrenciesService from "src/service/Currencies";
import WalletService from "src/service/Wallet";

// Modules
import AddDialog from "./models/AddDialog";
import KitCard from "./models/KitCard";

function PartnerRequsites() {
  let [currencies, setСurrencies] = useState([]);
  let [currencyId, setCurrencyId] = useState("");
  let [kits, setKits] = useState([]);
  let [showAdd, setShowAdd] = useState(false);

  useEffect(() => {
    CurrenciesService.getList({
      query: {
        merchantCode: { $exists: true },
      },
    }).then(({ data }) => setСurrencies(data));
  }, []);

  useEffect(() => {
    if (!currencyId) return;
    WalletService.getKits({ isPartner: true, currency: currencyId }).then(
      ({ data }) => setKits(data)
    );
    console.log("fetch partner kits");
  }, [currencyId]);

  // Calc
  let currency = currencies.find((c) => c._id === currencyId);

  return (
    <PageWrap title="Комплекты партнеров">
      <AddDialog
        addItemHandler={(item) => {
          setKits([item, ...kits]);
        }}
        currency={currency}
        show={showAdd}
        handleClose={() => {
          setShowAdd(false);
        }}
      />
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <Button
                onClick={() => setShowAdd(true)}
                startIcon={<Icon icon="material-symbols:add" />}
                sx={{ minHeight: "100%", mr: 2 }}
                variant="contained"
                fullWidth
              >
                Добавить
              </Button>
            </Grid>
            <Grid item xs={12} md={10}>
              <CurrencySelect
                label="Валюта"
                placeholder="Выбирете валюту"
                currencies={currencies}
                value={currencyId}
                setValue={(id) => setCurrencyId(id)}
                //   showBalance
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Box sx={{ mt: 4 }}>
        {kits.map((kit) => (
          <KitCard
            style={{ mb: 2 }}
            key={kit._id}
            data={kit}
            merchantCode={currency?.merchantCode}
            deleteItemHandler={(id) => {
              setKits(kits.filter((k) => k._id !== id));
            }}
          />
        ))}
      </Box>
    </PageWrap>
  );
}

export default PartnerRequsites;
