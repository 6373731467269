import { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  Chip,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";

import moment from "moment";
import "moment/locale/ru";

import CustomChip from "src/@core/components/mui/chip";

// Socket
import { socket } from "src/socket";

// ** Icon
import Icon from "src/@core/components/icon";

// Configs
import SITES from "src/configs/websites";
// Services
import TargetPairService from "src/service/TargetPair";
import ExchangeService from "src/service/Exchange";

// Toast
import toast from "react-hot-toast";

function PairSiteCard({
  data,
  tpId,
  deleteItemSequence,
  num,
  style = {},
  tkCode,
  gvCode,
}) {
  let siteName = SITES.find((s) => s.id == data.sc)?.name;

  const [siteData, setSiteData] = useState(data);
  const [exchange, setExchange] = useState(data.exchange);

  // Hooks
  useEffect(() => {
    socket.on(`target_pair_sequence_${data._id}`, (upd) => {
      setSiteData((t) => Object.assign({}, t, { ...upd }));
    });
    socket.on(`exchange_upd_part_${exchange._id}`, (upd) => {
      setExchange((e) => {
        return Object.assign({}, e, upd);
      });
    });

    return () => {
      socket.off(`target_pair_sequence_${data._id}`);
      socket.off(`exchange_upd_part_${exchange._id}`);
    };
  }, []);

  return (
    <Card
      sx={{ p: 3, ...style, border: (t) => `1px solid ${t.palette.divider}` }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={11}>
          <TopCard
            tkCode={tkCode}
            gvCode={gvCode}
            num={num}
            exchange={exchange}
            siteName={siteName}
          />
          <BottomCard
            rate={`${pairGetPrices(exchange).left} => ${
              pairGetPrices(exchange).right
            }`}
            tpId={tpId}
            id={data._id}
            position={siteData?.position}
            all={siteData?.all}
            updPosition={siteData?.updPosition}
            initLimit={siteData.limit}
            updated={moment(exchange.updatedAt).format("HH:mm:ss, DD.MM")}
          />
        </Grid>

        <Grid item xs={12} md={1}>
          <Btns
            deleteItemSequence={deleteItemSequence}
            tpId={tpId}
            id={data._id}
            enabled={siteData.enabled}
          />
        </Grid>
      </Grid>
    </Card>
  );
}

function BottomCard({
  updated,
  rate,
  initLimit,
  id,
  tpId,
  position,
  all,
  updPosition
}) {
  let [limit, setLimit] = useState(initLimit);

  let isChangedLimit = +limit !== +initLimit;

  const saveLimitHandler = () => {
    TargetPairService.changeSequence(tpId, {
      action: "change",
      data: {
        _id: id,
        limit,
      },
    }).then((r) => {
      toast.success(`Лимит сайта измененн!`);
    });
  };

  const saveLimitBtn = (
    <InputAdornment position="end">
      <IconButton
        sx={{ borderRadius: 0, px: 0.8 }}
        onClick={saveLimitHandler}
        color="primary"
        edge="end"
      >
        <Icon icon="mingcute:save-line" />
      </IconButton>
    </InputAdornment>
  );


  return (
    <Grid sx={{ mt: 0 }} container alignItems="center" spacing={3}>
      <Grid item xs={12} md={5.34}>
        <Typography sx={{ fontSize: "14px" }} variant="body1">
          {rate}
        </Typography>
        <Typography sx={{ fontSize: "12px" }} variant="body2">
          {updated}
        </Typography>
      </Grid>
      <Grid xs={6} item md={3.33}>
        <CustomChip
          fullWidth
          sx={{ py: "1px", width: "100%" }}
          rounded
          label={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ mr: 2 }} color="primary">
                <b>{position}</b> из {all}
              </Typography>
              <Typography sx={{ fontSize: "12px" }} variant="body2">
                {updPosition
                  ? moment(new Date(updPosition)).format("HH:mm:ss")
                  : null}
              </Typography>
            </Box>
          }
          skin="light"
          color="primary"
        />
      </Grid>
      <Grid xs={6} item md={3.33}>
        <TextField
          size="small"
          fullWidth
          value={limit}
          type="number"
          label="Порог"
          InputProps={{
            endAdornment: isChangedLimit ? saveLimitBtn : null,
          }}
          onChange={(e) => setLimit(e.target.value)}
        />
      </Grid>
    </Grid>
  );
}

function TopCard({ siteName, num, exchange, tkCode, gvCode }) {
  let [mn, setMn] = useState(exchange.limit.mn);
  let [mx, setMx] = useState(exchange.limit.mx);
  let [prc, setPrc] = useState(exchange.rate.prc);

  //
  useEffect(() => {
    setMn(exchange.limit.mn);
    setMx(exchange.limit.mx);
    setPrc(exchange.rate.prc);
  }, [exchange?.updatedAt]);

  //Calc
  let isbn = exchange.rate.isbn;
  let isChanged =
    +exchange.limit.mn !== +mn ||
    +exchange.limit.mx !== +mx ||
    +exchange.rate.prc !== +prc;

  // Handlers
  const changeExchangeHandler = (upd) => {
    ExchangeService.changeExchange({
      _id: exchange._id,
      ...upd,
    }).then(() => {
      toast.success("Обменная пара изменена!");
    });
  };

  return (
    <Grid container alignItems="center" spacing={3}>
      <Grid item xs={6} md={2}>
        <Typography variant="body1">
          <b>{num}</b> {siteName}{" "}
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        md={isChanged ? 3 : 3.33}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <IconButton
          sx={{
            transform: `rotate(${exchange.rate.isbn ? 0 : 180}deg)`,
            borderRadius: 0,
            p: 0.5,
            margin: "0px auto",
            mr: 1,
          }}
          onClick={() =>
            changeExchangeHandler({
              rate: {
                isbn: !isbn,
              },
            })
          }
          color={exchange.rate.isbn ? "success" : "error"}
        >
          <Icon icon="mdi:arrow-top-bold-box-outline" />
        </IconButton>
        <TextField
          size="small"
          fullWidth
          value={prc}
          label="Процент"
          type="number"
          onChange={(e) => setPrc(e.target.value)}
        />
      </Grid>
      <Grid xs={6} item md={isChanged ? 3 : 3.33}>
        <TextField
          size="small"
          fullWidth
          value={mn}
          type="number"
          label={`Мин. ${tkCode}`}
          onChange={(e) => setMn(e.target.value)}
        />
      </Grid>
      <Grid xs={isChanged ? 5 : 6} item md={isChanged ? 3 : 3.33}>
        <TextField
          size="small"
          fullWidth
          value={mx}
          type="number"
          label={`Макс. ${tkCode}`}
          onChange={(e) => setMx(e.target.value)}
        />
      </Grid>
      {isChanged && (
        <Grid
          item
          xs={1}
          md={1}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <IconButton
            onClick={() => {
              changeExchangeHandler({
                limit: {
                  mn: Math.abs(+mn),
                  mx: Math.abs(+mx),
                },
                rate: {
                  prc: Math.abs(+prc),
                },
              });
            }}
            sx={{ borderRadius: 1 }}
            color="primary"
          >
            <Icon icon="mingcute:save-line" />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}

function Btns({ id, enabled, tpId, deleteItemSequence }) {
  const deleteItemHandler = () => {
    if (!window.confirm("Удалить обменную пару сайта?")) return;
    TargetPairService.changeSequence(tpId, {
      action: "delete",
      data: id,
    }).then((r) => {
      deleteItemSequence(id);
      toast.success("Пара сайта удалена!");
    });
  };

  const changeEnabledHandler = () => {
    let newEnabled = !enabled;
    TargetPairService.changeSequence(tpId, {
      action: "change",
      data: {
        _id: id,
        enabled: newEnabled,
      },
    }).then((r) => {
      toast.success(`Пара сайта ${newEnabled ? "включена" : "выключена"}!`);
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {
          xs: "row-reverse",
          md: "column",
        },
        justifyContent: {
          xs: "space-between",
          md: "center",
        },
        alignItems: "flex-end",
        // alignItems: {
        //   sx: ''
        // }
      }}
    >
      <IconButton
        color={enabled ? "primary" : "secondary"}
        variant="contained"
        onClick={changeEnabledHandler}
        sx={{
          borderRadius: 1,
          // ml: 2,
          mb: {
            xs: 0,
            md: 2,
          },
          backgroundColor: (theme) => theme.palette.background.default,
          color: enabled ? "primary.dark" : "secondary.light",
        }}
        fullWidth
      >
        <Icon
          icon={
            enabled
              ? "pepicons-pop:power-circle-filled"
              : "pepicons-pop:power-circle-off"
          }
        />
      </IconButton>
      <IconButton
        color="error"
        variant="contained"
        onClick={deleteItemHandler}
        sx={{ borderRadius: 1 }}
      >
        <Icon icon="mdi:trash-outline" />
      </IconButton>
    </Box>
  );
}
function pairGetPrices(exc) {
  const srv = exc.rate.srv;
  const ins = exc.rate.in;

  return {
    left: ins,
    right: srv,
  };
}

export default PairSiteCard;
