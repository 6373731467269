import axios from "axios";

class Stat {
  getPanel() {
    return axios.get("/stat/panel");
  }

  getGeneral(params) {
    return axios.get("/stat/general", { params });
  }
}

export default new Stat();
