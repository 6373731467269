import { useState } from "react";
import { Box } from "@mui/material";

// ** MUI Imports
import TabContext from "@mui/lab/TabContext";
import MuiTab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Icon from "src/@core/components/icon";

import DepositTab from "./Tabs/Deposit";
import RequisitesTab from "./Tabs/Requisites";
import HistoryTab from "./Tabs/History";
import OrdersTab from "./Tabs/Orders";

// ** Styled Tab component
const Tab = styled(MuiTab)(({ theme }) => ({
  minHeight: 48,
  flexDirection: "row",
  "& svg": {
    marginBottom: "0 !important",
    marginRight: theme.spacing(1),
  },
}));

function Tabs({ merchant }) {
  const [activeTab, setActiveTab] = useState("orders");

  const handleChange = (event, value) => {
    setActiveTab(value);
  };
  return (
    <Box>
      <TabContext value={activeTab}>
        <TabList
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleChange}
          aria-label="forced scroll tabs example"
          sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
        >
          <Tab
            value="orders"
            label="Заявки"
            icon={<Icon icon="mingcute:exchange-bitcoin-2-line" />}
          />
          <Tab
            value="deposit"
            label="Депозит"
            icon={<Icon icon="mdi:instant-deposit" />}
          />
          <Tab
            value="requisites"
            label="Реквизиты"
            icon={<Icon icon="mdi:credit-cards" />}
          />
          <Tab
            value="history"
            label="История"
            icon={<Icon icon="material-symbols:history-edu-rounded" />}
          />
        </TabList>
        <TabPanel value="deposit">
          <DepositTab merchantId={merchant._id} />
        </TabPanel>
        <TabPanel value="requisites">
          <RequisitesTab merchantId={merchant._id} />
        </TabPanel>
        <TabPanel value="history">
          <HistoryTab merchantId={merchant._id} />
        </TabPanel>
        <TabPanel value="orders">
          <OrdersTab merchantId={merchant._id} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

export default Tabs;
