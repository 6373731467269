import { Box, Avatar, Typography } from "@mui/material";
import CustomAvatar from "src/@core/components/mui/avatar";

function SumAndCurrency(props) {
  let { name, image, style = {} } = props;
  return (
    <Box sx={{ display: "flex", alignItems: "center", ...style }}>
      <CustomAvatar
        skin="light"
        variant="rounded"
        sx={{ width: 30, height: 30, mr: 2 }}
      >
        <Avatar src={image} alt={name} sx={{ height: 25, width: 25 }} />
      </CustomAvatar>
      <Typography noWrap variant="body1">
        {name}
      </Typography>
    </Box>
  );
}

export default SumAndCurrency;
