import { useState } from "react";
import {
  Card,
  CardContent,
  Alert,
  Box,
  Divider,
  Typography,
  Grid,
  Button,
  CircularProgress,
  TextField
} from "@mui/material";
import WalletInfo from "src/modules/Orders/OrderDrawer/Modules/WalletInfo";
import SumTitle from "src/modules/Orders/OrderDrawer/Modules/SumTitle";

import MerchantService from "src/service/Merchant";
import FieldsRaw from "./FieldsRaw";

function TopupView({ data }) {
  let titleClient = "Клиент переводит с реквизитов";
  let titleAdmin = "Реквизиты сервиса для получения";

  return (
    <Card sx={{ bgcolor: (t) => t.palette.background.default }}>
      <CardContent sx={{ p: 3 }}>
        {data.code == "confirm-payment" && (
          <Alert sx={{ p: "4px 10px", mb: 2 }} severity="info">
            Пользователь подтвердил отправление средств.
          </Alert>
        )}

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <SumTitle text={"Сумма"} sum={data.sum} code="USDT" />
        </Box>
      </CardContent>
      <Divider sx={{ my: "0px !important" }} />
      <CardContent sx={{ p: 3 }}>
        <Typography variant="body1">{titleClient}:</Typography>
        <FieldsRaw name="Адрес USDT" value={data.address} style={{ mt: 2 }} />
      </CardContent>
      <Divider />
      <CardContent sx={{ p: 3 }}>
        <Typography variant="body1">{titleAdmin}:</Typography>
        <Box sx={{ my: 3 }}>
          <WalletInfo name="Комплект" value={data.data.kit} />
          {data.fields.map((f) => (
            <FieldsRaw key={f._d} name={f.name} value={f.value} />
          ))}
        </Box>
      </CardContent>
      <Divider />
      <Actions _id={data._id} code={data.code} />
    </Card>
  );
}

function Actions({ code, _id }) {
  let [submit, setSubmit] = useState("");
  let [isViewCancel, setIsViewCancel] = useState(false);
  let [comment, setComment] = useState("");

  let view;

  const doneHandler = () => {
    if (!window.confirm("Вы уверены?")) return;
    setSubmit("done");
    MerchantService.request("admin.ser_deposit_change", {
      action: "done",
      _id,
    }).finally(() => {
      setSubmit("");
    });
  };

  const restoreHandler = () => {
    if (!window.confirm("Вы уверены?")) return;
    setSubmit("restore");
    MerchantService.request("admin.ser_deposit_change", {
      action: "restore",
      _id,
    }).finally(() => {
      setSubmit("");
    });
  };

  const cancelHandler = () => {
    if (!window.confirm("Вы уверены?")) return;
    setSubmit("cancel");
    MerchantService.request("admin.ser_deposit_change", {
      action: "cancel",
      comment,
      _id,
    }).finally(() => {
      setSubmit("");
    });
  };

  const refundHandler = () => {
    if (!window.confirm("Вы уверены?")) return;
    setSubmit("refund");
    MerchantService.request("admin.ser_deposit_change", {
      action: "refund",
      comment,
      _id,
    }).finally(() => {
      setSubmit("");
    });
  };

  const cancelView = (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Комментарий"
          placeholder="Не обязательно"
          size="small"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <Button
          disabled={submit == "cancel"}
          startIcon={submit == "cancel" && <CircularProgress />}
          fullWidth
          onClick={cancelHandler}
          variant="contained"
        >
          Отклонить
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          onClick={() => setIsViewCancel(false)}
          fullWidth
          color="error"
          variant="outlined"
        >
          Отменить
        </Button>
      </Grid>
    </>
  );

  if (code == "payment" || code == "confirm-payment") {
    view = (
      <>
        {isViewCancel ? (
          cancelView
        ) : (
          <>
            <Grid item xs={6}>
              <Button
                disabled={submit == "done"}
                startIcon={submit == "done" && <CircularProgress />}
                fullWidth
                onClick={doneHandler}
                variant="contained"
              >
                Средства получены
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => setIsViewCancel(true)}
                fullWidth
                color="error"
                variant="outlined"
              >
                Отклонить
              </Button>
            </Grid>
          </>
        )}
      </>
    );
  } else if (code == "done") {
    view = (
      <Grid item xs={6}>
        <Button
          onClick={refundHandler}
          disabled={submit == "refund"}
          startIcon={submit == "refund" && <CircularProgress />}
          fullWidth
          color="error"
          variant="outlined"
        >
          Возврат средств
        </Button>
      </Grid>
    );
  } else if (code == "cancel") {
    view = (
      <Grid item xs={6}>
        <Button
          onClick={restoreHandler}
          disabled={submit == "restore"}
          startIcon={submit == "restore" && <CircularProgress />}
          fullWidth
          variant="outlined"
        >
          Восстановить
        </Button>
      </Grid>
    );
  }

  return (
    <CardContent sx={{ py: 1 }}>
      <Grid container spacing={4}>
        {view}
      </Grid>
    </CardContent>
  );
}

export default TopupView;
