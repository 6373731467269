import { useState, useEffect } from "react";

// *MUI*
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// ** Icon Imports
import Icon from "src/@core/components/icon";
// Services
import InfoService from "src/service/Info";
// ** Custom Components Imports
import CustomChip from "src/@core/components/mui/chip";
// Socket
import { socket } from "src/socket";

import { observer } from "mobx-react-lite";

// Helpers
import getPriceCurrentFiat from "src/helpers/getPriceCurrentFiat";

function BtcRate() {
  let [data, setData] = useState(null);

  useEffect(() => {
    InfoService.getBtcRate().then((r) => {
      setData(r.data);
    });
    socket.on("info_btcrate", (d) => setData(d));
    return () => {
      socket.off("info_btcrate");
    };
  }, []);

  if (!data) return null;

  let changeColor = data.priceChange >= 0 ? "success.light" : "error.light";

  let addPlusSymbol = data.priceChange >= 0 ? "+" : "";

  return (
    <Card sx={{ p: 1.7, display: "flex", alignItems: "center", mr: 1 }}>
      <img
        width={25}
        height={25}
        alt="Курс BTC"
        src={"https://icon-library.com/images/bitcoin-icon/bitcoin-icon-16.jpg"}
      />
      <Typography
        variant="subtitle2"
        sx={{ fontWeight: 600, color: "text.primary", ml: 2 }}
      >
        {getPriceCurrentFiat(data.price, 0)}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          ml: 2,
        }}
      >
        <Typography color={changeColor} variant="caption">
          {addPlusSymbol + data.percentChange}%
        </Typography>
        <Typography color={changeColor} variant="caption">
          {addPlusSymbol + getPriceCurrentFiat(data.priceChange, 0, true)}
        </Typography>
      </Box>
    </Card>
  );
}

export default observer(BtcRate);
