import { useEffect, useState } from "react";

import { Box, Grid } from "@mui/material";

import RequisiteCard from "src/modules/Merchant/Requisite/RequisiteCard";

import MerchantService from "src/service/Merchant";

function RequisitesTab({ merchantId }) {
  let [requisites, setRequisites] = useState([]);

  useEffect(() => {
    MerchantService.request("admin.ser_requisite_get-by-account", {
      id: merchantId,
    }).then((res) => {
      setRequisites(res.data);
    });
  }, []);

  return (
    <Box>
      <Grid container spacing={4}>
        {requisites.map((o) => {
          return (
            <Grid item xs={12} md={6} key={o._id}>
              <RequisiteCard key={o._id} status={o?.status} data={o} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

export default RequisitesTab;
