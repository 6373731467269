// Mui
import { Box, Avatar, Typography } from "@mui/material";

// Config
import ConfigApi from "src/configs/api";

//Custom components
import CustomAvatar from "src/@core/components/mui/avatar";

export default function TitleCurrencyCard({ image, name }) {
  return (
    <Box sx={{ display: "flex", alignItems: 'center' }}>
      <CustomAvatar
        skin="light"
        variant="rounded"
        sx={{ width: 40, height: 40, mr: 2 }}
      >
        <Avatar
          src={ConfigApi.PUBLIC_IMAGES + image + ".png"}
          alt={name}
          sx={{ height: 30, width: 30 }}
        />
      </CustomAvatar>
      <Typography variant="subtitle1">{name}</Typography>
    </Box>
  );
}
