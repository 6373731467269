import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Divider,
} from "@mui/material";

function OutcomeTable(props) {
  let { list = [] } = props;

  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Комплект</TableCell>
            <TableCell>Сумма</TableCell>
            <TableCell>Комиссия</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((row) => (
            <TableRow
              key={row._id}
              sx={{
                "&:last-of-type td, &:last-of-type th": {
                  border: 0,
                },
              }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="left">{row.sum}</TableCell>
              <TableCell align="left">{row.cms}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Divider/>
    </>
  );
}

export default OutcomeTable;
