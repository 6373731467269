import { useEffect, useState } from "react";
// Mui
import { Box, CircularProgress } from "@mui/material";

// Modules
import TargetPairCard from "./TargetPairCard";
// Services
import TargetPairService from "src/service/TargetPair";

// Event
// import emitter from "src/EventEmitter";

function TargetPairList({ statusFilter, takeId, giveId }) {
  let [list, setList] = useState([]);
  let [loaded, setLoaded] = useState(true);

  // // Hooks
  useEffect(() => {
    if (statusFilter !== "all") return;
    setLoaded(true);
    TargetPairService.getPairTargetFull({
      filter: statusFilter,
    })
      .then((r) => {
        setList(r.data);
      })
      .finally(() => setLoaded(false));
  }, [statusFilter]);

  // // Hooks
  useEffect(() => {
    if (statusFilter !== "pair") return;
    let hasPair = takeId && giveId;
    if (!hasPair) return;
    if (takeId == giveId) return;
    setLoaded(true);
    TargetPairService.getPairTargetFull({
      take: takeId,
      give: giveId,
    }).then((r) => {
      setList(r.data);
      setLoaded(false);
    });
  }, [takeId, giveId, statusFilter]);

  const deleteItemHandler = (id) => {
    setList((list) => list.filter((t) => t._id !== id));
  };

  const listComponent = (
    <Box>
      {list.map((p) => (
        <TargetPairCard
          deleteTargetPair={deleteItemHandler}
          style={{ mb: 4 }}
          key={p._id}
          data={p}
        />
      ))}
    </Box>
  );

  return <Box>{loaded ? loadedComponent : listComponent}</Box>;
}

let loadedComponent = (
  <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
    <CircularProgress />
  </Box>
);

export default TargetPairList;
