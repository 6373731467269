import { useState, useEffect } from "react";
// ** MUI Imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import LinearProgress from "@mui/material/LinearProgress";

// Hooks
import { useAuth } from "src/hooks/useAuth";

// Services
import StatService from "src/service/Stat";

// ** Utils
import getPriceCurrentFiat from "src/helpers/getPriceCurrentFiat";
// Modules
import StatOrderCount from "./StatOrderCount";
import StatProfit from "./StatProfit";
import MiniStat from "./MiniStat";
import Capitalization from "./Capitalization";

import { observer } from "mobx-react-lite";

// Permission provider
import PermissionProvider from "src/permission/PermissionProvider";

function Stat() {
  let [data, setData] = useState(null);

  // Hooks
  const { isAdmin, permission } = useAuth();

  useEffect(() => {
    StatService.getPanel().then((r) => {
      setData(r.data);
    });
  }, []);

  if (!data) return null;

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <PermissionProvider name="dashboard.stats.finance-сounts">
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <Capitalization sum={data.capitalization} />
            </Grid>
          </Grid>
        </PermissionProvider>
      </Grid>
      <PermissionProvider name="dashboard.stats.сounts">
        <Grid item xs={12} md={6}>
          <StatOrderCount countData={data.dayCountOrders} />
        </Grid>
      </PermissionProvider>
      <PermissionProvider name="dashboard.stats.finance-сounts">
        <Grid item xs={12} md={6}>
          <StatProfit profitData={data.dayProfit} />
        </Grid>
      </PermissionProvider>
      <PermissionProvider name="dashboard.stats.сounts">
        <Grid item xs={12} md={6}>
          <MiniStat
            color="primary"
            icon="mdi:user"
            stats={data.dayCountClients}
            title="Новых клиентов"
          />
        </Grid>
      </PermissionProvider>
      <PermissionProvider name="dashboard.stats.finance-сounts">
        <Grid item xs={12} md={6}>
          <MiniStat
            color="warning"
            icon="mdi:invite"
            stats={getPriceCurrentFiat(data.dayAccrual.referral, 0)}
            title="Начислено рефералам"
          />
        </Grid>
      </PermissionProvider>
      <PermissionProvider name="dashboard.stats.finance-сounts">
        <Grid item xs={12} md={6}>
          <MiniStat
            color="info"
            icon="mdi:cash-chargeback"
            stats={getPriceCurrentFiat(data.dayAccrual.cashback, 0)}
            title="Начислено кэшбека"
          />
        </Grid>
      </PermissionProvider>
    </Grid>
  );
}

export default observer(Stat);
