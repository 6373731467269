import { forwardRef } from "react";
// Mui
import {
  Card,
  CardHeader,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  TextField,
  MenuItem,
  Button,
  CircularProgress,
} from "@mui/material";

// Libs
import DatePicker, { registerLocale } from "react-datepicker";

import ru from "date-fns/locale/ru"; // the locale you want
// register it with the name you want

// moment
import moment from "moment";
import "moment/locale/ru";

// ** Styled Component
import DatePickerWrapper from "src/@core/styles/libs/react-datepicker";

// Compoenents
import SimpleSelectCurrency from "src/components/Currency/SimpleSelectCurrency";

registerLocale("ru", ru);

function Filter({
  typeStatisticsList,
  selectedTypeStatistic,
  setSelectedTypeStatistic,
  currencies,
  selectedCurrency,
  setSelectedCurrency,
  siteOptionList = [],
  selectedSite,
  setSelectedSite,
  periodList = [],
  selectedPeriod,
  setSelectedPeriod,
  startDateRange,
  setStartDateRange,
  endDateRange,
  setEndDateRange,
  isSubmit,
  fetchHandler
}) {
  const handleOnChangeRange = (dates) => {
    const [start, end] = dates;
    setStartDateRange(start);
    setEndDateRange(end);
  };

  const CustomInput = forwardRef((props, ref) => {
    // const startDate = format(props.start, 'MM/dd/yyyy')
    const startDate = moment(props.start).format("DD.MM.yyyy");
    const endDate = moment(props.end).format("DD.MM.yyyy");

    const value = `${startDate}-${endDate}`;

    return (
      <TextField
        fullWidth
        inputRef={ref}
        label={props.label || ""}
        {...props}
        value={value}
      />
    );
  });

  return (
    <Card>
      <CardHeader title="Фильтр" />
      <CardContent>
        <FormControl sx={{ mb: 3 }} fullWidth>
          <InputLabel>Сайт</InputLabel>
          <Select
            value={selectedSite}
            label="Сайт"
            onChange={(e) => setSelectedSite(e.target.value)}
          >
            {siteOptionList.map((t) => {
              return (
                <MenuItem key={t.value} value={t.value}>
                  {t.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl sx={{ mb: 3 }} fullWidth>
          <InputLabel>Тип</InputLabel>
          <Select
            value={selectedTypeStatistic}
            label="Тип"
            onChange={(e) => setSelectedTypeStatistic(e.target.value)}
          >
            {typeStatisticsList.map((t) => {
              return (
                <MenuItem key={t.value} value={t.value}>
                  {t.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {selectedTypeStatistic == "currency" && (
          <SimpleSelectCurrency
            label="Валюта"
            containerStyle={{
              mb: 3,
            }}
            currencies={currencies}
            value={selectedCurrency}
            onChange={setSelectedCurrency}
          />
        )}
        <FormControl sx={{ mb: 3 }} fullWidth>
          <InputLabel>Период времени</InputLabel>
          <Select
            value={selectedPeriod}
            label="Период времени"
            onChange={(e) => setSelectedPeriod(e.target.value)}
          >
            {periodList.map((t) => {
              return (
                <MenuItem key={t.value} value={t.value}>
                  {t.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {selectedPeriod == "time" && (
          <DatePickerWrapper
            sx={{ "& .react-datepicker-wrapper": { width: "100%", mb: 3 } }}
          >
            <DatePicker
              styl
              selectsRange
              monthsShown={2}
              endDate={endDateRange}
              selected={startDateRange}
              startDate={startDateRange}
              locale="ru"
              shouldCloseOnSelect={false}
              onChange={handleOnChangeRange}
              customInput={
                <CustomInput
                  label="От и До"
                  end={endDateRange}
                  start={startDateRange}
                />
              }
            />
          </DatePickerWrapper>
        )}
        <Button
          startIcon={isSubmit ? <CircularProgress size={18} /> : null}
          disabled={isSubmit}
          variant="contained"
          fullWidth
          onClick={fetchHandler}
          // sx={{ mt: 3 }}
        >
          Подсчитать
        </Button>
      </CardContent>
    </Card>
  );
}

export default Filter;
