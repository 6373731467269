import { useState, useEffect } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// Config
import ConfigApi from "src/configs/api";

// Toast
import { toast } from "react-hot-toast";

// Services
import SiteCurrencyService from "src/service/SiteCurrency";
import ExchangeService from "src/service/Exchange";

// Custom components
import CurrencySelect from "src/components/CurrencySelect";

// Config
import WEBSITES from "src/configs/websites";
function AddExchangeModal(props) {
  let { show, closeHandler, site, baseId, addExchangeHandler } = props;

  // States
  const [quoteCurrencyId, setQuoteCurrencyId] = useState(null);
  const [isSubmiting, setSubmiting] = useState(false);
  const [quoteCurrencies, setQuoteCurrencies] = useState([]);

  const s = WEBSITES.find((s) => s.id === site);

  // Hooks
  useEffect(() => {
    SiteCurrencyService.getSiteCurrencyList({
      sc: s.id,
    }).then((r) => {
      setQuoteCurrencies(r.data);
    });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setSubmiting(true);
    ExchangeService.addExchange({
      sc: site,
      base: baseId,
      quote: quoteCurrencyId,
    })
      .then((r) => {
        addExchangeHandler(r.data);
        closeHandler();
        toast.success('Обменная пара создана!')
      })
      .finally(() => setSubmiting(false));
  };

  // Calc
  let formatedQuoteCurrencies = quoteCurrencies.map((c) => {
    let { name, image } = c.cur;
    return { _id: c._id, name, image };
  });

  let baseCurrency = formatedQuoteCurrencies.find((c) => c._id === baseId);

  formatedQuoteCurrencies = formatedQuoteCurrencies.filter(
    (c) => c._id !== baseId
  );

  return (
    <Box>
      <Dialog
        fullWidth
        open={show}
        maxWidth="xs"
        scroll="body"
        onClose={closeHandler}
      >
        <form onValidate onSubmit={onSubmit}>
          <DialogContent
            sx={{
              pb: 6,
              px: { xs: 8, sm: 15 },
              pt: { xs: 8, sm: 12.5 },
              position: "relative",
            }}
          >
            <IconButton
              size="small"
              onClick={closeHandler}
              sx={{ position: "absolute", right: "1rem", top: "1rem" }}
            >
              <Icon icon="mdi:close" />
            </IconButton>
            <Box sx={{ mb: 4, textAlign: "center" }}>
              <Typography variant="h5" sx={{ mb: 3, lineHeight: "2rem" }}>
                Добавить обменную пару
              </Typography>
              <Typography variant="body2">
                Добавьте уникальную пару которой еще нет у сайта {s.name}
              </Typography>
            </Box>
            {baseCurrency && <BaseCurrencyInfo currency={baseCurrency} />}
            <CurrencySelect
              label="Валюта"
              placeholder="Выбирете валюту"
              value={quoteCurrencyId}
              setValue={setQuoteCurrencyId}
              currencies={formatedQuoteCurrencies}
            />
          </DialogContent>
          <DialogActions
            sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: "center" }}
          >
            <Button
              startIcon={isSubmiting ? <CircularProgress size="1rem" /> : null}
              disabled={isSubmiting}
              variant="contained"
              sx={{ mr: 2 }}
              onClick={onSubmit}
              type="submit"
            >
              Добавить
            </Button>
            <Button variant="outlined" color="secondary" onClick={closeHandler}>
              Отмена
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

function BaseCurrencyInfo({ currency }) {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          p: 4,
          border: "1px",
          borderStyle: "solid",
          borderColor: (t) => `rgba(${t.palette.customColors.main}, 0.32)`,
          borderRadius: 1,
        }}
      >
        <Avatar
          variant="square"
          src={ConfigApi.PUBLIC_IMAGES + currency.image+ '.png'}
          alt={currency.name}
          sx={{ height: 23, width: 23, mr: 3 }}
        />
        {currency.name}
      </Box>
      <Box sx={{ my: 2 }}>
        <Box
          sx={{
            maxWidth: "max-content",
            mx: "auto",
            my: 1,
            transform: "rotate(90deg)",
          }}
        >
          <Icon icon="uil:exchange" />
        </Box>
      </Box>
    </Box>
  );
}

export default AddExchangeModal;
