import WEBSITES from "src/configs/websites";
// permission
import navPermissionFilter from "src/permission/navPermissionFilter";

const contentPages = [
  {
    title: "Соглашение",
    path: "/content/agreement",
  },
  {
    title: "Уведомление",
    path: "/content/notification",
  },
  {
    title: "Контакты",
    path: "/content/contacts",
  },
  {
    title: "Реклама",
    path: "/content/ad",
  },
  {
    title: "FAQ",
    path: "/content/faq",
  },
  {
    title: "Новости",
    path: "/content/news",
  },

  {
    title: "Рассылка",
    path: "/content/mail",
  },
];

const navigation = (isAdmin, permission) => {
  const contentChildren = [];

  WEBSITES.forEach((s) => {
    contentChildren.push({
      title: s.name,
      children: contentPages.map((page) => {
        return {
          title: page.title,
          path: page.path + "/" + s.code,
        };
      }),
    });
  });

  const currenciesChildren = [];

  WEBSITES.forEach((s) => {
    currenciesChildren.push({
      title: s.name,
      path: "/currencies/" + s.id,
    });
  });

  const coursesChildren = [];

  WEBSITES.forEach((s) => {
    coursesChildren.push({
      title: s.name,
      path: "/courses/" + s.id,
    });
  });

  coursesChildren.push({
    title: "Таргет",
    path: "/courses/target",
  });

  let list = [
    {
      title: "Заявки",
      path: "/orders",
      icon: "ic:twotone-currency-exchange",
    },
    {
      title: "Карты",
      path: "/cards",
      icon: "ic:round-credit-card",
    },
    {
      title: "Панель",
      path: "/dashboard",
      icon: "material-symbols:dashboard",
    },
    {
      title: "Курсы",
      icon: "bi:currency-exchange",
      children: coursesChildren,
    },
    {
      title: "Валюты",
      icon: "tabler:coin-bitcoin",
      permission: "currencies",
      children: currenciesChildren,
    },

    {
      title: "Настройки",
      icon: "material-symbols:settings",
      children: [
        {
          title: "Черный список",
          path: "/settings/black-list",
          permission: "settings.black-list",
        },
        {
          title: "Комментарии к картам",
          path: "/settings/card-comments",
          permission: "settings.card-comments",
        },
        {
          title: "Валюты и курсы",
          path: "/settings/currency-and-rate",
          permission: "settings.currency-and-rate",
        },
        {
          title: "Системные настройки",
          path: "/settings/system",
          permission: "settings.system-settings",
        },
        {
          title: "Операторы и доступ",
          path: "/settings/operators",
          onlyAdmin: true,
        },
        {
          title: "Локализация",
          path: "/settings/localization",
          permission: "settings.localization",
        },
      ],
    },
    {
      title: "Контент",
      permission: "content",
      icon: "material-symbols:content-paste-rounded",
      children: contentChildren,
    },
    {
      title: "Реквизиты",
      icon: "uil:wallet",
      children: [
        {
          title: "Управление",
          path: "/requisites/control",
        },
        {
          title: "Общие",
          path: "/requisites/global",
        },
        {
          title: "Партнеров",
          path: "/requisites/partners",
        },
        {
          title: "История",
          children: [
            {
              title: "Обмены",
              path: "/requisites/history/exchanges",
            },
            {
              title: "Внутренние переводы",
              path: "/requisites/history/transfers",
            },
            {
              title: "Операции",
              path: "/requisites/history/operations",
            },
          ],
        },
      ],
    },
    {
      title: "Пользователи",
      icon: "mdi:user",
      path: "/clients",
    },
    {
      title: "Вознаграждение",
      icon: "fluent:reward-12-filled",
      path: "/rewards",
      permission: "rewards",
    },
    {
      title: "Финансы",
      icon: "carbon:finance",
      children: [
        {
          title: "Долги",
          path: "/finance/arrears",
          permission: "finance.debts",
        },
        {
          title: "Статистика",
          path: "/finance/statistics",
          permission: "finance.statistics",
        },
      ],
    },

    {
      title: "Партнеры",
      icon: "mdi:partnership-outline",
      children: [
        {
          title: "Эквайринг",
          path: "/partners/acquiring",
          onlyAdmin: true,
        },
      ],
    },
    {
      title: "Мерчант",
      icon: "icon-park-solid:card-two",
      children: [
        {
          title: "Заявки",
          path: "/merchant/orders",
          onlyAdmin: true,
        },
        {
          title: "Депозит",
          path: "/merchant/deposit",
          onlyAdmin: true,
        },
        {
          title: "Реквизиты",
          path: "/merchant/requisites",
          onlyAdmin: true,
        },
        {
          title: "Тарифы",
          path: "/merchant/tariff",
          onlyAdmin: true,
        },
        {
          title: "Мерчанты",
          path: "/merchant/clients",
          onlyAdmin: true,
        },
      ],
    },
    {
      title: "Обновления",
      icon: "material-symbols:info-outline",
      path: "/updates",
    },
  ];

  return navPermissionFilter(list, isAdmin, permission);
};

//TODO поменять расположение

export default navigation;
