import { useState, useEffect } from "react";
// Mui
import { Box, Grid, CircularProgress } from "@mui/material";

// Modules
import Filter from "./Filter";
import Control from "./Control";

// Global
import OrderCard from "src/modules/Orders/OrderCard/OrderCard";

// Modules
import WalletQuickView from "./WalletQuickView";
import AdvancedFilterDialog from "./AdvancedFilterDialog";

// Services
import OrderService from "src/service/Order";

// LIBS
import InfiniteScroll from "react-infinite-scroll-component";

// Configs
import WEBSITES from "src/configs/websites";

// Socker

import { socket } from "src/socket";

// Timer
let timerId;

const LIMIT = 24;

const WEBSITES_LIST = [
  {
    value: "all",
    name: "Все",
  },
  ...WEBSITES.map((w) => ({
    name: w.name,
    value: w.id,
  })),
];
const ORDER_STATUS_LIST = [
  {
    value: "all",
    name: "Все",
  },
  {
    value: "new",
    name: "Новые",
  },
  {
    value: "completed",
    name: "Выполненные",
  },
  {
    value: "rejected",
    name: "Отклоненные",
  },
  {
    value: "check",
    name: "На проверке",
  },

  {
    value: "archive",
    name: "Архив",
  },
];

const initSiteValue = WEBSITES_LIST[0].value;
const initStatusValue = ORDER_STATUS_LIST[0].value;

function Orders() {
  // Main Filter
  const [site, setSite] = useState(initSiteValue);
  const [status, setStatus] = useState(initStatusValue);

  // Advanced filter
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
  const [advancedFilterData, setAdvancedFilterData] = useState(
    initAdvancedFilterData
  );
  const [search, setSearch] = useState("");

  // Data
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  // Calc
  let isSearchFilter = search.trim();
  let isMultiFilter = objHasValues(advancedFilterData);
  let isAdvancedFilter = isSearchFilter || isMultiFilter;

  // Hooks
  useEffect(() => {
    socket.on("order_add", (o) => {
      setOrders((list) => [o, ...list]);
    });
    socket.on("order_upd_item", (u) => {
      setOrders((list) => {
        return list.map((o) => {
          return o._id == u._id ? Object.assign(o, u) : o;
        });
      });
    });
    return () => {
      socket.off("order_upd_item");
      socket.off("order_add");
    };
  }, []);

  useEffect(() => {
    if (isAdvancedFilter) {
      if (isMultiFilter) {
        if (!showAdvancedFilter) {
          searchFilterOrders(true);
        }
      } else {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
          searchFilterOrders(true);
        }, 2000);
      }
    } else {
      fetchOrders(true);
    }
  }, [status, site, search, isMultiFilter, showAdvancedFilter]);

  // Handlers
  const closeAdvancedFilter = () => {
    setShowAdvancedFilter(false);
    setAdvancedFilterData(initAdvancedFilterData);
  };

  const openAdvancedFilter = () => {
    // setAdvancedFilterData(initAdvancedFilterData);
    setShowAdvancedFilter(true);
  };

  // const clearMainFilters = () => {
  //   setSite(initSiteValue);
  //   setStatus(initStatusValue);
  // };

  const clearAdvancedFilters = () => {
    setSearch("");
    setAdvancedFilterData(initAdvancedFilterData);
  };

  const changeMainFilter = (name, value) => {
    if (name == "site") {
      setSite(value);
    } else if (name == "status") {
      setStatus(value);
    }
  };

  const changeAdvancedFilter = (name, value) => {
    if (name == "multi") {
      setAdvancedFilterData(value);
    } else if (name == "search") {
      setSearch(value);
    }
  };
  // // Api
  const fetchOrders = (isInit = false) => {
    setLoading(true);
    OrderService.getList({
      filter: {
        status,
        site,
      },
      pagination: {
        limit: LIMIT,
        skip: isInit ? 0 : orders.length,
      },
    })
      .then((r) => {
        let data = r.data;
        setOrders((list) => {
          return isInit ? data : [...list, ...data];
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const searchFilterOrders = (isInit = false) => {
    let mode = isMultiFilter ? "multi" : "search";
    let data = isMultiFilter ? advancedFilterData : search.trim();

    setLoading(true);
    OrderService.getSearchList({
      filter: {
        data,
        mode,
      },
      pagination: {
        limit: LIMIT,
        skip: isInit ? 0 : orders.length,
      },
    })
      .then((r) => {
        let data = r.data;
        setOrders((list) => {
          return isInit ? data : [...list, ...data];
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchApi = isAdvancedFilter ? searchFilterOrders : fetchOrders;

  // Calc
  let filtredOrders = isAdvancedFilter
    ? orders
    : filterOrder(site, status, [...orders]);

  return (
    <Box>
      <AdvancedFilterDialog
        closeModal={() => setShowAdvancedFilter(false)}
        show={showAdvancedFilter}
        handleClose={closeAdvancedFilter}
        filterData={advancedFilterData}
        setFilterData={(v) => changeAdvancedFilter("multi", v)}
        initFilterData={initAdvancedFilterData}
      />
      {!isAdvancedFilter && (
        <Filter
          site={site}
          setSite={(v) => changeMainFilter("site", v)}
          status={status}
          setStatus={(v) => changeMainFilter("status", v)}
          WEBSITES={WEBSITES_LIST}
          STATUSES={ORDER_STATUS_LIST}
        />
      )}

      <Control
        openAdvancedFilter={openAdvancedFilter}
        search={search}
        setSearch={(v) => changeAdvancedFilter("search", v)}
        isMultiFilter={isMultiFilter}
        isAdvancedFilter={isAdvancedFilter}
        clearAdvancedFilters={clearAdvancedFilters}
      />

      <InfiniteScroll
        dataLength={orders.length}
        next={fetchApi}
        hasMore={true}
        loader={null}
      >
        <Grid sx={{ mt: 4 }} container spacing={4}>
          {filtredOrders.map((o) => {
            return (
              <Grid key={o._id} item xs={12} sm={6} md={3}>
                <OrderCard visibleArchived={isAdvancedFilter} key={o._id} status={o?.status} data={o} />
              </Grid>
            );
          })}
        </Grid>
      </InfiniteScroll>
      {loading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pt: 2,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <WalletQuickView />
    </Box>
  );
}

// Methods

function objHasValues(obj) {
  return !!Object.values(obj).filter((v) => v).length;
}

function filterOrder(sc, status, orders) {
  let result = [...orders];

  if (sc !== "all") {
    result = result.filter((o) => o.sc == sc);
  }

  if (status !== "all") {
    switch (status) {
      case "new":
        result = result.filter((o) => {
          let s = o.status;
          return (s == 101 || s == 102 || s == 103 || s == 104) && !o.archived;
        });
        break;
      case "completed":
        result = result.filter((o) => {
          let s = o.status;
          return s == 105 && !o.archived;
        });
        break;
      case "rejected":
        result = result.filter((o) => {
          let s = o.status;
          return s == 106 && !o.archived;
        });
        break;
      case "check":
        result = result.filter((o) => {
          return o.onCheck;
        });
        break;
      case "archive":
        result = result.filter((o) => {
          return o.archived;
        });
        break;
      default:
    }
  } else {
    result = result.filter((o) => {
      return !o.archived;
    });
  }
  return result;
}

// Data

// CONSTS

const initAdvancedFilterData = {
  site: "",
  status: "",
  order: "",
  email: "",
  name: "",
  buy: "",
  sell: "",
  requisite: "",
  ip: "",
  comment: "",
  from: "",
  to: "",
};

export default Orders;
