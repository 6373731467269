import { useState, useEffect } from "react";
// Mui
import { Box, Tab as MuiTab, Grid, CircularProgress } from "@mui/material";

import RewardService from "src/service/Reward";

// Modules
import RewardCard from "src/modules/Rewards/RewardCard";

// Components
import EmptyListStub from "src/components/EmptyListStub";

export default function Withdraw({ clientId }) {
  let [list, setList] = useState([]);
  let [loaded, setLoaded] = useState(true);

  useEffect(() => {
    setLoaded(true);
    RewardService.search({
      client: clientId,
    })
      .then((r) => setList(r.data))
      .finally(() => {
        setLoaded(false);
      });
  }, []);

  if (loaded) {
    return (
      <Box
        sx={{
          display: "flex",
          pt: 4,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  } else if (!loaded && !list.length) {
    return <EmptyListStub text="Список пуст" />;
  }

  return (
    <Box>
      <EmptyListStub />

      <Grid sx={{ mt: 4 }} container spacing={4}>
        {list.map((o) => {
          return (
            <Grid key={o._id} item xs={12} sm={12} md={6}>
              <RewardCard data={o} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
