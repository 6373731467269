import { useState, useEffect } from "react";

import {
  Card,
  CardContent,
  Box,
  Grid,
  useMediaQuery,
  useTheme,
  Collapse,
  IconButton,
} from "@mui/material";

import ActiveSwitch from "../../modules/Ui/ActiveSwitch";
import CustomTextField from "../../modules/Ui/CustomTextField";
import BalanceInfo from "../../modules/Ui/BalanceInfo";
import LoyaltySelect from "src/components/LoyaltySelect";
import RoundSelect from "../../modules/Ui/RoundSelect";
import SingleAccountDialog from "../../History/SingleAccountDialog";

// Modules
import RequisiteList from "./RequisiteList";

// Socket
import { socket } from "src/socket";

import WalletService from "src/service/Wallet";

import { Icon } from "@iconify/react";

import { toast } from "react-hot-toast";

function KitCard({ style = {}, data, merchantCode, deleteItemHandler }) {
  const [kit, setKit] = useState(data);
  const [name, setName] = useState(data.name);
  const [showMore, setShowMore] = useState(false);
  let [showHistory, setShowHistory] = useState(false);
  let [showHistoryData, setShowHistoryData] = useState(null);

  // Hooks
  useEffect(() => {
    socket.on(`update_kit_${data._id}`, (upd) => {
      setKit((k) => ({ ...k, ...upd }));
    });
    return () => {
      socket.off(`update_kit_${data._id}`);
    };
  }, []);

  //Handlers
  const openShowHistory = (id) => {
    setShowHistoryData({
      id,
      mode: "kit",
    });
    setShowHistory(true);
  };

  const changeLoyaltyHadnler = (loyalty) => {
    WalletService.changeKit({
      _id: kit._id,
      loyalty,
    }).then(() => {
      toast.success(`Уровень лояльности изменен!`);
    });
  };
  const deleteHandler = () => {
    if (!window.confirm("Удалить комлект?")) return;

    WalletService.deleteKit(kit._id).then((r) => {
      deleteItemHandler(kit._id);
      toast.success(`Комплект удален!`);
    });
  };
  const changeRoundHadnler = (round) => {
    WalletService.changeKit({
      _id: kit._id,
      round,
    }).then(() => {
      toast.success(`Обход комлекта изменен!`);
    });
  };
  const changeEnabledHadnler = (enabled) => {
    WalletService.changeKit({
      _id: kit._id,
      enabled,
    }).then(() => {
      toast.success(`Комплект ${enabled ? "включен" : "выключен"}!`);
    });
  };
  const saveNameHandler = (v) => {
    WalletService.changeKit({
      _id: kit._id,
      name,
    }).then(() => {
      toast.success(`Комплект изменен!`);
    });
  };

  // Calc
  const theme = useTheme();
  let kitCurrency = data.wallet.currency;
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const btns = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: {
          sx: "row-reverse",
          md: "row",
        },
        alignItems: "center",
      }}
    >
      <IconButton sx={{ mt: 2 }} onClick={() => setShowMore(!showMore)}>
        <Icon icon="material-symbols:expand-more-rounded" />
      </IconButton>
      <Box sx={{ display: "flex", alignItems: "flex-end", mt: 2 }}>
        <IconButton
          color="primary"
          variant="contained"
          onClick={() => openShowHistory(kit._id)}
          title="История комплекта"
          sx={{
            backgroundColor: (theme) => theme.palette.background.default,
            borderRadius: 1,
            ml: 2,
          }}
          fullWidth
        >
          <Icon icon="material-symbols:history-edu-outline" />
        </IconButton>
        <IconButton
          onClick={deleteHandler}
          sx={{
            ml: 2,
            borderRadius: 1,
          }}
          color="error"
        >
          <Icon icon="ic:baseline-delete-forever" />
        </IconButton>
      </Box>
    </Box>
  );
  return (
    <Box sx={{ ...style }}>
      <SingleAccountDialog
        data={showHistoryData}
        open={showHistory}
        closeHandler={() => {
          setShowHistory(false);
          setShowHistoryData(null);
        }}
      />
      <Card>
        <CardContent>
          <Grid spacing={4} container>
            <Grid
              item
              xs={12}
              md={7}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <ActiveSwitch
                  isKit
                  hideNumber
                  enabled={kit.enabled}
                  onChange={changeEnabledHadnler}
                  style={{ mr: 3 }}
                />
                <CustomTextField
                  label="Название"
                  placeholder="Введите название"
                  isChanged={name.trim() !== kit.name.trim()}
                  saveHandler={saveNameHandler}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Box>

              {isDesktop && btns}
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <LoyaltySelect
                initValue={kit.loyalty}
                value={kit.loyalty}
                setValue={changeLoyaltyHadnler}
                style={{ mb: 2 }}
              />
              <RoundSelect
                initValue={kit.round}
                value={kit.round}
                setValue={changeRoundHadnler}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <BalanceInfo
                balance={kit.balance}
                disabledBalance={kit.disabledBalance}
                currency={kitCurrency}
                isPartner
              />
            </Grid>
          </Grid>
          {!isDesktop && btns}
        </CardContent>
      </Card>

      <Collapse in={showMore} timeout="auto" unmountOnExit>
        <RequisiteList kit={kit} merchantCode={merchantCode} />
      </Collapse>
    </Box>
  );
}

export default KitCard;
