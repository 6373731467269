// ** Store
import ServiceDataStore from "src/store/ServiceData";
import ServiceSettingsStore from "src/store/ServiceSettings";
// Libs
import { FormatMoney } from "format-money-js";

const fm = new FormatMoney({
  separator: " ",
  leadZeros: true,
  append: true,
});

function getPriceCurrentFiat(price, precision = 8, hideSymbol = false) {
  let cur = ServiceSettingsStore.getSettlementСurrency;
  let { USD } = ServiceDataStore.getFiatPrices;

  const isUSD = cur == "USD";

  let sum = fm.from(isUSD ? price / USD : price, { decimals: precision });

  let symbol = "";

  if (!hideSymbol) {
    symbol = isUSD ? " USD" : " RUB";
  }

  return sum + symbol;
}

export default getPriceCurrentFiat;
