// ** React Imports
import { useState } from "react";

// ** Next Import
import { Link, useNavigate } from "react-router-dom";

// ** MUI Components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import { styled, useTheme } from "@mui/material/styles";
import MuiCard from "@mui/material/Card";
import InputAdornment from "@mui/material/InputAdornment";
import MuiFormControlLabel from "@mui/material/FormControlLabel";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";

// ** Icon Imports
import Icon from "src/@core/components/icon";

// ** Configs
// import themeConfig from "src/configs/themeConfig";

// ** Layout Import
import BlankLayout from "src/@core/layouts/BlankLayout";

// ** Demo Imports
// import FooterIllustrationsV1 from "src/views/pages/auth/FooterIllustrationsV1";
import { useForm, Controller } from "react-hook-form";

// ** Services
import AuthService from "src/service/Auth";

// ** Styled Components
const Card = styled(MuiCard)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: { width: 450 },
}));

const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": {
    fontSize: "0.875rem",
    color: theme.palette.text.secondary,
  },
}));

const Login = () => {
  // ** Hook
  const theme = useTheme();
  const navigate = useNavigate();
  // ** State
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmiting, setSubmiting] = useState(false);
  const [showError, setShowError] = useState(false);
  const [textError, setTextError] = useState("");

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  // ** Fetchs

  const onSubmit = handleSubmit((data) => {

    setSubmiting(true);

    AuthService.login(data)
      .then((res) => {
        navigate("/confirm-login", {
          state: {
            email: data.email,
            password: data.password,
            expires: res.data.expires,
          },
        });
      })
      .catch((e) => {
        let message = e.response.data.message;
        if (message) {
          setTextError(message);
          setShowError(true);
        }
      })
      .finally(() => {
        setSubmiting(false);
      });
  });

  // ** Handlers

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <BlankLayout>
      <Box className="content-center">
        <Card sx={{ zIndex: 1 }}>
          <CardContent
            sx={{ p: (theme) => `${theme.spacing(13, 7, 6.5)} !important` }}
          >
            <Box sx={{ mb: 6 }}>
              <Typography
                variant="h5"
                sx={{ mb: 1.5, fontWeight: 600, letterSpacing: "0.18px" }}
              >
                {`Добро пожаловать! 👋🏻`}
              </Typography>
              <Typography variant="body2">
                Пожалуйста авторизуйтесь чтобы получить доступ к панели.
              </Typography>
            </Box>

            <Collapse sx={{ mb: 4 }} in={showError}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    size="small"
                    color="inherit"
                    aria-label="close"
                    onClick={() => setShowError(false)}
                  >
                    <Icon icon="mdi:close" fontSize="inherit" />
                  </IconButton>
                }
              >
                {textError}
              </Alert>
            </Collapse>

            <form autoComplete="off" noValidate onSubmit={onSubmit}>
              <Controller
                name={"email"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    autoFocus
                    fullWidth
                    id="email"
                    type="email"
                    label="Email"
                    sx={{ mb: 4 }}
                    error={Boolean(errors.email?.message)}
                    {...register("email", {
                      required: "Введите Email!",
                      pattern: {
                        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                        message: "Некорректный Email!",
                      },
                      minLength: {
                        message: "Мин. длина: 4 символа",
                        value: 4,
                      },
                      maxLength: 100,
                    })}
                    helperText={errors.email?.message}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />

              <Controller
                name={"password"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    label="Пароль"
                    value={value}
                    id="password"
                    onChange={onChange}
                    type={showPassword ? "text" : "password"}
                    error={Boolean(errors.password?.message)}
                    {...register("password", {
                      required: "Введите пароль",
                      minLength: {
                        message: "Мин. длина: 6 символов",
                        value: 6,
                      },
                      maxLength: 100,
                    })}
                    helperText={errors.password?.message}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          aria-label="toggle password visibility"
                        >
                          <Icon
                            icon={
                              showPassword
                                ? "mdi:eye-outline"
                                : "mdi:eye-off-outline"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              />

              <Button
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                sx={{ mb: 7, mt: 5 }}
                startIcon={
                  isSubmiting ? <CircularProgress size="1rem" /> : null
                }
                disabled={isSubmiting}
              >
                Получить код
              </Button>
            </form>
          </CardContent>
        </Card>
      </Box>
    </BlankLayout>
  );
};

export default Login;
