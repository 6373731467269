import { useState } from "react";
import {
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  CircularProgress,
  Avatar,
  DialogActions,
  Typography,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-hot-toast";

// Config
import ConfigApi from "src/configs/api";

import WalletService from "src/service/Wallet";

function AddPartnerKitDialog({ show, handleClose, currency, addItemHandler }) {
  const [isSubmit, setIsSubmit] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      name: "",
    },
  });

  const closeHandler = () => {
    handleClose();
    reset();
  };

  const onSubmit = handleSubmit((d) => {
    setIsSubmit(true);
    WalletService.createKit({
      name: d.name,
      currency: currency._id,
      isPartner: true,
    })
      .then(({ data }) => {
        addItemHandler(data);
        toast.success("Комплект добавлен!");
        handleClose();
      })
      .finally(() => {
        setIsSubmit(false);
      });
  });

  return (
    <Box>
      <Dialog fullWidth maxWidth="xs" open={show} onClose={closeHandler}>
        <DialogTitle sx={{ pb: 0 }}>Добавить комплект</DialogTitle>
        <form onSubmit={onSubmit}>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                py: 0,
                mb: 4,
              }}
            >
              <Avatar
                variant="square"
                src={ConfigApi.PUBLIC_IMAGES + currency?.image + ".png"}
                alt={currency?.name}
                sx={{ height: 30, width: 30, mr: 2 }}
              />
              <Typography variant="body1">{currency?.name}</Typography>
            </Box>
            <Controller
              name={"name"}
              control={control}
              render={({ field }) => (
                <TextField
                  autoFocus
                  fullWidth
                  label="Название"
                  placeholder="Введите название комплекта"
                  id="name"
                  type="text"
                  error={Boolean(errors.name?.message)}
                  {...register("name", {
                    required: "Обязательное поле!",
                  })}
                  helperText={errors.name?.message}
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
          </DialogContent>
          <DialogActions
            sx={{ pb: { xs: 4, sm: 6 }, justifyContent: "center" }}
          >
            <Button
              startIcon={isSubmit ? <CircularProgress size="1rem" /> : null}
              disabled={isSubmit}
              variant="contained"
              sx={{ mr: 2 }}
              type="submit"
              onClick={onSubmit}
            >
              Добавить
            </Button>
            <Button variant="outlined" color="secondary" onClick={closeHandler}>
              Отмена
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

export default AddPartnerKitDialog;
