import checkPermission from "./checkPermission";

// export default function navPermissionFilter(list, isAdmin, permission) {}

export default function permissionFilter(list, isAdmin, permission) {
  if (isAdmin || !permission) return list;

  let result = [];

  list.forEach((n) => {
    if (n.onlyAdmin) return false;

    if (n.permission) {
      let isAllow = checkPermission(permission, n.permission);
      if (isAllow) {
        return result.push(n);
      } else {
        return;
      }
    }
    let childrens = n.children;

    if (childrens) {
      let resultChildrens = permissionFilter(childrens, isAdmin, permission);
      if (resultChildrens.length) {
        return result.push(Object.assign({}, n, { children: resultChildrens }));
      } else {
        return false;
      }
    } else {
      result.push(n);
    }
  });
  return result;
}
