import { useState, useEffect } from "react";
// Mui
import { Box, Grid, CircularProgress, Typography } from "@mui/material";

// Components
import PageWrap from "src/components/PageWrap";

// Modules
import Filter from "./Filter";
import General from "./General";
import Currency from "./Currency";

// Services
import CurrencyService from "src/service/Currencies";
import StatService from "src/service/Stat";

// Config
import SITES from "src/configs/websites";

// moment
import moment from "moment";
import "moment/locale/ru";

// Constants
const typeStatisticsList = [
  {
    name: "Общая",
    value: "general",
  },
  {
    name: "Валютная",
    value: "currency",
  },
];

const periodList = [
  {
    name: "Сегодня",
    value: "today",
  },
  {
    name: "Вчера",
    value: "yesterday",
  },
  {
    name: "Неделя",
    value: "week",
  },
  {
    name: "Месяц",
    value: "month",
  },
  {
    name: "2 месяца",
    value: "2months",
  },
  {
    name: "3 месяца",
    value: "3months",
  },
  {
    name: "6 месяцев",
    value: "6months",
  },
  {
    name: "Год",
    value: "year",
  },
  {
    name: "Все время",
    value: "all",
  },
  {
    name: "По времени",
    value: "time",
  },
];

function Statistics() {
  let [selectedSite, setSelectedSite] = useState("all");
  let [selectedTypeStatistic, setSelectedTypeStatistic] = useState(
    typeStatisticsList[0].value
  );
  let [currencies, setCurrencies] = useState([]);
  let [selectedCurrency, setSelectedCurrency] = useState([]);
  let [selectedPeriod, setSelectedPeriod] = useState(periodList[0].value);
  let [startDateRange, setStartDateRange] = useState(
    moment().startOf("day").valueOf()
  );
  let [endDateRange, setEndDateRange] = useState(
    moment().startOf("day").valueOf()
  );
  let [data, setData] = useState(null);
  let [isLoading, setIsLoading] = useState(false);

  // Hooks
  useEffect(() => {
    CurrencyService.getList({
      query: {
        isCrypto: true,
      },
    }).then((r) => {
      let list = r.data;
      setCurrencies(list);
      setSelectedCurrency(list[0]._id);
    });
  }, []);

  // Handlers
  const fetchHandler = () => {
    // setIsSubmit(true);
    let query = {
      site: selectedSite,
      type: selectedTypeStatistic,
      period: selectedPeriod,
    };

    if (selectedTypeStatistic == "currency") {
      query.currency = selectedCurrency;
    }

    if (selectedPeriod == "time") {
      query.from = startDateRange;
      query.to = endDateRange;
    }

    setIsLoading(true);

    StatService.getGeneral(query)
      .then((r) => {
        setData(r.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <PageWrap title="Cтатистика">
      <Grid container spacing={4}>
        <Grid item xs={12} md={3}>
          <Filter
            selectedTypeStatistic={selectedTypeStatistic}
            setSelectedTypeStatistic={setSelectedTypeStatistic}
            typeStatisticsList={typeStatisticsList}
            currencies={currencies}
            selectedCurrency={selectedCurrency}
            setSelectedCurrency={setSelectedCurrency}
            siteOptionList={[
              {
                name: "Все",
                value: "all",
              },
              ...SITES.map((s) => ({ value: s.id, name: s.name })),
            ]}
            setSelectedSite={setSelectedSite}
            selectedSite={selectedSite}
            periodList={periodList}
            selectedPeriod={selectedPeriod}
            setSelectedPeriod={setSelectedPeriod}
            startDateRange={startDateRange}
            setStartDateRange={setStartDateRange}
            endDateRange={endDateRange}
            setEndDateRange={setEndDateRange}
            isSubmit={isLoading}
            fetchHandler={fetchHandler}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          {isLoading && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                pt: 8,
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {!isLoading && !data && (
            <Typography variant="h6" textAlign="center">
              Данные не загружены
            </Typography>
          )}

          {!isLoading &&
            data &&
            data.type == "general" &&
            selectedTypeStatistic == "general" && <General data={data} />}

          {!isLoading &&
            data &&
            data.type == "currency" &&
            selectedTypeStatistic == "currency" && <Currency data={data} />}
        </Grid>
      </Grid>
    </PageWrap>
  );
}

export default Statistics;
