import { useState, useEffect } from "react";

import { Box, Grid, Tab } from "@mui/material";

// ** Router
import { useParams } from "react-router-dom";

import Details from "./Details";
import Tabs from "./Tabs";

import MerchantService from "src/service/Merchant";

function MerchantClient() {
  let [merchant, setMerchant] = useState(null);

  const params = useParams();

  useEffect(() => {
    MerchantService.request("admin.ser_account_get-one-admin-service", {
      id: params.id,
    }).then((res) => {
      setMerchant(res.data);
    });
  }, []);

  // Handlers
  const updateMerchantHandler = (updated) => {
    setMerchant((merchant) => Object.assign({}, merchant, updated));
  };

  if (!merchant) return null;

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={5} lg={4}>
        <Details updateMerchantHandler={updateMerchantHandler} merchant={merchant} />
      </Grid>
      <Grid item xs={12} md={7} lg={8}>
        <Tabs merchant={merchant} />
      </Grid>
    </Grid>
  );
}

export default MerchantClient;
