import { useState, useEffect } from "react";
//MUi
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";

// Custom ui
import ActiveSwitch from "./Ui/ActiveSwitch";
import CustomTextField from "./Ui/CustomTextField";

// Custom Components
import ViewImage from "src/components/ViewImage";

// ** Icon Imports
import Icon from "src/@core/components/icon";
import { Typography } from "@mui/material";

// Toast
import toast from "react-hot-toast";

// Socket
import { socket } from "src/socket";

// Services
import WalletService from "src/service/Wallet";

export default function CryptoRequisite(props) {
  let { data, deleteHandler, style } = props;

  // States
  let [showQr, setShowQr] = useState(false);
  let [requisite, setRequisite] = useState(data);
  let [fields, setFields] = useState(data.fields);

  // Calc
  let hasFields = requisite.fields.length > 0;
  let address = requisite.fields.find((f) => f.validation == "address")?.value;
  let { changed: isChanged, fields: changeFields } = getChangedFields(
    getFieldsObj(fields),
    getFieldsObj(requisite.fields)
  );

  // Handlers
  const changeItemField = (id, value) => {
    setFields((fields) =>
      fields.map((f) => (f._id !== id ? f : Object.assign({}, f, { value })))
    );
  };

  const updateReqHandler = (upd) => {
    setRequisite((r) => Object.assign({}, r, upd));
    if (upd.fields) {
      setFields(upd.fields);
    }
  };

  const saveHandler = () => {
    // fields

    let fieldList = requisite.fields.map((f) => {
      return Object.assign({}, f, { value: changeFields[f._id] || "" });
    });

    WalletService.changeRequisite({
      _id: requisite._id,
      fields: fieldList,
    }).then(() => {
      toast.success(`Реквизит изменен!`);
    });
  };

  // Hooks
  useEffect(() => {
    socket.on(`update_req_${data._id}`, updateReqHandler);

    return () => {
      socket.off(`update_req_${data._id}`);
    };
  }, []);

  return (
    <Card sx={{ ...style }}>
      <ViewImage
        show={showQr}
        handleClose={() => setShowQr(false)}
        isQrCode
        value={address}
      />
      <CardContent>
        <Grid container spacing={4} columns={20} justifyContent="flex-end">
          <Grid item xs={20} md={1}>
            <ActiveSwitch number={data.number} hideToggle style={{ mr: 3 }} />
          </Grid>
          <Grid item xs={20} md={17}>
            {hasFields ? (
              <Grid container spacing={4}>
                {fields.map((f) => {
                  return (
                    <Grid key={f._id} item xs={12} md={6}>
                      <CustomTextField
                        label={f.name}
                        value={f.value}
                        onChange={(e) => changeItemField(f._id, e.target.value)}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Typography variant="body1" textAlign="center">
                Нет полей
              </Typography>
            )}
          </Grid>
          <Grid item xs={20} md={2}>
            <Box
              sx={{
                minHeight: "100%",
                display: "flex",
                flexDirection: {
                  xs: "row-reverse",
                  md: "column",
                },

                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {isChanged && (
                  <Button
                    variant="outlined"
                    sx={{
                      p: 1.5,
                      minWidth: 38,
                      mb: {
                        xs: 0,
                        md: 2,
                      },
                     alignSelf: 'flex-start'
                    }}
                    color="primary"
                    onClick={saveHandler}
                  >
                    <Icon icon="material-symbols:save-outline-rounded" />
                  </Button>
                )}

                {address && (
                  <Button
                    variant="outlined"
                    sx={{
                      p: 1.5,
                      minWidth: 38,
                      mb: {
                        xs: 0,
                        md: 3,
                      },
                      ml: 3
                    }}
                    color={"secondary"}
                    onClick={() => setShowQr(true)}
                  >
                    <Icon icon="material-symbols:qr-code-2-rounded" />
                  </Button>
                )}
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="outlined"
                  sx={{ p: 1.5, minWidth: 38,  ml:{
                    xs: 0,
                    md: 3
                  } }}
                  color={"error"}
                  onClick={() => deleteHandler(data._id)}
                >
                  <Icon icon="ic:round-delete-forever" />
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function getFieldsObj(fields) {
  let result = {};
  fields.forEach((f) => {
    result[f._id] = f.value || "";
  });
  return result;
}

function getChangedFields(newObj, savedObj) {
  const result = { changed: false, fields: {} };

  // Сравниваем свойства объектов

  for (let prop in newObj) {
    if (newObj[prop] !== savedObj[prop]) {
      result.changed = true;
    }
    result.fields[prop] = newObj[prop];
  }

  return result;
}
