import { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Divider,
  IconButton,
  CardActions,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControlLabel,
  CircularProgress,
  Checkbox,
} from "@mui/material";

import Icon from "src/@core/components/icon";

import moment from "moment";
import "moment/locale/ru";

// Components
import MerchantService from "src/service/Merchant";

// Event
import emitter from "src/EventEmitter";

import EditMerchantClient from "./EditMerchantClient";

function Details({ merchant, updateMerchantHandler }) {
  let [showEdit, setShowEdit] = useState(false);
  let [showBlockedDialog, setShowBlockedDialog] = useState(false);

  const changeBlockedHandler = () => {
    let currentBlocked = merchant.blocked;

    if (!currentBlocked) {
      setShowBlockedDialog(true);
      return;
    }

    if (!window.confirm("Вы уверены что хотите разблокировать мерчанта?"))
      return;

    MerchantService.request("admin.ser_account_change-one-admin-service", {
      _id: merchant._id,
      blocked: false,
    })
      .then(({ data }) => {
        updateMerchantHandler(data);
        emitter.emit("snackbar", {
          open: true,
          message: "Мерчант разблокирован!",
          severity: "success",
        });
      })
      .catch((e) => {
        let message = e?.response?.data?.message;
        if (message) {
          emitter.emit("snackbar", {
            open: true,
            message,
            severity: "error",
          });
        }
      });
  };

  return (
    <Card>
      <BlockedDialog
        merchant={merchant}
        open={showBlockedDialog}
        updateData={updateMerchantHandler}
        handleClose={() => {
          setShowBlockedDialog(false);
        }}
      />
      <EditMerchantClient
        data={merchant}
        show={showEdit}
        updateData={updateMerchantHandler}
        handleClose={() => {
          setShowEdit(false);
        }}
      />
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "& svg": {
              color: merchant.activated ? "success.main" : "error.main",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" sx={{ wordBreak: "break-all", mr: 2 }}>
              {merchant.email}
            </Typography>
            <Icon
              icon={
                merchant.activated
                  ? "line-md:square-to-confirm-square-transition"
                  : "line-md:confirm-square-to-square-transition"
              }
              fontSize={20}
            />
          </Box>

          <IconButton
            onClick={changeBlockedHandler}
            size="small"
            sx={{
              borderRadius: 1,
              ml: 2,
              "& svg": {
                color: merchant.blocked ? "error.main" : "success.main",
              },
            }}
            color={merchant.blocked ? "error" : "success"}
          >
            <Icon
              icon={
                merchant.blocked
                  ? "material-symbols:lock-outline"
                  : "material-symbols:lock-open-outline"
              }
              fontSize={22}
            />
          </IconButton>
        </Box>
        <Typography
          variant="body2"
          color={merchant.enabled ? "success.light" : "error.light"}
        >
          {merchant.enabled ? "Включен" : "Выключен"}
        </Typography>
        <Divider sx={{ mt: (theme) => `${theme.spacing(4)} !important` }} />
        <Box sx={{ display: "flex", mb: 2.7 }}>
          <Typography variant="subtitle2" sx={{ mr: 2, color: "text.primary" }}>
            Баланс:
          </Typography>
          <Typography variant="body2">{merchant.balance} USDT</Typography>
        </Box>
        {/* <Box sx={{ display: "flex", mb: 2.7 }}>
          <Typography variant="subtitle2" sx={{ mr: 2, color: "text.primary" }}>
            Процент вознаграждения от прибыли:
          </Typography>
          <Typography variant="body2">{merchant.percent} %</Typography>
        </Box> */}
        <Box sx={{ display: "flex", mb: 2.7 }}>
          <Typography variant="subtitle2" sx={{ mr: 2, color: "text.primary" }}>
            Telegram:
          </Typography>
          <Typography variant="body2">
            {merchant.telegram ? `@${merchant.telegram}` : "-"}
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ mr: 2, fontWeight: 500, fontSize: "0.875rem" }}>
            Зерегистрирован:
          </Typography>
          <Typography variant="body2">
            {moment(merchant.createdAt).format("DD MMMM YYYY, в HH:mm")}
          </Typography>
        </Box>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "center" }}>
        {/* <Button
          variant="contained"
          sx={{ mr: 2 }}
          onClick={() => setShowEdit(true)}
        >
          Изменить
        </Button> */}
      </CardActions>
    </Card>
  );
}

function BlockedDialog({ open, handleClose, merchant, updateData }) {
  const [isDeleteRequisite, setIsDeleteRequisite] = useState(false);
  const [isSubmiting, setSubmiting] = useState(false);
  // Handlers
  const closeHandler = () => {
    handleClose();
    setIsDeleteRequisite(false);
  };

  // Api
  const apiBlockedHandler = () => {
    setSubmiting(true);
    MerchantService.request("admin.ser_account_change-one-admin-service", {
      _id: merchant._id,
      blocked: true,
      isDeleteRequisite
    })
      .then(({ data }) => {
        updateData(data);
        emitter.emit("snackbar", {
          open: true,
          message: "Мерчант заблокирован!",
          severity: "success",
        });
        closeHandler();
      })
      .catch((e) => {
        let message = e?.response?.data?.message;
        if (message) {
          emitter.emit("snackbar", {
            open: true,
            message,
            severity: "error",
          });
        }
      })
      .finally(() => {
        setSubmiting(false);
      });
  };

  return (
    <Dialog open={open} onClose={closeHandler}>
      <DialogTitle>Подтверждение блокировки</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={isDeleteRequisite}
              onChange={(e) => setIsDeleteRequisite(e.target.checked)}
              defaultChecked
            />
          }
          label="Также удалить реквизиты мерчанта"
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={handleClose}>
          Отменить
        </Button>
        <Button
          onClick={apiBlockedHandler}
          startIcon={isSubmiting && <CircularProgress size={14} />}
          disabled={isSubmiting}
          variant="contained"
          autoFocus
        >
          Удалить
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Details;
