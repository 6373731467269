import { useEffect } from "react";
// router
import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "src/hooks/useAuth";

// ** Pages
import Login from "./pages/Login/Login";
import ConfirmLogin from "./pages/Login/ConfirmLogin";
import FAQPage from "./pages/content/FAQ";
import AgreementContent from "./pages/content/Agreement";
import NotificationContent from "./pages/content/Notification";
import ContactsContent from "./pages/content/Contacts";
import AdContent from "./pages/content/Ad";
import NewsContent from "./pages/content/News";
import MailContent from "./pages/content/Mailing";

import Cards from "./pages/cards/Cards";
import CardComments from "./pages/settings/card-comments/CardComments";
import CurrencySettings from "./pages/settings/currency";
import SystemSettings from "./pages/settings/system/SystemSettings";
import BlackList from "./pages/settings/BlackList/BlackList";
import Localization from "./pages/settings/localization/Localization";
import Rewards from "./pages/rewards/Rewards";

import Currencies from "./pages/currencies";
import Courses from "./pages/courses/Courses";
// import CoursesTarget from "./pages/courses/Target";
import CoursesTarget from "./pages/courses/Target2/Target2";

import CoursesTable from "./pages/courses/Table";

import Clients from "./pages/clients/Clients";
import Client from "./pages/client/Client";

// Wallets
import WalletControl from "./pages/wallet/Control";
import WalletGeneral from "./pages/wallet/General";
import WalletPartner from "./pages/wallet/Partner/index";

import WalletHistoryTransfers from "./pages/wallet/History/Transfers";
import WalletHistoryExchanges from "./pages/wallet/History/Exchanges";
import WalletHistoryOperations from "./pages/wallet/History/Operations";
import Operators from "./pages/settings/operators/Operators";
import Operator from "./pages/settings/operator/Operator";

// Wallets

import Currency from "./pages/currency/Currency";
import Dashboard from "./pages/dashboard/Dashboard";

// Orders
import Orders from "./pages/orders/Orders";

// ** Layout
import UserLayout from "src/layouts/UserLayout";

// Finance
import Arrears from "src/pages/Arrears/Arrears";
import Statistics from "src/pages/statistics/index";

// Partners
import OrderAcquiring from "src/pages/OrderAcquiring";

// Merchant
import MerchantTariff from "src/pages/MerchantTariff";

import DepositList from "src/pages/DepositList";

import MerchantRequisites from "src/pages/MerchantRequisites";
import { MerchantClientsPage } from "src/pages/MerchantClients";
import MerchantClient from "src/pages/MerchantClient";
import MerchantOrders from 'src/pages/MerchantOrders';

// Others
import Updates from "src/pages/updates/index";

// Permissions
import routesPermissionFilter from "src/permission/routesPermissionFilter";
import PartnerRequsites from "./pages/wallet/Partner/index";

export default function MainRouter() {
  const { user, permission, isAdmin } = useAuth();

  // Calc
  const isAuth = !!user;

  const getLayout = !isAuth
    ? (f) => f
    : (page) => <UserLayout>{page}</UserLayout>;

  const unauthRoutes = [
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/confirm-login",
      element: <ConfirmLogin />,
    },
  ];

  const authRoutes = [
    {
      path: "/orders",
      element: <Orders />,
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
    },

    {
      path: "/content/faq/:site",
      element: <FAQPage />,
      permission: "content",
    },
    {
      path: "/content/agreement/:site",
      element: <AgreementContent />,
      permission: "content",
    },
    {
      path: "/content/notification/:site",
      element: <NotificationContent />,
      permission: "content",
    },
    {
      path: "/content/contacts/:site",
      element: <ContactsContent />,
      permission: "content",
    },
    {
      path: "/content/ad/:site",
      element: <AdContent />,
      permission: "content",
    },
    {
      path: "/content/news/:site",
      element: <NewsContent />,
      permission: "content",
    },
    {
      path: "/content/mail/:site",
      element: <MailContent />,
      permission: "content",
    },
    {
      path: "/settings/card-comments",
      element: <CardComments />,
      permission: "settings.card-comments",
    },
    {
      path: "/settings/currency-and-rate",
      element: <CurrencySettings />,
      permission: "settings.currency-and-rate",
    },
    {
      path: "/settings/localization",
      element: <Localization />,
      permission: "settings.localization",
    },
    {
      path: "/settings/system",
      element: <SystemSettings />,
      permission: "settings.system-settings",
    },
    {
      path: "/settings/black-list",
      element: <BlackList />,
      permission: "settings.black-list",
    },
    {
      path: "/settings/operators",
      element: <Operators />,
      onlyAdmin: true,
    },
    {
      path: "/settings/operators/:id",
      element: <Operator />,
    },
    {
      path: "/requisites/control",
      element: <WalletControl />,
    },
    {
      path: "/requisites/global",
      element: <WalletGeneral />,
    },
    {
      path: "/requisites/partners",
      element: <WalletPartner />,
    },
    {
      path: "/requisites/history/exchanges",
      element: <WalletHistoryExchanges />,
    },
    {
      path: "/requisites/history/transfers",
      element: <WalletHistoryTransfers />,
    },
    {
      path: "/requisites/history/operations",
      element: <WalletHistoryOperations />,
    },
    {
      path: "/currencies/:site",
      element: <Currencies />,
      permission: "currencies",
    },
    {
      path: "/currency/:id",
      element: <Currency />,
      permission: "settings.currency-and-rate",
    },
    {
      path: "/courses/:site",
      element: <Courses />,
    },
    {
      path: "/courses/target",
      element: <CoursesTarget />,
    },
    {
      path: "/courses-table",
      element: <CoursesTable />,
    },
    {
      path: "/clients",
      element: <Clients />,
    },
    {
      path: "/clients/:id",
      element: <Client />,
    },
    {
      path: "/cards",
      element: <Cards />,
    },
    {
      path: "/rewards",
      element: <Rewards />,
      permission: "rewards",
    },
    {
      path: "/finance/arrears",
      element: <Arrears />,
      permission: "finance.debts",
    },
    {
      path: "/finance/statistics",
      element: <Statistics />,
      // permission: "finance.debts",
    },
    {
      path: "/partners/acquiring",
      element: <OrderAcquiring />,
      // permission: "finance.debts",
    },
    // Merchant
    {
      path: "/merchant/deposit",
      element: <DepositList />,
    },
    {
      path: "/merchant/requisites",
      element: <MerchantRequisites />,
    },
    {
      path: "/merchant/clients",
      element: <MerchantClientsPage />,
    },
    {
      path: "/merchant/tariff",
      element: <MerchantTariff />,
    },
    {
      path: "/merchant/clients/:id",
      element: <MerchantClient />,
    },
    {
      path: "/merchant/orders",
      element: <MerchantOrders />,
    },
    {
      path: "/updates",
      element: <Updates />,
      // permission: "finance.debts",
    },
  ];

  // let routeList = isAuth
  //   ? routesPermissionFilter(authRoutes, isAdmin, permission)
  //   : unauthRoutes;

  let routeList = [...authRoutes, ...unauthRoutes];

  const routes = (
    <Routes>
      {routeList.map((r) => (
        <Route path={r.path} element={r.element} />
      ))}
      {routeList.length && (
        <Route path="*" element={<Navigate to="/orders" replace />} />
      )}
    </Routes>
  );

  return getLayout(routes);
}
