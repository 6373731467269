const loalty = {
  default: "Обычный",
  permanent: "Постоянный",
  risk: "Высокий риск",
};

// const orderStatusList = [
//   {
//     name: 'Но'
//   }
// ]

const cardStatusList = [
  {
    name: "Новая",
    filterName: "Новые",
    code: 101,
    color: 'primary',
  },
  {
    name: "Одобрена",
    filterName: "Одобренные",
    code: 102,
    color: 'success'
  },
  {
    name: "Отклонена",
    filterName: "Отклоненные",
    code: 103,
    color: 'error'
  },
  {
    name: "Забанена",
    filterName: "Забаненные",
    code: 104,
    color: ''
  },
];

const currencyFilter = [
  {
    value: "rub",
    name: "Рубли",
  },
  {
    value: "usd",
    name: "Доллары",
  },
  {
    value: "eur",
    name: "Евро",
  },
  {
    value: "coin",
    name: "Coin",
  },
  {
    value: "ps",
    name: "Платежная система",
  },
  {
    value: "code",
    name: "Code",
  },
];

const fiats = ["RUB", "USD", "EUR", "UAH", "KZT", "TRY"];

export default { loalty, fiats, currencyFilter,cardStatusList };
