import { useState, useEffect } from "react";

//Router
import { useSearchParams, Link } from "react-router-dom";

import socket from "src/socket";

// Mui
import {
  Box,
  Drawer,
  CircularProgress,
  IconButton,
  Typography,
  CardContent,
  Divider,
  Link as MuiLink,
  Card,
  Alert,
  Grid,
  Button,
  TextField,
} from "@mui/material";

import MerchantService from "src/service/Merchant";

import Icon from "src/@core/components/icon";

import StatusChip from "../StatusChip";

// Libs
import moment from "moment";
import "moment/locale/ru";
// import CardSend from "./SendCard";
// Modules
import SendContent from "./modules/SendContent";
import ReceiveContent from "./modules/ReceiveContent";
import Currency from "./modules/Currency";

function MerchantOrderDrawer() {
  // Hooks Router
  const [searchParams, setSearchParams] = useSearchParams();
  const merchantOrderId = searchParams.get("merchantOrderId");

  // States
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState(null);

  // Hooks
  useEffect(() => {
    if (!merchantOrderId) return;
    setLoading(true);
    MerchantService.request("admin.ser_order_get-by-id", {
      id: merchantOrderId,
    }).then(({ data }) => {
      setOrder(data);
      setLoading(false);
    });

    socket.on("merchant_order-send_upd", (upd) => {
      setOrder((order) => {
        return order._id == upd._id ? upd : order;
      });
    });
    socket.on("merchant_order-receive_upd", (upd) => {
      setOrder((order) => {
        return order._id == upd._id ? upd : order;
      });
    });


    return () => {
      socket.off("merchant_order-send_upd");
      socket.off("merchant_order-receive_upd");
    };
  }, [merchantOrderId]);

  // Handler
  const handleClose = () => {
    searchParams.delete("merchantOrderId");
    setSearchParams(searchParams);
  };

  let hasData = !loading && order;

  return (
    <Drawer
      open={!!merchantOrderId}
      anchor="right"
      variant="temporary"
      onClose={handleClose}
      ModalProps={{ keepMounted: false }}
      sx={{ "& .MuiDrawer-paper": { width: { xs: "100%", sm: 600 } } }}
    >
      {hasData ? (
        <Content data={order} handleClose={handleClose} />
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Drawer>
  );
}

function Content({ handleClose, data }) {
  let viewSection;

  if (data.isReceive) {
    viewSection = <ReceiveContent data={data} />;
  } else if (data.isSend) {
    viewSection = <SendContent data={data} />;
  }

  return (
    <Box>
      <Box
        sx={{
          py: 3,
          px: 4,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <IconButton
          size="small"
          onClick={handleClose}
          sx={{ color: "text.primary" }}
        >
          <Icon icon="mdi:close" fontSize={20} />
        </IconButton>
      </Box>
      <CardContent sx={{ pt: 0 }}>
        <TopInfo data={data} />
      </CardContent>
      <Divider />
      <CardContent>
        {data.code == "cancel" && data.comment ? (
          <Typography color="error" sx={{ mb: 3 }}>
            Комментарий: {data.comment}
          </Typography>
        ) : null}

        {viewSection}
      </CardContent>
    </Box>
  );
}
function TopInfo({ data }) {
  let title = getTitleActon(data.isReceive, data.isSend);

  function getTitleActon(isReceive, isSend) {
    if (isReceive) {
      return "Получение";
    } else if (isSend) {
      return "Перевод";
    } else {
      return "";
    }
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ fontWeight: "bold" }} variant="h5">
            {title}
          </Typography>
        </Box>

        <Box>
          <StatusChip
            status={data.status}
            isReceive={data.isReceive}
            isSend={data.isSend}
          />
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <InfoRaw style={{ mb: 1 }} iconName="ph:hash-bold" text={data._id} />
        </Box>
        <InfoRaw
          iconName="lets-icons:order"
          text={data.orderId}
          style={{ mb: 1 }}
          link={`?orderId=${data.orderId}`}
        />
        <InfoRaw
          iconName="mdi:user-circle-outline"
          text={data.account.email}
          style={{ mb: 1 }}
          link={`/merchant/clients/${data.account._id}`}
        />
        <InfoRaw
          style={{ mb: 1 }}
          iconName="ion:time-outline"
          text={moment(data.createdAt).format("DD MMMM YYYY, в HH:mm")}
        />
      </Box>
      <Currency
        style={{ mt: 2 }}
        name={data.currency?.name}
        image={data.currency?.logo}
      />
    </Box>
  );
}




function InfoRaw(props) {
  let {
    style,
    textColor,
    link,
    external = false,
    iconName,
    text,
    TypographyProps = {},
    copy = false,
  } = props;

  let textComponent;

  if (external) {
    textComponent = (
      <MuiLink
        component={"a"}
        target="_blank"
        color="primary"
        underline="none"
        rel="noreferrer"
        variant="body1"
        href={link}
        {...TypographyProps}
      >
        {text}
      </MuiLink>
    );
  } else {
    textComponent = (
      <Typography
        color={link ? "primary" : textColor}
        component={link ? Link : undefined}
        to={link}
        {...TypographyProps}
      >
        {text}
      </Typography>
    );
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center", mb: 2, ...style }}>
      <Icon fontSize={20} icon={iconName} />
      <Box sx={{ ml: 1.5 }}>{textComponent}</Box>
    </Box>
  );
}

export default MerchantOrderDrawer;
